import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, Grid, Typography} from "@mui/material";


function AlreadyConfirmed(props) {
	const {t} = useTranslation();
	const navigate = useNavigate();

	function submitHandler() {
		navigate("/")
	}

	return (
		<>
			{props.userEmail === '' ? (
				<Grid container alignItems="center" justifyContent="center">
					<Typography gutterBottom variant="h4">{t('auth.AlreadyConfirmed')}</Typography>
					<Typography gutterBottom variant="body">{t('auth.AlreadyConfirmed')}</Typography>
					<Button sx={{marginTop:"50px"}} fullWidth variant="contained" color="primary" size="large" type="submit" onClick={submitHandler}>
						{ t('app.Continue') }
					</Button>
				</Grid>
			) :(
				<Grid container alignItems="center" justifyContent="center">
					<Typography gutterBottom variant="h4">{t('auth.ThanksConfirmed')}</Typography>
					<Typography gutterBottom variant="body2">{props?.userEmail}</Typography>
					<Button sx={{marginTop:"50px"}} fullWidth variant="contained" color="primary" size="large" type="submit" onClick={submitHandler}>
						{ t('app.Continue') }
					</Button>
				</Grid>
			)}

		</>
	);
}

export default AlreadyConfirmed;