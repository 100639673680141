import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid, Paper} from '@mui/material';
import {ClientAppointmentSection,ClientFollowUpSection, ClientGeneralNoteSection, EditClientInfo, ToastMsg} from 'components';
import apiClient from "../../../services/apiClient";
import {useParams} from "react-router-dom";
import ClientDocumentSection from "../../client/ClientDocumentSection";

function ClientPage(props) {
	const {t} = useTranslation()
	const [client, setClient] = useState({});
	const [errors, setErrors] = useState({});
	const {id} = useParams()
	const [isFetching, setIsFetching] = useState(false)
	const [ hasBeenEdited, setHasBeenEdited ] = useState(false)

	useEffect(() => {
		setIsFetching(true)
		apiClient.fetchAllClientData(id).then(({data, error}) => {
			if (data) {
				setClient(data)
				setHasBeenEdited(false)
			}
			if (error) {
				setErrors({msg: error})
			}
			setIsFetching(false)
		})
	}, [hasBeenEdited])

	const handleClientEdited = () => {
		setHasBeenEdited(true)
	}
	
	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg &&
				<ToastMsg open={true} type='error' message={t('app.Error')}/>
			}
			<ToastMsg open={hasBeenEdited} type='error' message={t('app.Success')}/>
			{!isFetching && (
				<Grid container spacing={2}>
					<Grid item xs={12} md={8}>
						<Paper sx={{p: 2}}>
							<EditClientInfo client={client} clientEdited={handleClientEdited}/>
						</Paper>
					</Grid>
					<Grid item xs={12} md={4}>
						<Paper sx={{p: 2}}>
							<ClientAppointmentSection client={client} />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper sx={{p: 2}}>
							<ClientFollowUpSection client={client} />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper sx={{p: 2}}>
							<ClientGeneralNoteSection client={client}/>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper sx={{p: 2}}>
							<ClientDocumentSection client={client} />
						</Paper>
					</Grid>
				</Grid>
			)}
		</>
	);
}

export default ClientPage;