import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardActionArea} from '@mui/material';
import theme from '../../theme';

const myStyles = {
	card:{
		textAlign: 'center',
	},
	cardSelected:{
		textAlign: 'center',
		border:'3px solid',
		borderColor: theme.palette.primary.main
	}
};

function TreatmentCard({index, onClick, selected, title}) {
	const [isSelected, setIsSelected] = useState(selected);
	const {t} = useTranslation();

	function onClickCard(index){
		onClick(index)
		setIsSelected(!isSelected)
	}

	return (
		<>
			<Card onClick={() => onClickCard(index)} sx={isSelected ? myStyles.cardSelected : myStyles.card} >
				<CardActionArea sx={{p: 1}}>
					{isSelected ? <img width='125px'
						src={`../../images/treatments/vert/${title}.png`}
						alt={title}/> : <img width='125px'
						src={`../../images/treatments/noir/${title}.png`}
						alt={title}/>}
					<h4>{t(`treatment.${title}`)}</h4>
				</CardActionArea>

			</Card>
		</>
	);
}

export default TreatmentCard;