import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormContextNoAuthProvider, useFormContextNoAuth} from "../../../contexts/FormContextNoAuth";
import {Backdrop, Button, CircularProgress, Container, Grid, Paper, Typography} from "@mui/material";
import {DocumentTitle, Recommandations} from "components";

export default function CrudFormContainer(){
    return (
        <FormContextNoAuthProvider>
            <CompletedDocumentPageNoAuth />
        </FormContextNoAuthProvider>
    )
}

function CompletedDocumentPageNoAuth(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const {appointmentDate, client, isFetching, treatmentName, idTreatment} = useFormContextNoAuth();

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container maxWidth="lg" sx={{pt:5}}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={8}>
                        <DocumentTitle title={`${client?.firstName} ${client?.lastName}`} subtitle={`${t(`treatment.${treatmentName}`)}`} appointmentDate={appointmentDate} />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" sx={{pt:5}} spacing={1}>
                    <Grid item xs={12} md={8}>
                        <Typography variant='body1'>
                            {t('document.DocumentCompleted')}
                        </Typography>
                    </Grid>
                </Grid>
                <Recommandations idTreatment={idTreatment} />
            </Container>
        </>
    );
}
