import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import {Button, CircularProgress, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField} from '@mui/material';
import {BaseTextField} from '../textfield/StyledTextField';
import NumberFormat from 'react-number-format';
import MapAutoComplete from '../textfield/MapAutoComplete';
import apiClient from '../../services/apiClient';
import {useAuthContext} from '../../contexts/auth';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			format="(###) ###-####" allowEmptyFormatting mask="_"
		/>
	);
});

function ClientForm({closeModal, handleAddClient}) {
	const {t} = useTranslation();
	const [errors, setErrors] = useState({});
	const {user, initialized} = useAuthContext()
	const [form, setForm] = useState({firstName:'', lastName:'', address:'',local:'', mainPhone:'', altPhone:'', email:'', langPreferences: 'fr'});
	const [isFetching, setIsFetching] = useState(false)
	const [isProcessing, setIsProcessing] = useState(false)
	const [lang, setLanguage] = useState('fr')
	const [authorisation, setAuthorisation] = useState('0')

	const handleChange = (element) => {
		setErrors({...errors, msgServer: null});
		if ( errors.hasOwnProperty(element.target.name) ) {
			setErrors((e) => ({ ...e, [element.target.name]: null }))
		}
		if (element.target.name === "email") {
			if (element.target.value.indexOf("@") === -1) {
				setErrors((e) => ({ ...e, email: "app.ValidEmail" }))
			} else {
				setErrors((e) => ({ ...e, email: null }))
			}
		}
		setForm({ ...form, [element.target.name]: element.target.value });
	}

	const submitHandler = async () => {
		const requiredFields = [
			'firstName',
			'lastName',
			'address',
			'mainPhone'];
		let hasError = false;
		setIsProcessing(true)
		setErrors((e) => ({...e, form: null}))

		Object.entries(form).map(field => {
			if (requiredFields.indexOf(field[0]) !== -1 && field[1] === '') {
				setErrors(
					(e) => ({...e, [field[0]]: "app.RequiredFields"}))
				hasError = true
			}
		})

		if (!hasError) {
			setIsFetching(true)
			const {data, error} = await apiClient.addNewClient(form, user.centerId)
			if (data) {
				const url = 'client/' + data.id
				window.open(url)
				handleAddClient()
				closeModal()
			}
			if (error) {
				setErrors({msgServer: error})
			}
			setIsFetching(false)
		}
	}

	function handleLangChange(element) {
		setErrors((e) => ({...e, langPreferences: null}))
		setLanguage(element.target.value)
		setForm({...form, langPreferences: element.target.value})
	}

	const handleChangeAutoComplete = (value) => {
		setErrors((e) => ({ ...e, address: null }))
		setForm({...form, address : (value?.description ? value.description : '' )})
	}

	return (
		<>
			<Grid container spacing={1}>
				{
					errors?.msgServer !== null &&
					<Grid item xs={12} sx={{pt: 2}}>
						<span className="errorMsg">{t(errors.msgServer)}</span>
					</Grid>
				}
				<Grid item xs={12} md={6}>
					<BaseTextField
						label={t('client.FirstName')}
						required
						fullWidth
						autoFocus
						variant="outlined"
						name="firstName"
						onChange={handleChange}
						value={form.firstName}
						error={errors.firstName && true}
						helperText={errors.firstName && t(errors.firstName) }
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<BaseTextField
						label={t('client.LastName')}
						required
						fullWidth
						variant="outlined"
						name="lastName"
						onChange={handleChange}
						value={form.lastName}
						error={errors.lastName && true}
						helperText={errors.lastName && t(errors.lastName) }
					/>
				</Grid>
				<Grid item xs={12} md={10}>
					<MapAutoComplete valueAutoComplete={form.address} errorAutoComplete={errors.address && true} helperTextAutoComplete={errors.address && t(errors.address) } handleChangeAutoComplete={handleChangeAutoComplete}/>
				</Grid>
				<Grid item xs={12} md={2}>
					<BaseTextField
						label={t('client.Local')}
						fullWidth
						variant="outlined"
						name="local"
						onChange={handleChange}
						value={form.local}
						error={errors.local && true}
						helperText={errors.local && t(errors.local) }
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<BaseTextField
						label={t('client.MainPhone')}
						required
						fullWidth
						onChange={handleChange}
						name="mainPhone"
						id="formatted-mainPhone-input"
						InputProps={{
							inputComponent: NumberFormatCustom,
						}}
						variant="outlined"
						value={form.mainPhone}
						error={errors.mainPhone && true}
						helperText={errors.mainPhone && t(errors.mainPhone) }
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<BaseTextField
						label={t('client.AltPhone')}
						fullWidth
						onChange={handleChange}
						name="altPhone"
						id="formatted-altPhone-input"
						InputProps={{
							inputComponent: NumberFormatCustom,
						}}
						variant="outlined"
						value={form.altPhone}
						error={errors.altPhone && true}
						helperText={errors.altPhone && t(errors.altPhone) }
					/>
				</Grid>
				<Grid item xs={12}>
					<BaseTextField
						label={t('client.Email')}
						fullWidth
						variant="outlined"
						name="email"
						onChange={handleChange}
						value={form.email}
						error={errors.email && true}
						helperText={errors.email && t(errors.email) }
					/>
				</Grid>
				<Grid item xs={12}>
					<FormLabel component="legend">{t('app.LangPreference')}</FormLabel>
					<RadioGroup row aria-label="gender" name="row-radio-buttons-group" onChange={handleLangChange} value={lang}>
						<FormControlLabel value="fr" control={<Radio />} label={t('app.French')} />
						<FormControlLabel value="en" control={<Radio />} label={t('app.English')} />
					</RadioGroup>
				</Grid>
				<Grid item xs={12} lg={3}>
					<Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isFetching} onClick={closeModal}>
						{t('app.Cancel')}
					</Button>
				</Grid>
				<Grid item xs={12} lg={6}/>
				<Grid item xs={12} lg={3}>
					<Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isFetching} onClick={submitHandler}>
						{isFetching && <CircularProgress color="secondary"  size={20} />}
						{!isFetching && t('app.Save')}
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

export default ClientForm;