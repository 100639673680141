import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import {Box, Button, CircularProgress, Grid, Modal, Paper, Stack} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {myStyles, Title} from "components";
import apiClient from "../../services/apiClient";
import SignaturePad from "react-signature-canvas";

function ModalResignDocument({open, handleCloseModal, docId}) {
    const {t} = useTranslation()
	const [isProcessing, setIsProcessing] = useState(false);
	const [errors, setErrors] = useState(false);
	const [signature, setSignature] = useState(null);

	const handleClose = () => {
		setErrors(false);
		handleCloseModal(false, null);
	};

	const submitHandler = () => {
		setIsProcessing(true);
		if (!signature.isEmpty()) {
			apiClient.updateDocumentSignature(docId, signature.getTrimmedCanvas().toDataURL())
				.then(() => {
					setIsProcessing(false);
					handleCloseModal(true, signature.getTrimmedCanvas().toDataURL());
				})
		}else {
			setErrors(true);
			setIsProcessing(false);
		}
	}

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={myStyles.smallBoxModal}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Title balise={'h4'} title={t('form.ResignModalTitle')} subtitle='' forgetLabel={false}/>
						<HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
					</Stack>
					<Grid container alignItems="center" spacing={2}>
						{ errors ? (
							<Grid item xs={12}>
								<span className="errorMsg">{t('form.MissingSignature')}</span>
							</Grid>
						) : null }
						<Grid item xs={12} >
							<Paper  sx={myStyles.paper} >
								<SignaturePad canvasProps={{className: 'signatureFormulaire'}}  ref={(ref) => { setSignature(ref) }} />
							</Paper>
						</Grid>
						<Grid item xs={12} lg={4}>
							<Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isProcessing} onClick={handleClose}>
								{t('app.Close')}
							</Button>
						</Grid>
						<Grid item xs={12} lg={4}>
							<Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isProcessing}  onClick={() => {
								signature.clear();
							}}>{t('form.Clear')}</Button>
						</Grid>
						<Grid item xs={12} lg={4}>
							<Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isProcessing} onClick={submitHandler}>
								{isProcessing && <CircularProgress color="secondary"  size={20} />}
								{!isProcessing && t('app.Save')}
							</Button>
						</Grid>
					</Grid>

				</Box>
			</Modal>

		</>
	);
 }

export default ModalResignDocument;