import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import { TranslateLink } from 'components'
import apiClient from 'services/apiClient';
//images
import loginImg from 'asset/images/loginImg.png'
import logo from 'asset/images/logo.png'
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {BaseTextField} from '../../textfield/StyledTextField';
import theme from '../../../theme';

const myStyles = {
	container: {
		margin:"auto",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		minHeight: "100vh",
		padding: "100px 0",
	},
	leftSide:{
		backgroundColor: theme.palette.primary.main,
		borderRadius:"20px",
		padding:"15px"
	},
	rightSide:{
		padding: "70px 0px 70px 24px",
		[theme.breakpoints.down('sm')]: {
			padding: "20px",
		},
	},
};

function ResetPassword({}) {
	const { t } = useTranslation()
	const [isProcessing, setIsProcessing] = useState(false)
	const [isDone, setDone] = useState(false)
	const [form, setForm] = useState({ email: null });
	const [errors, setErrors] = useState({})

	const submitHandler = async () => {
		setIsProcessing(true)
		setErrors((e) => ({ ...e, form: null }))

		const { data, error } = await apiClient.resetPassword({ email: form.email })

		if (data) {
			setDone(true)
		}
		if (error) {
			setErrors((e) => ({ ...e, form: error }))
		}

		setIsProcessing(false)
	}

	const handleChange = (event) => {
		if (event.target.name === "email") {
			if (event.target.value.indexOf("@") === -1) {
				setErrors((e) => ({ ...e, email: "app.ValidEmail" }))
			} else {
				setErrors((e) => ({ ...e, email: null, form: null }))
			}
		}
		setForm({ ...form, [event.target.name]: event.target.value });
	}

	return (
		<>
			<Container maxWidth="lg" sx={myStyles.container}>
				<Grid container alignItems="center" justifyContent="space-between">
				<Grid item xs={12} md={6} sx={myStyles.leftSide}>
					<div>
						<img src={logo} alt="" className='login__logo' />
						<div className='login__translate'>
							<TranslateLink />
						</div>
					</div>
					<div className="login__image">
						<img src={loginImg} alt="NessaApp" width="250px" />
						<p>{t('app.Slogan')}</p>
					</div>
				</Grid>
					{ isDone && <Grid item xs={12} md={6}  sx={myStyles.rightSide}>
						<Box display="flex" justifyContent="flex-start"	alignItems="flex-start" flexDirection="column">
							<Typography variant="h2">{t('auth.ResetPasswordDone')}</Typography>
							<Typography gutterBottom>{t('auth.ResetPasswordDoneInfo')}</Typography>
						</Box>
					</Grid>}
					{ !isDone && 				<Grid item xs={12} md={6}  sx={myStyles.rightSide}>
						<Box display="flex" justifyContent="flex-start"	alignItems="flex-start" flexDirection="column">
							<Typography variant="h2">{t('auth.ResetPassword')}</Typography>
							<Typography gutterBottom>{t('auth.ResetPasswordInfo')}</Typography>
						</Box>
						<BaseTextField
							label={t('auth.Email')}
							required
							fullWidth
							autoFocus
							variant="outlined"
							name="email"
							onChange={handleChange}
							value={form.email}
							error={errors.email && true}
							helperText={errors.email && t(errors.email) }
						/>
						<Button fullWidth variant="contained"  size="large" type="submit" disabled={isProcessing || errors.email} onClick={submitHandler}>
							{isProcessing ? t('app.Loading') : t('auth.Reset') }
						</Button>
					</Grid>}
			</Grid>
			</Container>
		</>
	);
}

export default ResetPassword;