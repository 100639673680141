import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import './lang/i18nextConf';
import {ThemeProvider} from "@emotion/react";
import {CssBaseline} from "@mui/material";
import theme from './theme'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://5f3bc801a56f4f3588ff220b8615512d@o1412198.ingest.sentry.io/6751042",
    integrations: [
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.3, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
            <App />
    </ThemeProvider>,
  document.getElementById('root')
);
