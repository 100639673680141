import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';
import {ForgetSaveLabel} from '../index';

function Title(props) {
	const {t} = useTranslation();

	return (
		<>
			<Typography variant={props?.balise ? props.balise : 'h2'}>{t(props.title)}</Typography>
			{props?.subtitle && <Typography variant="h6" gutterBottom>
					{t(props.subtitle)} {props.forgetLabel ? <ForgetSaveLabel/> : ''}
				</Typography>
			}
		</>
	);
}

export default Title;