import React, {useState} from 'react';
import { TreatmentCard } from 'components';
import {Grid} from '@mui/material';

function TreatmentCardList({contents, treatmentSelected, onChange}) {
	const [selectedTreatments, setSelectedTreatments] = useState(treatmentSelected.treatmentList)

	function onItemSelected(index) {
		const arrayTreatments = selectedTreatments
		const selectedIndex = arrayTreatments.indexOf((index));
		if (selectedIndex > -1) {
			arrayTreatments.splice(selectedIndex, 1);
			onChange(arrayTreatments);
		}else{
			arrayTreatments.push((index));
			onChange(arrayTreatments);
		}
		setSelectedTreatments(arrayTreatments)
	}

	return contents.map((service, i) => {
		const {treatment_PK, treatment_name} = service
		const isSelected = selectedTreatments.indexOf((treatment_PK)) > -1;
		return (
			<Grid item xs={6} md={4} lg={2} key={i}>
				<TreatmentCard title={treatment_name} index={treatment_PK} selected={isSelected} onClick={onItemSelected}/>
			</Grid>
		)
	})
}

export default TreatmentCardList;