import React, {useEffect, useState} from 'react';
import {Title, ToastMsg, ListExamenPeauNotes, Element, myStyles, ModalNoteGeneral} from 'components';
import {Backdrop, Box, Button, CircularProgress, Grid, Paper, Stack, Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams,} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from '@mui/icons-material/Save';
import {FormExamenPeauContextProvider, useFormExamenPeauContext} from "../../../contexts/FormExamenPeauContext";
import Swal from "sweetalert2";
import {useFollowUpContext} from "../../../contexts/FollowUpContext";
import apiClient from "../../../services/apiClient";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import theme from "../../../theme";
import AddBusinessRounded from "@mui/icons-material/AddComment";

export default function FormExamenPeauContainer(){
	return (
		<FormExamenPeauContextProvider>
			<ExamenPeauNotePage />
		</FormExamenPeauContextProvider>
	)
}

function ExamenPeauNotePage({viewOnly}) {
	const {t} = useTranslation();
	const {id} = useParams()
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});
	const [visibilityOld, setVisibilityOld] = useState(true)
	const [client, setClient] = useState({})
	const { handleAddFollowUpClick } = useFollowUpContext();
	const [open, setOpen] = useState(false);
	const [generalNote, setGeneralNote] = useState(false)
	const {completed, elements, isFetching, handleSubmit, handleResetNotes, handleCrudOldNotes, oldNotes, viewMode} = useFormExamenPeauContext();

	useEffect(() => {
		apiClient.fetchClientName(id).then(({data}) => {
			setClient({clientId: id, firstName: data.firstName, lastName: data.lastName})
		}).catch(error => {
			setErrors(error.response.data.errors)
		})
	}, [])

	const handleCancel = () => {
		Swal.fire({
			title: t("examenPeauNotes.CancelEditDocumentTitle"),
			text: t("examenPeauNotes.DocumentWontBeSaved"),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: theme.palette.primary.main,
			cancelButtonColor: theme.palette.secondary.light,
			confirmButtonText: t("examenPeauNotes.CancelEditBtn")
		}).then((result) => {
			if (result.isConfirmed) {
				navigate('/center/client/' + id)
			}
		})
	}

	const handleOldSection = (value) => {
		setVisibilityOld(value)
	}

	const handleOpenClientFollowUp = () => {
		handleAddFollowUpClick(client)
	}

	const handleOpen = () => {
		setOpen(true);
	}

	const handleClose= (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpen(false);
			setGeneralNote(false)
		}
	}

	const handleCrudCompleted = () => {
		setOpen(false)
		setGeneralNote(true)
	}

	return (
		<>
			{generalNote &&
				<ToastMsg open={true} type="success" message={t('app.SaveSuccess')}/>
			}
			{errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{completed && <ToastMsg open={true} type='success' message={t('app.SaveSuccess')}/>}
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isFetching}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
			<ModalNoteGeneral open={open} handleClose={handleClose} handleCrudCompleted={handleCrudCompleted} editNote={false}/>
			 <Grid container>
				 <Grid item xs={12} >
					 <Tooltip title={t('app.BackToClientPage')} sx={myStyles.icons} >
						 <ArrowBackIosIcon onClick={() => handleCancel()}/>
					 </Tooltip>
				 </Grid>
				 <Grid item xs={12}>
					 <Stack
						 direction="row"
						 justifyContent="space-between"
						 alignItems="flex-start"
						 spacing={2}
					 >
						 <div>
							 <Title title='examenPeauNotes.Title' subtitle='examenPeauNotes.Subtitle' forgetLabel={true}/>
						 </div>
						 {!viewOnly &&
							 <Box sx={{display:'inline-flex'}}>
								 {!visibilityOld ? (
									 <Tooltip title={t('examenPeauNotes.VoirNote')} sx={myStyles.icons} >
										 <VisibilityIcon onClick={e => handleOldSection(true)} />
									 </Tooltip>
								 ) : (
									 <Tooltip title={t('examenPeauNotes.CacherNote')} sx={myStyles.icons} >
										 <VisibilityOffIcon onClick={e => handleOldSection(false)} />
									 </Tooltip>
								 )}
								 {!viewOnly &&  <>
									 <Tooltip title={t('electrolyseNotes.AddFollowUp')} sx={myStyles.icons} >
										 <NotificationAddIcon onClick={() => handleOpenClientFollowUp()} />
									 </Tooltip>
									 <Tooltip title={t('electrolyseNotes.AddGeneralNote')} sx={myStyles.icons} >
										 <AddBusinessRounded onClick={e => handleOpen()} />
									 </Tooltip>
									 <Tooltip title={t('examenPeauNotes.SaveNote')} sx={myStyles.icons} >
										 <SaveIcon onClick={handleSubmit} />
									 </Tooltip>
								 </>}
							 </Box>
						 }
					 </Stack>
				 </Grid>
			 </Grid>
			 {
				 visibilityOld ? (
					 <Grid container sx={{pt:3}}>
						 <ListExamenPeauNotes oldNotes={oldNotes} handleCrudOldNotes={handleCrudOldNotes}/>
					 </Grid>
				 ): null
			 }
			 <Grid container sx={{pt:3}}>
				 {
					 elements ? elements.map((elm, i) => {
						 const {fields, page_label} = elm ?? {};
						 return (
							 <Grid item xs={12} key={page_label}>
								 <Paper  sx={myStyles.paper} >
									 <Grid container alignItems="center" spacing={1}>
										 <Grid item xs={12}>
											 <Typography variant="h4">{t(page_label)}
												 <Tooltip title={t('examenPeauNotes.ResetNote')} sx={myStyles.icons} >
													 <RotateLeftIcon onClick={handleResetNotes} />
												 </Tooltip>
											 </Typography>
										 </Grid>
										 {fields ? fields.map((field, i) => <Element field={field} key={i} viewMode={viewMode} isExamenPeau={true}/>) : null}
									 </Grid>
								 </Paper>
							 </Grid>
						 )
					 }) : null
				 }
			 </Grid>
			 {!viewOnly &&  <Grid container alignItems="center" spacing={1} sx={{pt: 5}}>
				 <Grid item xs={12} lg={4}>
					 <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleCancel}>
						 {t('app.Cancel')}
					 </Button>
				 </Grid>
				 <Grid item xs={12} lg={4}>
				 </Grid>
				 <Grid item xs={12} lg={4}>
					 <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleSubmit}>
						 {t('app.Save')}
					 </Button>
				 </Grid>
			 </Grid>}
		</>
	);
}