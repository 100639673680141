import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {CircularProgress, Grid} from '@mui/material';
import {ListDocumentsBilanClient, Title, ToastMsg} from "../index";
import apiClient from "../../services/apiClient";
import Swal from "sweetalert2";
import theme from "../../theme";
import _ from "lodash";
import {useNavigate} from "react-router-dom";

function ClientDocumentSection({client}) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [errors, setErrors] = useState({})
    const [isFetching, setIsFetching] = useState(false)
    const [documents, setDocument] = useState([])
    const [isSend, setIsSend] = useState(false)

    useEffect( () => {
        setIsSend(false)
        if(client.id) {
            setIsFetching(true)
            apiClient.fetchClientDocumentList(client.id)
                .then(({data}) => {
                    setIsFetching(false)
                    setDocument(data)
                })
                .catch(error => {
                    setIsFetching(false)
                    setErrors({message : error.response.data.errors})
                })
        }
    },[client.id])

    const handleNotes = (documentData) => {
        navigate(`view/appointmentnotes/${documentData.idTreatment}`)
    }
    
    const handleModal = (documentData) => {
        if (_.isEmpty(documentData.appointmentData)) {
            Swal.fire({
                title: t('bilan.DocumentEmptyTitle'),
                text: t('bilan.DocumentEmptyText'),
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: t('bilan.SendDocumentToFillOut'),
                denyButtonText: t('bilan.EditDocumentToFillOut'),
                confirmButtonColor: theme.palette.primary.main,
                denyButtonColor: theme.palette.secondary.main,
                cancelButtonColor: theme.palette.secondary.light,
            }).then((result) => {
                if (result.isConfirmed) {
                    apiClient.SendDocumentToFillOutEmail(documentData.id, client)
                        .then(() => {
                            setIsSend(true)
                        })
                        .catch(error => {
                            setErrors({message : t('bilan.DocumentSentError')})
                        })
                } else if (result.isDenied) {
                    navigate(`edit/${documentData.id}`)
                }
            })
        }else{
            /* inputOptions can be an object or Promise */
            const inputOptions = apiClient.fetchDocListFromId(documentData.id).then((result) => {
                let list = {};
                result.data.forEach(function(doc) {list[doc.id] = doc.treatmentName + " - " + doc.appointmentDate})

                return list;
            });
            
            Swal.fire({
                title: t('bilan.ChooseDocumentTitle'),
                input: 'select',
                inputOptions: inputOptions,
                confirmButtonColor: theme.palette.primary.main,
            }).then((result) => {
                if (result.value) {
                    navigate(`viewdocument/${result.value}`)
                }
            })
        }
    }
    
    const handlePdf = (documentData) =>{
        if (_.isEmpty(documentData.appointmentData)) {
            Swal.fire({
                confirmButtonColor: theme.palette.primary.main,
                icon: 'warning',
                title: 'Aucun Document',
            })
        }else{
            /* inputOptions can be an object or Promise */
            const inputOptions = apiClient.fetchDocListFromId(documentData.id).then((result) => {
                let list = {};
                result.data.forEach(function(doc) {list[doc.id] = doc.treatmentName + " - " + doc.appointmentDate})

                return list;
            });
            
            Swal.fire({
                title: t('bilan.ChooseDocumentTitle'),
                input: 'select',
                inputOptions: inputOptions,
                confirmButtonColor: theme.palette.primary.main,
            }).then((result) => {
                if (result.value) {
                    navigate(`viewdocument/${result.value}/print`)                    
                }
            })
        }
    }

    return (
        <>
            {isFetching && <CircularProgress color="secondary" size={40}/>}
            {errors?.message &&
                <ToastMsg open={true} type='error' message={errors?.message}/>}
            {isSend &&
                <ToastMsg open={true} type='success' message={t('bilan.DocumentSentSuccess')}/>}
            {!isFetching && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Title
                                balise={'h3'}
                                title={t('bilan.BilanClientTitle')}
                                subtitle='' forgetLabel={false}/>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container spacing={2} alignItems="center">
                                <ListDocumentsBilanClient documents={documents} handleModal={handleModal} handlePdf={handlePdf} handleNotes={handleNotes}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}

export default ClientDocumentSection;