import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {CircularProgress, Grid} from '@mui/material';
import {Title, ToastMsg} from "../../index";
import theme from "../../../theme";
import {useNavigate, useParams} from "react-router-dom";
import 'moment/locale/fr';
import apiClient from "../../../services/apiClient";

const myStyles = {
    paper: {
        padding: theme.spacing(2),
    },
    icons:{
        fontSize:'60px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        }
    }
};

function FillEmptyDocumentPage(props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {token} = useParams()
    const [isModifiedDoc, setModified] = useState(false);
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)

    const fetchDocFromToken = async() => {
		setIsFetching(true)
		const {data, error} = await apiClient.fetchDocFromToken(token)
		if (data){
            if (!data.isModified) {
                navigate("/document/" + data.docId + "/" + data.clientId)
            }
            setModified(data.isModified)		
        }
		if (error){
			setErrors({msg: error})
		}
		setIsFetching(false)
	}

	useEffect(() => {
		fetchDocFromToken()
	}, [])

    return (
        <>
            {isFetching && <CircularProgress color="secondary" size={40}/>}
            {errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
            {isModifiedDoc && 
                <Grid container >
                    <Grid item xs={12}>
                        <Title title='document.AlreadyModified' subtitle='document.AlreadyModifiedSubtitle' forgetLabel={false}/>
                    </Grid>
                </Grid>
            }
        </>
    );
}

export default FillEmptyDocumentPage;