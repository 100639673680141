import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {DataGrid, enUS, frFR, GridColDef} from "@mui/x-data-grid";
import moment from "moment/moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {ModalViewGridOverflow, myStyles} from "components";
import EditIcon from "@mui/icons-material/Edit";
import {useMediaQuery} from "@mui/material";
import theme from "../../theme";

function ListClientGeneralNote({notes, handleOpen}) {
    const {t} = useTranslation()
	const [noteList, setNoteList] = useState(notes)
	const [note, setNote] = useState("")
	const [openModalOverflow, setOpenModalOverflow] = useState(false)
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
        setNoteList(notes)
    }, [notes])

	const columns: GridColDef[] = [
		{ field: 'id', hide:true },
		{
			field: "view",
			headerName:t("client.ViewHeader"),
			width: 100,
			align: 'center',
			sortable: false,
			renderCell: (client) => (
				<>
					<VisibilityIcon sx={myStyles.smallIcons} onClick={() => handleOpen(client.row.id)}/>
				</>
			)
		},
		{
            field: "note",
            headerName: t("notes.NoteListHeader"),
			flex: 1,
			hide : isMobile
        },
		{
			field: "create_at",
			headerName: t("notes.NoteDateListHeader"),
			flex: 0.5,
			hide : isMobile,
			renderCell: (client) => (
				<>
					<span>
						{moment(client.row.create_at).format('LLLL')}
					</span>
				</>
			)
		},
		{
			field: "action",
			headerName: t('app.Edit'),
			width: 100,
			align: 'center',
			sortable: false,
			renderCell: (client) => (
				<>
					<EditIcon sx={myStyles.smallIcons} onClick={() =>handleOpen(client.row.id)}/>
				</>
			)
		}
    ]

	const handleView = (note) => {
		setOpenModalOverflow(true)
		setNote(note)
	}

	const handleViewClose = (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpenModalOverflow(false)
			setNote("")
		}
	}

	return (
		<>
			<DataGrid
				autoHeight
				rows={noteList}
				localeText={localStorage.getItem("i18nextLng") === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
				disableColumnMenu={true}
				disableColumnFilter={true}
				hideFooterSelectedRowCount={true}
				disableDensitySelector={true}
				columns={columns}
			/>
		</>
	);
 }

export default ListClientGeneralNote;