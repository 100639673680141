import React, {useEffect, useState, useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import apiClient from "../../../services/apiClient";
import {useAuthContext} from "../../../contexts/auth";
import {AddNewClient, ModalAppointment, myStyles, Title, ToastMsg} from "components";
import moment from "moment";
import CircleIcon from '@mui/icons-material/Circle';
import {Backdrop, Box, CircularProgress, Grid, Stack, Tooltip, Typography, useMediaQuery} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EventIcon from '@mui/icons-material/Event';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Swal from "sweetalert2";
import theme from "../../../theme";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function AppointmentPage(props) {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const [errors, setErrors] = useState({});
	const [isProcessing, setIsProcessing] = useState(false);
	const [isUpdated, setIsUpdated] = useState(false);
	const [appointments, setAppointments] = useState([]);
	const [modalOpenAppointment, setModalOpenAppointment] = useState(false);
	const [modalOpenAddClient, setModalOpenAddClient] = useState(false);
	const [appointmentInfos, setAppointmentInfos] = useState({});
	const [ clientList, setClientList ] = useState([])
	const [ isSend, setIsSend ] = useState(false)
	const [added, setAdded] = useState()
	const {user} = useAuthContext()
	const calendarRef = useRef(null);


	useEffect(() => {
		setIsProcessing(true);
		setModalOpenAddClient(false);
		setIsUpdated(false);
		apiClient.fetchAllCenterAppointments(user.centerId).then(({data}) => {
			setAppointments(data);
			return apiClient.fetchCenterClientList(user.centerId)
		}).then(({data}) => {
			setClientList(makeLabelFromList(data))
		}).finally(() => {
			setIsProcessing(false)
			setErrors({})
		})
	}, [isUpdated, added]);

	const makeLabelFromList = (list) => {
		const options = [];
		list && list.forEach(function(option) {
			options.push({ label: option.firstName + ' ' + option.lastName, id: option.id })
		});
		return options
	}

	const renderEventContent = (eventInfo: EventContentArg) => {
		let viewTypeCalendar = eventInfo.view.type ? eventInfo.view.type === 'dayGridMonth' : false;

		return (
			<Grid container spacing={0} sx={{border : viewTypeCalendar ? '1px solid lightGrey': 'none', borderRadius: '5px'}}>
				<Grid item xs={12} sx={{ 'display': 'flex', 'alignItems': 'center'}}>
					{viewTypeCalendar && !isMobile &&
						<CircleIcon style={{color: eventInfo.event.backgroundColor, fontSize: '1,1em'}}/>
					}
					<Typography variant="body1" style={{fontWeight:'bold'}}>{eventInfo.timeText}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2" sx={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
						{eventInfo.event.title} - {t(`treatment.${eventInfo.event.extendedProps.treatmentName}`)}
					</Typography>
				</Grid>
			</Grid>
		)
	};

	const handleAppointmentClick = (appointment) => {
		if (moment(appointment.event.start).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
			setAppointmentInfos({
				id: appointment.event.id,
				start: appointment.event.start,
				end: appointment.event.end,
				idClient: appointment.event.extendedProps.idClient,
				idDocument: appointment.event.extendedProps.idDocument,
				clientName: appointment.event.extendedProps.clientName,
				treatmentId: appointment.event.extendedProps.treatmentId,
				note: appointment.event.extendedProps.note,
			});
			setModalOpenAppointment(true);
		}
	}

	const handleAppointmentDragToNewDate = (appointment) => {
		setIsProcessing(true);
		setIsUpdated(false)
		const {id, start} = appointment.event;
		if (moment(start).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
			appointment.revert();
		}else{
			apiClient.updateClientDragAppointment(id, start).then(() => {
				setIsUpdated(true);
			}).catch(err => {
				setErrors({...errors, msg: true})
			}).finally(
				setIsProcessing(false)
			)
		}
	}

	const handleAppointmentResize = (appointment) => {
		const {id} = appointment.event;
		apiClient.updateClientTimeAppointment(id, Math.abs(moment(appointment.oldEvent.start).diff(appointment.event.end, 'minutes'))).then(() => {
			setIsUpdated(true);
		}).catch(err => {
			setErrors({...errors, msg: true})
		}).finally(
			setIsProcessing(false)
		)
	}

	const handleCloseModal = () => {
		setAppointmentInfos({});
		setModalOpenAppointment(false);
	}

	const handleAddAppointment = () => {
		setModalOpenAppointment(true);
	}

	const handleModalAddClient = () => {
		setModalOpenAddClient(true)
	}

	const handleCancel = () => {
		navigate(-1);
	}

	const handleUpdate = (isFirstTimeAppointment = false, appointment = null) => {
		setIsUpdated(true);
		handleCloseModal();
		if (isFirstTimeAppointment) {
		 	Swal.fire({
		 		title: t('bilan.AppointmentFirstTimeTitle'),
		 		text: t('bilan.AppointmentFirstTimeText'),
		 		showCancelButton: true,
		 		confirmButtonText: t('bilan.SendDocumentToFillOut'),
		 		confirmButtonColor: theme.palette.primary.main,
		 		cancelButtonColor: theme.palette.secondary.light,
		 	}).then((result) => {
		 		if (result.isConfirmed) {
					apiClient.SendDocumentToFillOutEmailFromCalendar(appointment)
						.then(() => {
							setIsSend(true)
						})
		 		}}
		 	)
		}
	}
	const handleCancelAppointment = () => {
		setIsUpdated(true);
		handleCloseModal();
	}

	const handleClose = (event, reason) => {
		if (reason !== 'backdropClick') {
			setModalOpenAddClient(false)
		}
	}

	const handleAddClient = () => setAdded(true)

	const handleInitialView = () => {
		// Validate that the calendarRef is referenced
		if (calendarRef.current) {
			// Validate that the initialView is undefined (so in init state)
			if (calendarRef.current?.props.initialView === undefined)
			{
				const api = calendarRef.current?.getApi()
				api.changeView(isMobile ? 'timeGridDay' : 'dayGridMonth')
			}
		}
	}

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isProcessing}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
			{errors?.msg && <ToastMsg open={errors?.msg} type='error' message={t('app.Error')}/>}
			{isUpdated && <ToastMsg open={isUpdated} type='success' message={t('appointmentPage.Success')}/>}
			{added && <ToastMsg open={added} type='success' message={t('appointmentPage.SuccessAddClient')}/>}
			{isSend && <ToastMsg open={isSend} type='success' message={t('appointmentPage.SuccessSend')}/>}
			<AddNewClient open={modalOpenAddClient} handleClose={handleClose} handleAddClient={handleAddClient} fastAdd={true}/>
			<ModalAppointment open={modalOpenAppointment} handleCloseModal={handleCloseModal} appointmentInfos={appointmentInfos} handleUpdate={handleUpdate} clientList={clientList} handleCancel={handleCancelAppointment}/>
			<Grid container spacing={1} sx={{mb:5}}>
				<Grid item xs={12} sx={{pb:3}}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Tooltip title={t('app.BackToClientPage')} sx={myStyles.icons} >
							<ArrowBackIosIcon onClick={() => handleCancel()}/>
						</Tooltip>
						<Typography variant="h2">{t('appointmentPage.Title')}</Typography>
						<Box sx={{display:'flex'}}>
							<Tooltip title={t('appointmentPage.AddClient')} sx={myStyles.icons} >
								<PersonAddAlt1Icon sx={myStyles.icons} onClick={() => handleModalAddClient()}/>
							</Tooltip>
							<Tooltip title={t('appointmentPage.AddAppointment')} sx={myStyles.icons} >
								<EventIcon sx={myStyles.icons} onClick={() => handleAddAppointment()}/>
							</Tooltip>
						</Box>
					</Stack>
				</Grid>
			</Grid>
			<FullCalendar
				ref={calendarRef}
				events={appointments}
				locale= 'fr'
				plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
				initialView={handleInitialView()}
				eventDrop={(e) => handleAppointmentDragToNewDate(e)}
				eventResize= {(e) => handleAppointmentResize(e)}
				headerToolbar={{
					left: "prev,next,today",
					center: "title",
					right: "dayGridMonth,timeGridDay"
				}}
				buttonText={{
					'today': t('appointmentPage.Today'),
					'dayGridMonth': t('appointmentPage.Month'),
					'timeGridWeek': t('appointmentPage.Week'),
					'timeGridDay': t('appointmentPage.Day'),
				}}
				buttonHints={{
					'today': t('appointmentPage.Today'),
					'prev': t('appointmentPage.Prev'),
					'next': t('appointmentPage.Next'),
					'dayGridMonth': t('appointmentPage.Month'),
					'timeGridWeek': t('appointmentPage.Week'),
					'timeGridDay': t('appointmentPage.Day'),
				}}
				validRange={{
					start: moment()
				}}
				businessHours= {{
					startTime: '06:00',
					endTime: '21:00',
				}}
				backgroundColor={(e) => e.backgroundColor}
				eventTextColor={'black'}
				eventContent={renderEventContent}
				eventClick={(e) => handleAppointmentClick(e)}
			/>
		</>
	);
}

export default AppointmentPage;