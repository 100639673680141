import React, {useContext, useState} from 'react'
import {FormContext} from '../../../contexts/FormContext'
import {FormContextNoAuth} from '../../../contexts/FormContextNoAuth'
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {FormExamenPeauContext} from "../../../contexts/FormExamenPeauContext";

const FormSelect = ({field_id, field_label, field_placeholder, field_value, field_options, viewMode, isNoAuth, isExamenPeau}) => {
	const { handleChange } = useContext(isNoAuth ? FormContextNoAuth : !isExamenPeau ? FormContext : FormExamenPeauContext);
	const [isDisabled, setIsDisabled] = useState(viewMode);
	const labelId = 'label-' + field_id;
	return (
		<>
			<FormControl disabled={isDisabled} variant="standard" >
				<InputLabel id={labelId}>{field_label}</InputLabel>
				<Select class="form-control"
					id={field_id}
					onChange={event => handleChange(field_id, event)}
					disabled={isDisabled}
					labelId={labelId}
					label={field_label}
					value={field_value}
				>
					{field_options.map((option, i) => (
						<MenuItem  value={option.option_label} key={i}>{option.option_label}</MenuItem >
					))}
				</Select>
			</FormControl>
		</>
	)
}

export default FormSelect
