import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
	DataGrid,
	enUS,
	frFR,
	GridColDef, GridToolbarDensitySelector,
	GridToolbarFilterButton,
} from '@mui/x-data-grid';
import theme from "../../theme";
import moment from "moment/moment";
import ModalViewGridOverflow from "../modal/ModalViewGridOverflow";
import {Tooltip, useMediaQuery} from "@mui/material";
import {myStyles} from "../index";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ListNoteAppointment({list}) {
	const {t} = useTranslation();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const [openModal, setOpenModal] = useState(false);
	const [noteRendezVous, setNoteRendezVous] = useState({});

	const columns: GridColDef[] = [
		{
			field: "view",
			headerName:t("client.ViewHeader"),
			width: 70,
			align: 'center',
			sortable: false,
			hide: !isMobile,
			renderCell: (note) => (
				<>
					<Tooltip title={t('client.ViewHeader')} sx={myStyles.smallIcons} >
						<VisibilityIcon onClick={() => handleViewNote(note)}/>
					</Tooltip>

				</>
			)
		},
		{
			field: "date",
			headerName: t("appointmentNotes.Date"),
			type: 'date',
			flex: 0.5,
			renderCell: (appointmentNote) => (
				<>
					<span>
						{moment(appointmentNote.row.date).format('LL')}
					</span>
				</>
			),
			editable: false,
		},
		{ field: 'note', headerName: t('appointmentNotes.Note'), flex: 3, editable: false,hide: isMobile},
		{ field: 'employeeName', headerName: t('appointmentNotes.Employee'), flex: 1, editable: false,hide: isMobile},

	];

	const handleClose= (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpenModal(false);
			setNoteRendezVous({});
		}
	}

	const handleViewNote = (note) => {
		setOpenModal(true);
		setNoteRendezVous(note.row);
	}

	return (
		<>
			<ModalViewGridOverflow open={openModal} handleClose={handleClose} data={noteRendezVous} />
			<DataGrid
				autoHeight
				rows={list}
				localeText={localStorage.getItem("i18nextLng") === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
				disableColumnMenu={true}
				disableColumnFilter={true}
				hideFooterSelectedRowCount={true}
				disableDensitySelector={true}
				columns={columns}
			/>
		</>

	);
}

export default ListNoteAppointment;