import React, {useEffect, useState} from 'react';
import {TreatmentCardList, Title, ToastMsg, myStyles, ModalNoteGeneral} from 'components';
import {Backdrop, Box, CircularProgress, Grid, Stack, Tooltip, Typography} from '@mui/material';
import AddBusinessRounded from "@mui/icons-material/AddComment";
import apiClient from '../../../services/apiClient';
import {useAuthContext} from '../../../contexts/auth';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from "react-router-dom";
import {BaseTextField} from '../../textfield/StyledTextField';
import ListNoteAppointment from '../../grid/ListNoteAppointment'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import theme from '../../../theme';
import {ModalAppointmentNote} from '../../index'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import {TREATMENTS} from "../../../constants/EnumTreatment";
import SaveIcon from "@mui/icons-material/Save";

function AppointmentNotePage({viewOnly}) {
	const {t} = useTranslation();
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)
	const [notes, setNotes] = useState([])
	const [note, setNote] = useState(null)
	const { user } = useAuthContext()
	const {id, idTreatment} = useParams()
	const [client, setClient] = useState({})
	const [treatmentName, setTreatmentName] = useState(null)
	const [isUpdated, setIsUpdated] = useState(false)
	const [open, setOpen] = useState(false);
	const [generalNote, setGeneralNote] = useState(false)
	const navigate = useNavigate();

	useEffect(() => {
		setIsFetching(true)
		setIsUpdated(false)
		apiClient.fetchGeneralTreatmentNote(id, idTreatment).then(({data}) => {
			setNotes(data)
			setIsFetching(false)
			return apiClient.fetchClientName(id)
		}).then(({data}) => {
			setClient({clientId: id, firstName: data.firstName, lastName: data.lastName})
			return apiClient.fetchTreatmentName(idTreatment)
		}).then(({data}) => {
			setTreatmentName(data)
		}).catch(error => {
			setIsFetching(false)
			setErrors(error.response.data.errors)
		})
	}, [isUpdated])

	function onChangeNote(e){
		setNote(e.target.value)
	}

	function saveNote() {
		apiClient.addGeneralTreatmentAppointmentNote(note, id, idTreatment, user.id).then(({data}) => {
			setIsUpdated(true)
			setNote('')
		}).catch(error => {
			setIsFetching(false)
			setErrors(error.response.data.errors)
		})
	}

	const handleCancel = () => {
		navigate('/center/client/' + id)

	}

	const setLabel = () => {
		let label = null
		switch (parseInt(idTreatment)) {
			case TREATMENTS.EpilationCire:
			case TREATMENTS.EpilationSucre:
				label = t("appointmentNotes.EpilationNote")
				break;
			case TREATMENTS.Pedicure:
				label = t("appointmentNotes.PedicureNote")
				break;
			case TREATMENTS.Manucure:
				label = t("appointmentNotes.ManucureNote")
				break;
			case TREATMENTS.Massage:
				label = t("appointmentNotes.MassageNote")
				break;
			case TREATMENTS.PoseOngles:
				label = t("appointmentNotes.PoseOnglesNote")
				break;
			case TREATMENTS.ExtensionCils:
			case TREATMENTS.RehaussementCils:
				label = t("appointmentNotes.ExtensionCilsNote")
				break;
			default:
				label = t("appointmentNotes.GlobalNote")
				break;
		}
		return label
	}

	const handleOpen = () => {
		setOpen(true);
	}

	const handleClose= (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpen(false);
			setGeneralNote(false)
		}
	}

	const handleCrudCompleted = () => {
		setOpen(false)
		setGeneralNote(true)
	}

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isFetching}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
			{generalNote &&
				<ToastMsg open={true} type="success" message={t('app.SaveSuccess')}/>
			}
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{isUpdated && <ToastMsg open={true} type='success' message={t('app.SaveSuccess')}/>}
			<ModalNoteGeneral open={open} handleClose={handleClose} handleCrudCompleted={handleCrudCompleted} editNote={false}/>
			<Grid container>
				<Grid item xs={12} >
					<Tooltip title={t('app.BackToClientPage')} sx={myStyles.icons} >
						<ArrowBackIosIcon onClick={() => handleCancel()}/>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<div>
							<Typography variant="h2">
								{client.firstName + ' ' + client.lastName}
							</Typography>
							<Typography variant="h6" gutterBottom>
								{t(`treatment.${treatmentName}`) + ' - ' + t('appointmentNotes.Title')}
							</Typography>
						</div>
						{!viewOnly &&
							<Box sx={{display:'inline-flex'}}>
								<Tooltip title={t('electrolyseNotes.AddGeneralNote')} sx={myStyles.icons} >
									<AddBusinessRounded onClick={e => handleOpen()} />
								</Tooltip>
								<Tooltip title={t('examenPeauNotes.SaveNote')} sx={myStyles.icons} >
									<SaveOutlinedIcon sx={myStyles.icons} onClick={e => saveNote()} />
								</Tooltip>
							</Box>
						}
					</Stack>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<ListNoteAppointment list={notes}/>
			</Grid>
			{!viewOnly &&
				<Grid item xs={12}>
					<BaseTextField
						label={setLabel()}
						fullWidth
						multiline={true}
						minRows="5"
						autoFocus = {true}
						variant="outlined"
						onChange={e => onChangeNote(e)}
						value={note? note : ''}
					/>
				</Grid>
			}

		</>
	);
}

export default AppointmentNotePage;