import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {Box, Button, Grid, Modal, Stack, Typography} from '@mui/material';
import {myStyles, Title} from "components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment/moment";

function  ModalViewGridOverflow({open, handleClose, data}) {
    const {t} = useTranslation()
	const [dataNote, setDataNote] = useState()

	useEffect( () => {
		setDataNote(data)
	}, [data])

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-overflow"
				aria-describedby="modal-modal-overflow-description"
			>
				<Box sx={myStyles.smallBoxModal}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Title balise={'h4'} title={t('notes.NoteRendezVousTitle')} subtitle='' forgetLabel={false}/>
						<HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
					</Stack>
					<Grid container alignItems="center" sx={{pt:5}}>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("notes.Date")} : </Typography>
							<Typography display="inline" variant="body1">{moment(dataNote?.date).format('LL')}</Typography>
						</Grid>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("notes.Note")} : </Typography>
							<Typography display="inline" variant="body1">{dataNote?.note}</Typography>
						</Grid>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("electrolyseNotes.Employee")} : </Typography>
							<Typography display="inline" variant="body1">{dataNote?.employeeName}</Typography>
						</Grid>
						<Grid item xs={12} lg={4} sx={{pt:3}}>
							<Button fullWidth variant="contained" color="secondary" size="large" type="submit"  onClick={handleClose}>
								{t('app.Close')}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>

		</>
	);
 }

export default ModalViewGridOverflow;