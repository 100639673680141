import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FormContext} from "../../../contexts/FormContext";
import {FormContextNoAuth} from '../../../contexts/FormContextNoAuth'
import {FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import {FormExamenPeauContext} from "../../../contexts/FormExamenPeauContext";

const FormRadioYesNo = ({field_id, field_label, field_value, field_attributes, viewMode, isNoAuth, isExamenPeau}) => {
    const { handleChange } = useContext(isNoAuth ? FormContextNoAuth : !isExamenPeau ? FormContext : FormExamenPeauContext);
    const [isSelected, setIsSelected] = useState(field_value)
    const [isDisabled, setIsDisabled] = useState(viewMode);
    const {t} = useTranslation()
    const xs = field_attributes?.find(element => element.xs)?.xs ? field_attributes?.find(element => element.xs).xs : 12
    const md = field_attributes?.find(element => element.md)?.md ? field_attributes?.find(element => element.md).md : 12
    const lg = field_attributes?.find(element => element.lg)?.lg ? field_attributes?.find(element => element.lg).lg : 12

    const handleRadioBtn = (event) => {
        setIsSelected(event.target.value)
        handleChange(field_id, event)
    };

    return (
        <Grid item xs={xs} md={md} lg={lg}>
            <FormLabel component="legend">{t(field_label)}</FormLabel>
            <RadioGroup row aria-label={field_label} name="row-radio-buttons-group" onChange={handleRadioBtn}
                        value={isSelected}>
                <FormControlLabel disabled={isDisabled} value="yes" control={<Radio/>} label={t('form.Yes')}/>
                <FormControlLabel disabled={isDisabled} value="no" control={<Radio/>} label={t('form.No')}/>
            </RadioGroup>
        </Grid>
    )
}

export default FormRadioYesNo;