import React, {createContext, useContext, useEffect, useState} from 'react'
import {useAuthContext} from "./auth";
import {useTranslation} from "react-i18next";

const ApplicationAuthContext = createContext(null)

export const ApplicationAuthContextProvider = ({ children }) => {
    const applicationValue = {}

    return (
        <ApplicationAuthContext.Provider value={applicationValue}>
            <>
                {children}
            </>
        </ApplicationAuthContext.Provider>
    )
}

export const useApplicationAuthContext = () => useContext(ApplicationAuthContext)