import React, {createContext, useContext, useEffect, useState} from 'react'
import {useAuthContext} from "./auth";
import {useTranslation} from "react-i18next";
import apiClient from "../services/apiClient";
import {FollowUpCard, ModalCrudFollowUp, ToastMsg} from "../components";
import {Typography} from "@mui/material";
import ModalViewFollowUp from "../components/modal/ModalViewFollowUp";

const FollowUpContext = createContext(null)

export const FollowUpContextProvider = ({ children }) => {
    const { t } = useTranslation()
    const { user, initialized } = useAuthContext()
    const [ error, setError ] = useState(null)
    const [ followUpList, setFollowUpList ] = useState([])
    const [ followUpListAmount, setFollowUpListAmount ] = useState(0)
    const [ isEditFollowUp, setIsEditFollowUp ] = useState(false)
    const [ openViewFollowUp, setOpenViewFollowUp ] = useState(false)
    const [ openCrudFollowUp, setOpenCrudFollowUp ] = useState(false)
    const [ selectedFollowUp, setSelectedFollowUp ] = useState({});
    const [ followUpCompleted, setFollowUpCompleted ] = useState(false);
    const [ clientList, setClientList ] = useState([])

    useEffect(() => {
        if (initialized) {
            apiClient.fetchCenterFollowUpList(user.centerId).then(({data}) => {
                setFollowUpList(data)
                setFollowUpListAmount(data?.length)
                return apiClient.fetchCenterClientList(user.centerId)
            }).then(({data}) => {
              setClientList(makeLabelFromList(data))
            })
        }
    }, [initialized, followUpCompleted]);

    const makeLabelFromList = (list) => {
        const options = [];
        list && list.forEach(function(option) {
            options.push({ label: option.firstName + ' ' + option.lastName, id: option.id })
        });
        return options
    }

    const initializeFollowUp = () => {
        setError(null)
        setSelectedFollowUp({});
        setOpenViewFollowUp(false);
        setOpenCrudFollowUp(false);
    }

    const handleComplete = (id) => {
        const idToComplete = selectedFollowUp?.id || id;
        apiClient.setFollowUpCompleted(idToComplete).then(({data}) => {
            setFollowUpCompleted(true);
            initializeFollowUp();
        }).catch(err => {
            setError({... error, message: t('error.followUp.complete')})
        })
    }

    const handleSaveCrud = (followUp) => {
        let followUpToSave = {...followUp};
        if (!followUpToSave.id) {
            followUpToSave.centerId = user.centerId;
            followUpToSave.employeeId = user.id;
        }
        apiClient.crudClientFollowUp(followUpToSave).then(({data}) => {
            setFollowUpCompleted(true);
            initializeFollowUp();
        }).catch(err => {
            setError({... error, msg:true})
        })
    }

    const handleViewOpen = (selected) => {
        setFollowUpCompleted(false);
        setOpenViewFollowUp(true)
        setSelectedFollowUp(selected)
    };

    const showFollowUpList = () => {
        return (
            followUpList?.length > 0 ? (
                followUpList.map((item, index) => {
                    return <FollowUpCard key={index} item={item} handleOpen={handleViewOpen}/>
                })
            ): (
                <Typography variant="h6" gutterBottom component="div">
                    {t('followUp.NoFollowUp')}
                </Typography>
            )
        )
    }

    const switchToEditMode = () => {
        setIsEditFollowUp(true)
        setOpenViewFollowUp(false)
        setOpenCrudFollowUp(true)
    }

    const handleCrudOpen = (selected) => {
        setSelectedFollowUp(selected)
        setIsEditFollowUp(true)
        setOpenViewFollowUp(false)
        setOpenCrudFollowUp(true)
    };

    const handleAddFollowUpClick = (client = null) => {
        client && setSelectedFollowUp({
            clientId: client.clientId || client.id,
            clientName: client.firstName + ' ' + client.lastName,
        })
        setIsEditFollowUp(false)
        setOpenViewFollowUp(false)
        setOpenCrudFollowUp(true)
    }

    const handleDeleteFollowUp = (id) => {
        apiClient.deleteClientFollowUp(id).then(({data}) => {
            setFollowUpCompleted(true);
            initializeFollowUp();
        }).catch(err => {
            setError({... error, msg: true})
        }).finally(() => {
            setOpenViewFollowUp(false)
        }
        )
    }

    const followUpValue = {clientList, followUpList, followUpListAmount,followUpCompleted, handleAddFollowUpClick, handleComplete,handleCrudOpen,handleDeleteFollowUp, handleViewOpen, showFollowUpList}

    return (
        <FollowUpContext.Provider value={followUpValue}>
            <>
                { followUpCompleted && <ToastMsg open={true} type='success' message={t('app.Success')}/>}
                { error?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
                <ModalViewFollowUp open={openViewFollowUp} handleComplete={handleComplete} handleClose={() => setOpenViewFollowUp(false)} followUp={selectedFollowUp} switchToEditMode={switchToEditMode}/>
                <ModalCrudFollowUp isEditFollowUp={isEditFollowUp} open={openCrudFollowUp} handleClose={initializeFollowUp} followUp={selectedFollowUp} handleSaveCrud={handleSaveCrud}/>
                {children}
            </>
        </FollowUpContext.Provider>
    )
}

export const useFollowUpContext = () => useContext(FollowUpContext)