import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {CircularProgress, Fab, Grid, Link, Paper} from "@mui/material";
import {
	CenterPlanInformations,
	EditCenterInfoForm,
	EditCenterLogo,
	EmployeeSection,
	ServicesSection,
	myStyles,
	Title,
	ToastMsg
} from "components";
import {useAuthContext} from '../../../contexts/auth';
import {useNavigate} from "react-router-dom";

function CenterPage(props) {
	const {t} = useTranslation();
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)
	const { user, initialized} = useAuthContext()
	const navigate = useNavigate();

	useEffect(() => {
		if(user.isEmployee) {
			navigate("/portail")
		}
	}, [])

	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{!isFetching &&(
				<>
					<Grid container spacing={3} sx={{pb:5}}>
						<Grid item xs={12}>
							<Link sx={myStyles.anchorLink} href="#informations" underline="hover">
								{t('centerPage.Informations')}
							</Link>
							<Link sx={myStyles.anchorLink} href="#services" underline="hover">
								{t('centerPage.Services')}
							</Link>
							<Link sx={myStyles.anchorLink} href="#employees" underline="hover">
								{t('centerPage.SectionEmployees')}
							</Link>
						</Grid>
					</Grid>
					<Grid container >
						<Grid item xs={12}>
							<Title title='centerPage.Title' subtitle='' forgetLabel={true}/>
						</Grid>
					</Grid>
					<Grid container alignItems="center" sx={{paddingTop:5}} spacing={3} id="informations">
						<Grid item xs={12}>
							<Paper sx={{p: 2}}>
								<EditCenterLogo />
							</Paper>
						</Grid>
						<Grid item xs={12} lg={8} >
							<EditCenterInfoForm />
						</Grid>
						<Grid item xs={12} lg={4}>
							<CenterPlanInformations />
						</Grid>
					</Grid>

					<Grid container sx={{pt:5}} id="services">
						<Grid item xs={12} >
							<Paper sx={{p: 2}}>
								<ServicesSection />
							</Paper>
						</Grid>
					</Grid>

					<Grid container sx={{pt:5}} id="employees" >
						<Grid item xs={12} >
							<Paper sx={{p: 2}}>
								<EmployeeSection />
							</Paper>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
}

export default CenterPage;