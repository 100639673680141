import React from 'react'
import {FormDatePicker, FormLabel, FormInput, FormSelect, FormTextArea, FormCheckbox,FormMultiCheckbox, FormRadioYesNo} from "../index";
import {Grid} from "@mui/material";
import FormImage from "./elements/FormImage";
import FormTypographie from "./elements/FormTypographie";
import FormCheckboxGroup from "./elements/FormCheckboxGroup";

const Element = ({field: {field_type, field_id, field_label,field_mandatory, field_placeholder, field_value, field_options, field_attributes = null, field_group}, viewMode= false, isNoAuth = false, isExamenPeau = false}) => {
	switch (field_type) {
		case 'checkboxGroup':
			return (
				<FormCheckboxGroup
					field_id={field_id}
					field_group={field_group}
					field_attributes={field_attributes}
					viewMode={viewMode}
					isNoAuth={isNoAuth}
					isExamenPeau={isExamenPeau}
				/>
			)
		case 'datepicker':
			return (
				<FormDatePicker
					field_id={field_id}
					field_label={field_label}
					field_value={field_value}
					field_attributes={field_attributes}
					viewMode={viewMode}
					isNoAuth={isNoAuth}
					isExamenPeau={isExamenPeau}
				/>
			)
		case 'newRow':
			return (
				<Grid item xs={12}> </Grid>
			)
		case 'label':
			return (<FormLabel
				field_label={field_label}
				field_attributes={field_attributes}
			/>)
		case 'image':
			return (<FormImage
				field_id={field_id}
				field_label={field_label}
				field_placeholder={field_placeholder}
				field_value={field_value}
				field_attributes={field_attributes}
				field_mandatory={field_mandatory}
			/>)
		case 'text':
			return (<FormInput
				field_id={field_id}
				field_label={field_label}
				field_placeholder={field_placeholder}
				field_value={field_value}
				field_attributes={field_attributes}
				field_mandatory={field_mandatory}
				viewMode={viewMode}
				isNoAuth={isNoAuth}
				isExamenPeau={isExamenPeau}
				/>)
		case 'textarea':
			return (<FormTextArea
				field_id={field_id}
				field_label={field_label}
				field_placeholder={field_placeholder}
				field_value={field_value}
				field_attributes={field_attributes}
				field_mandatory={field_mandatory}
				viewMode={viewMode}
				isNoAuth={isNoAuth}
				isExamenPeau={isExamenPeau}
			/>)
		case 'sectionSubtitle':
		case 'subQuestion':
			return (<FormTypographie
				field_label={field_label}
				field_attributes={field_attributes}
			/>)
		case 'select':
			return (<FormSelect
				field_id={field_id}
				field_label={field_label}
				field_placeholder={field_placeholder}
				field_value={field_value}
				field_options={field_options}
				viewMode={viewMode}
				isNoAuth={isNoAuth}
				isExamenPeau={isExamenPeau}
				/>)
		case 'checkbox':
			return (<FormCheckbox
				field_id={field_id}
				field_label={field_label}
				field_value={field_value}
				field_attributes={field_attributes}
				viewMode={viewMode}
				isNoAuth={isNoAuth}
				isExamenPeau={isExamenPeau}
				/>)
		case 'multiCheckbox':
			return (<FormMultiCheckbox
				field_id={field_id}
				field_label={field_label}
				field_options={field_options}
				field_attributes={field_attributes}
				viewMode={viewMode}
				isNoAuth={isNoAuth}
				isExamenPeau={isExamenPeau}
			/>)
		case 'radioYesNo':
			return (<FormRadioYesNo 
				field_id={field_id}
				field_label={field_label}
				field_value={field_value}
				field_attributes={field_attributes}
				viewMode={viewMode}
				isNoAuth={isNoAuth}
				isExamenPeau={isExamenPeau}
			/>)
		default:
			break;
	}
	return null;
}
export default Element