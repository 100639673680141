import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import apiClient from 'services/apiClient';
import {
    Alert,
    CircularProgress,
    FormControlLabel,
    FormLabel,
    Grid,
    Link,
    Paper,
    Radio,
    RadioGroup, Tooltip
} from "@mui/material";
import {myStyles, Title, ToastMsg} from "components";
import SaveIcon from "@mui/icons-material/Save";
import {useAuthContext} from "../../contexts/auth";
import {BaseTextField} from "../textfield/StyledTextField";
import MapAutoComplete from "../textfield/MapAutoComplete";
import NumberFormat from "react-number-format";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="(###) ###-####" mask="_"
        />
    );
});

function EditCenterInfoForm(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const { user, initialized, setUser} = useAuthContext()
    const [edited, setEdited] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const [form, setForm] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        centerName: user.centerName,
        address: user.centerAddress,
        local: user.centerLocal,
        mainPhone: user.centerMainPhone,
        altPhone: user.centerAltPhone,
        langPreferences: user.centerLangPreferences,
    });

    const handleEdit = () =>{
        setErrors((e) => ({...e, form: null}))

        const requiredFields = [
            'firstName',
            'lastName',
            'centerName',
            'address',
            'mainPhone'
        ];
        let hasError = false;

        Object.entries(form).map(field => {
            if (requiredFields.indexOf(field[0]) !== -1 && field[1] === '') {
                setErrors(
                    (e) => ({...e, [field[0]]: "app.RequiredFields"}))
                hasError = true
            }
        })
        if (!hasError) {
            setIsProcessing(true)
            setEdited(false)
            apiClient.editCenterInformation(form, user.centerId)
                .then(({data}) => {
                    setEdited(true)
                    setUser(data)
                })
                .catch(error => {
                    setErrors({msg: error.response.data.errors})
                })
            setIsProcessing(false)
        }
    }

    const handleChange = (element) => {
        if (errors.hasOwnProperty(element.target.name)) {
            setErrors((e) => ({...e, [element.target.name]: null}))
        }
        setForm({...form, [element.target.name]: element.target.value});
    }

    const handleChangeAutoComplete = (value) => {
        setErrors((e) => ({...e, address: null}))
        setForm(
            {...form, address: (value?.description ? value.description : '')})
    }

    function handleLangChange(element) {
        setErrors((e) => ({...e, langPreferences: null}))
        setForm({...form, langPreferences: element.target.value})
    }

    return (
        <>
            {errors?.server &&
                <ToastMsg open={true} type='error' message={t('app.Error')}/>}
            {edited && <ToastMsg open={true} type='success'
                                 message={t('centerPage.EditedSuccess')}/>}
            <Paper sx={{p: 2}}>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Title
                            balise={'h3'}
                            title={t('centerPage.CenterInformations')}
                            subtitle='' forgetLabel={false}/>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{textAlign: 'right'}}>
                        {isProcessing &&
                            <CircularProgress color="secondary" size={40}/>}
                        <Tooltip title={t('centerPage.Save')} sx={myStyles.icons} >
                            <SaveIcon sx={myStyles.icons} onClick={handleEdit}/>
                        </Tooltip>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <BaseTextField
                                label={t('centerPage.FirstName')}
                                fullWidth
                                variant="outlined"
                                name="firstName"
                                onChange={handleChange}
                                value={form?.firstName}
                                error={errors.firstName && true}
                                helperText={errors.firstName && t(errors.firstName)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BaseTextField
                                label={t('centerPage.LastName')}
                                fullWidth
                                variant="outlined"
                                name="lastName"
                                onChange={handleChange}
                                value={form?.lastName}
                                error={errors.lastName && true}
                                helperText={errors.lastName && t(errors.lastName)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BaseTextField
                                label={t('centerPage.CenterName')}
                                fullWidth
                                variant="outlined"
                                name="centerName"
                                onChange={handleChange}
                                value={form?.centerName}
                                error={errors.centerName && true}
                                helperText={errors.centerName && t(errors.centerName)}
                            />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <MapAutoComplete valueAutoComplete={form?.address}
                                             errorAutoComplete={errors.address && true}
                                             helperTextAutoComplete={errors.address && t(errors.address)}
                                             handleChangeAutoComplete={handleChangeAutoComplete}/>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <BaseTextField
                                label={t('centerPage.Local')}
                                fullWidth
                                variant="outlined"
                                name="local"
                                onChange={handleChange}
                                value={form?.local || ""}
                                error={errors.local && true}
                                helperText={errors.local && t(errors.local)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BaseTextField
                                label={t('centerPage.MainPhone')}
                                required
                                fullWidth
                                onChange={handleChange}
                                name="mainPhone"
                                id="formatted-mainPhone-input"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                variant="outlined"
                                value={form?.mainPhone}
                                error={errors.mainPhone && true}
                                helperText={errors.mainPhone &&
                                    t(errors.mainPhone)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BaseTextField
                                label={t('centerPage.AltPhone')}
                                fullWidth
                                onChange={handleChange}
                                name="altPhone"
                                id="formatted-altPhone-input"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                variant="outlined"
                                value={form?.altPhone || ""}
                                error={errors.altPhone && true}
                                helperText={errors.altPhone &&
                                    t(errors.altPhone)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="legend">{t('app.LangPreference')}</FormLabel>
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group" onChange={handleLangChange} value={form.langPreferences}>
                                <FormControlLabel value="fr" control={<Radio />} label={t('app.French')} />
                                <FormControlLabel value="en" control={<Radio />} label={t('app.English')} />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>

    );
}

export default EditCenterInfoForm;