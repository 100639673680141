import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select, Stack, Tooltip,
    Typography
} from '@mui/material';
import {myStyles} from 'components';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {useAuthContext} from "../../contexts/auth";
import {TREATMENTS} from "../../constants/EnumTreatment";
import moment from "moment";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {BaseTextField} from "../textfield/StyledTextField";
import {DateTimePicker} from "@mui/x-date-pickers";
import NumberFormat from "react-number-format";
import apiClient from "../../services/apiClient";
import {Autocomplete} from "@mui/lab";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SaveIcon from "@mui/icons-material/Save";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
});

function ModalAddAppointment({open, handleCloseModal, appointmentInfos, handleUpdate, clientList, addGlobal = true, handleCancel }) {

    const {t} = useTranslation();
    const {user} = useAuthContext()
    const [errors, setErrors] = useState({});
    const [treatmentList, setTreatmentList] = useState([])
    const [appointment, setAppointment] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [treatmentSelect, setTreatmentSelect] = useState('');
    const [date, setDate] = useState( moment());
    const [appointmentLength, setAppointmentLength] = useState(60);
    const [client, setClient] = useState('');
    const [note, setNote] = useState('');

    useEffect(() => {
        setErrors({});
        setAppointment({});
        setTreatmentSelect('');
        setNote('');
        setDate(moment());
        setIsEditMode(false)
        const {id} = appointmentInfos
        setTreatmentList(user.centerTreatment.treatmentList)
        if (id != null){
            setTreatmentSelect(appointmentInfos.treatmentId)
            setDate(moment(appointmentInfos.start))
            setAppointmentLength(Math.abs(moment(appointmentInfos.end).diff(appointmentInfos.start, 'minutes')))
            setNote(appointmentInfos.note)
            setIsEditMode(true)
        }else {
            setIsEditMode(false)
        }
    }, [appointmentInfos, clientList]);

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setErrors({});
            setAppointment({});
            setTreatmentSelect('');
            setDate(moment());
            handleCloseModal();
        }
    }

    const handleSubmit = () => {
        setErrors({});
        if (!isEditMode){
            if (client === ''){
                setErrors((e) => ({...e, client: true}))
            }
            if (treatmentSelect === ''){
                setErrors((e) => ({...e, treatment: true}))
            }
        }
        if (date == null){
            setErrors((e) => ({...e, date: true}))
        }
        if (appointmentLength < 1){
            setErrors((e) => ({...e, appointmentLength: true}))
        }


        if (Object.keys(errors).length === 0){
            const appointment = {
                treatmentId: treatmentSelect,
                start: date,
                appointmentLength: appointmentLength,
                centerId: user.centerTreatment.id,
                clientId: appointmentInfos?.clientId ? appointmentInfos.clientId : client,
                userId: user.id,
                appointmentId: appointmentInfos.id,
                note: note
            }
            if (isEditMode){
                apiClient.editClientAppointment(appointment).then(({data, error}) => {
                    if (error){
                        setErrors({...errors, msg: true})
                        return
                    }
                    handleUpdate()
                })
            }else{
                apiClient.addClientAppointment(appointment).then(({data, error}) => {
                    if (error){
                        setErrors({...errors, msg: true})
                        return
                    }
                    handleUpdate(data.appointment.isFirstTimeAppointment, data.appointment)
                })
            }

        }

    }

    const handleCancelAppointment = async () => {
        const {id} = appointmentInfos
        await apiClient.cancelClientAppointment(id).then(() => {
            handleCancel()
        }).catch((err) => {
            setErrors({...errors, msg: true})
        });
    }

    const handleViewClientPage = () => {
        const url = '/center/client/' + appointmentInfos.idClient
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-appointment"
                    aria-describedby="modal-modal-appointment-description"
                >
                    <Box sx={myStyles.smallBoxModal}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            {
                                isEditMode ? (
                                    <Typography
                                        variant="h4">{t('appointment.EditAppointmentModalTitle')}</Typography>
                                ) : (
                                    <Typography
                                        variant="h4">{t('appointment.AddAppointmentModalTitle')}</Typography>
                                )
                            }
                            <Box sx={{display:'flex'}}>
                                {isEditMode && (
                                    <Tooltip title={t('appointment.ViewClientPage')} sx={myStyles.icons} >
                                        <ContactPageIcon onClick={handleViewClientPage}/>
                                    </Tooltip>
                                )}
                                <HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
                            </Box>
                        </Stack>
                        <Grid container alignItems="center" spacing={1}>
                            {errors?.msg && (
                                <Grid item xs={12}>
                                    <Alert sx={{justifyContent:"left"}} severity="error">
                                        {t('app.Error')}
                                    </Alert>
                                </Grid>
                                )
                            }
                            { errors?.appointmentLength && (
                                    <Grid item xs={12}>
                                        <Alert sx={{justifyContent:"left"}} severity="error">
                                            {t('appointment.AppointmentLengthError')}
                                        </Alert>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} md={3} sx={{textAlign: 'right'}}>
                            </Grid>
                            { !isEditMode && addGlobal ? (
                                <Grid item xs={12} sx={{pt:'10px'}}>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        id="combo-box-client"
                                        options={clientList}
                                        sx={{m :0,  borderRadius: '4px'}}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {option.label}
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) =>
                                            <BaseTextField {...params} label={t('appointment.Client')} fullWidth error={errors?.client}
                                                           helperText={errors?.client && t('app.RequiredFields') } />}
                                        onChange={(event, newValue) => {
                                            if (newValue.id) {
                                                setClient(newValue.id)
                                            }
                                        }}
                                    />
                                </Grid>
                            ) : null }
                            <Grid item xs={12} >
                                <FormControl fullWidth autoFocus required>
                                    <InputLabel htmlFor="service-select">{t('appointment.ServiceSelect')}</InputLabel>
                                    <Select
                                        id="service-select"
                                        value={treatmentSelect ? treatmentSelect : ''}
                                        label={t('appointment.ServiceSelect')}
                                        error={errors?.treatment && true}
                                        onChange={(element) => {
                                            setTreatmentSelect(element.target.value)
                                        }}
                                    >
                                        {treatmentList.map( (item, keyIndex) =>
                                            <MenuItem  key={keyIndex} value={item}>{t(`treatment.${Object.keys(TREATMENTS).find(k=>TREATMENTS[k]===item)}`)}</MenuItem>
                                        )}
                                    </Select>
                                    {errors?.treatment && <FormHelperText className="errorMsg"> {t('app.RequiredFields')}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl fullWidth autoFocus required>
                                    <DateTimePicker
                                        label={t('appointment.Date')}
                                        value={date}
                                        error={errors?.date}
                                        onChange={(newValue) => {
                                            setDate(newValue.format())
                                        }}
                                        renderInput={(params) => <BaseTextField fullWidth required {...params}    />}
                                    />
                                    {errors?.date && <FormHelperText className="errorMsg"> {t('app.RequiredFields')}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <BaseTextField
                                    label={t('appointment.AppointmentLength')}
                                    fullWidth
                                    onChange={(event) => setAppointmentLength(event.target.value)}
                                    name="appointmentLength"
                                    id="appointmentLength-input"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                    variant="outlined"
                                    value={appointmentLength}
                                    error={errors?.appointmentLength}
                                    helperText={errors?.appointmentLength && t('app.RequiredFields') }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BaseTextField
                                    label={t('appointment.Note')}
                                    fullWidth
                                    sx={{mt:0}}
                                    onChange={(event) => setNote(event.target.value)}
                                    name="appointmentNote"
                                    id="appointment-note-input"
                                    multiline={true}
                                    minRows="2"
                                    variant="outlined"
                                    value={note}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleClose}>
                                    {t('app.Close')}
                                </Button>
                            </Grid>
                            {
                                isEditMode ? (
                                    <>
                                        <Grid item xs={12} md={1.5}/>
                                            <Grid item xs={12} md={3}>
                                                <Button fullWidth variant="contained" color="primary" size="large" type="submit" onClick={handleCancelAppointment}>
                                                    {t('appointment.CancelAppointment')}
                                                </Button>
                                            </Grid>
                                        <Grid item xs={12} md={1.5}/>
                                    </>
                                ) : (
                                    <Grid item xs={12} md={6} />
                                )
                            }
                            <Grid item xs={12} md={3}>
                                <Button fullWidth variant="contained" color="primary" size="large" type="submit" onClick={handleSubmit}>
                                    {t('app.Save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </LocalizationProvider>
        </>
    );
}

export default ModalAddAppointment;