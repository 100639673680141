import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FormContext} from "../../../contexts/FormContext";
import {FormContextNoAuth} from '../../../contexts/FormContextNoAuth'

import {BaseTextField} from "../../textfield/StyledTextField";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from "@mui/x-date-pickers";
import {Grid} from "@mui/material";
import {FormExamenPeauContext} from "../../../contexts/FormExamenPeauContext";


const FormDatePicker = ({field_id, field_label, field_value, field_attributes, viewMode, isNoAuth, isExamenPeau}) => {
    const { handleChange } = useContext(isNoAuth ? FormContextNoAuth : !isExamenPeau ? FormContext : FormExamenPeauContext);
    const {t} = useTranslation()
    const [value, setValue] = useState(field_value ? field_value : null);
    const [isDisabled, setIsDisabled] = useState(viewMode);

    const xs = field_attributes?.find(element => element.xs)?.xs ? field_attributes?.find(element => element.xs).xs : 12
    const md = field_attributes?.find(element => element.md)?.md ? field_attributes?.find(element => element.md).md : 12
    const lg = field_attributes?.find(element => element.lg)?.lg ? field_attributes?.find(element => element.lg).lg : 12

    return (
        <Grid item xs={xs} md={md} lg={lg}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    views={['year', 'month']}
                    label={t(field_label)}
                    value={value}
                    disableMaskedInput
                    disabled={isDisabled}
                    onChange={(newValue) => {
                        setValue(newValue)
                        const e = {
                            target: {
                                    type: 'datepicker',
                                    value: newValue?.format('MM-YYYY'),
                                }
                            }
                        handleChange(field_id, e)
                    }}
                    renderInput={(params) => <BaseTextField fullWidth {...params} />}
                />
            </LocalizationProvider>
        </Grid>
    )
}


export default FormDatePicker;