import React from 'react';
import {useTranslation} from 'react-i18next';
import {
	Avatar,
	Badge,
	Divider,
	Drawer,
	IconButton, List,
	ListItem, ListItemButton,
	ListItemIcon, ListItemText, SvgIcon,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import styled from '@emotion/styled';
import {useTheme} from '@emotion/react';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {useAuthContext} from '../../contexts/auth';
import {useNavigate} from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpIcon from '@mui/icons-material/Help';
import ClientIcon from '../../asset/images/clients.png';
import AppointmentIcon from '../../asset/images/appointment.png';
import theme from "../../theme";

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(9)} + 1px)`,
	},
	[theme.breakpoints.down('sm')]: {
		width: 0,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const SideDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

function avatarOpen(open) {
	return !open ? { width: 50, height: 50, margin:'auto', mb: 3 } : { width: 150, height: 150, margin:'auto', mb: 3 }
}

const styles = {
	menuIcon:{
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '9px'
		},
	}
}

function SideMenu(props) {
	const {t} = useTranslation();
	const navigate = useNavigate()
	const { user, setUser, handleLogout, centerStatus } = useAuthContext()
	const theme = useTheme();

	return (
		<>
			<SideDrawer variant="permanent" open={props.open}>
				<DrawerHeader>
					<IconButton onClick={props.handleDrawer}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					<Avatar
						alt={user.centerName}
						src={user?.logo || "http://carismartes.com.br/assets/global/images/avatars/avatar1_big@2x.png"}
						sx={avatarOpen(props.open)}
					/>
					<ListItemButton component="a" href="/portail" sx={styles.menuIcon}>
						<ListItemIcon>
							<DashboardIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
						</ListItemIcon>
						<ListItemText primary={t('sideMenu.Dashboard')}/>
					</ListItemButton>
						{ !user.isEmployee &&
							<>
								<ListItemButton component="a" href="/center" sx={styles.menuIcon}>
									<ListItemIcon>
										<BusinessCenterIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
									</ListItemIcon>
									<ListItemText primary={t('sideMenu.Business')}/>
								</ListItemButton>
							</>
						}
						<ListItemButton component="a" href="/center/clientlist" sx={styles.menuIcon}>
							<ListItemIcon>
								<img src={ClientIcon} alt="clients" className='sideMenuIcon'/>
							</ListItemIcon>
							<ListItemText primary={t('sideMenu.Client')}/>
						</ListItemButton>
						<ListItemButton component="a" href="/center/appointment" sx={styles.menuIcon}>
							<ListItemIcon>
								<img src={AppointmentIcon} alt="Appointment" className='sideMenuIcon'/>
							</ListItemIcon>
							<ListItemText primary={t('sideMenu.Appointment')}/>
						</ListItemButton>
						{/*<ListItemButton component="a" href="https://youtube.com" target="_blank" sx={styles.menuIcon}>*/}
						{/*	<ListItemIcon>*/}
						{/*		<HelpIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>*/}
						{/*	</ListItemIcon>*/}
						{/*	<ListItemText primary={t('sideMenu.Help')}/>*/}
						{/*</ListItemButton>*/}
				</List>
				<Divider />
				<List>
					<ListItemButton onClick={handleLogout} sx={styles.menuIcon}>
						<ListItemIcon>
							<ExitToAppIcon sx={{fontSize:'40px', color:'#1d1d1b'}} />
						</ListItemIcon>
						<ListItemText primary={t('sideMenu.Logout')}/>
					</ListItemButton>
				</List>
			</SideDrawer>
		</>
	);
}

export default SideMenu;