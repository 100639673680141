import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
	DataGrid,
	enUS,
	frFR,
	GridColDef
} from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Tooltip} from "@mui/material";
import {myStyles} from "../index";


function ListEmployee({list, updateList, deleteEmployee}) {
	const {t} = useTranslation();
	const [employeeList, setEmployeeList] = useState(list)

	const columns: GridColDef[] = [
		{ field: 'id', hide:true },
		{ field: 'isEmployee', hide:true },
		{
			field: "firstName",
			headerName: t("employee.FirstNane"),
			type: 'text',
			flex: 4,
			editable: true,
		},
		{
			field: "lastName",
			headerName: t("employee.LastName"),
			type: 'text',
			flex: 4,
			editable: true,
		},
		{
			field: "email",
			headerName: t("employee.Email"),
			type: 'text',
			flex: 4,
			editable: true,
		},
		{ field: 'action', headerName: t('employee.Delete'), flex: 0.5,align: 'center', editable: true,
			renderCell: (employee) => {
				if (employee.row.isEmployee) {
					return (
						<Tooltip title={t('employee.Delete')} sx={myStyles.smallIcons} >
							<DeleteForeverIcon
								onClick={() => {
									deleteEmployee(employee.row.id)
								}}
							/>
						</Tooltip>

					)
				}
			}
		},

	];
	return (
		<DataGrid
			getRowId={(row) => row.email}
			autoHeight
			rows={employeeList}
			localeText={localStorage.getItem("i18nextLng") === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
			disableColumnMenu={true}
			disableColumnFilter={true}
			hideFooterSelectedRowCount={true}
			disableDensitySelector={true}
			onEditRowsModelChange={updateList}
			columns={columns}
		/>
	);
}

export default ListEmployee;