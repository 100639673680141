import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AvatarEditor from 'react-avatar-editor'
import {Button, CircularProgress, Grid, Slider, Tooltip, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../theme";
import apiClient from "../../services/apiClient";
import {useAuthContext} from "../../contexts/auth";

const myStyles = {
    icons:{
        fontSize:'60px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        }
    }
};

function EditCenterLogo(props) {
    const {t} = useTranslation();
    const { user, setUser} = useAuthContext()
    const [errors, setErrors] = useState({});
    const [logo, setLogo] = useState(user?.logo || "http://carismartes.com.br/assets/global/images/avatars/avatar1_big@2x.png");
    const [isEdited, setIsEdited] = useState(false);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [isProcessing, setIsProcessing] = useState(false);
    const [editorRef, setEditorRef] = useState(null);

    const handleScale = (e) => {
        const scale = parseFloat(e.target.value)
        setScale(scale)
    }

    const handleNewImage = (e) => {
        setIsEdited(true);
        setLogo( e.target.files[0])
    }

    const handlePositionChange = (positionXY) => {
        setPosition({ positionXY })
    }

    const handleEdit = (e) => {
        setIsProcessing(true);
        apiClient.updateCenterLogo(editorRef.getImage().toDataURL(), user.centerId).then(res => {
            setIsProcessing(false);
            setIsEdited(false);
            setUser({...user, logo: editorRef.getImage().toDataURL()})
        }).catch(err => {
            setIsProcessing(false);
            setErrors(err.response.data.errors);
        })
    }

    const handleEditorRef = (editor) => {
        if (editor) setEditorRef(editor)
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4} sx={{textAlign:"center"}}>
                    <AvatarEditor
                        ref={handleEditorRef}
                        image={logo}
                        width={250}
                        height={250}
                        border={50}
                        color={[255, 255, 255, 1]}
                        rotate={0}
                        borderRadius={200}
                        scale={parseFloat(scale)}
                        className="editor-logo"
                        onPositionChange={handlePositionChange}
                    />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Button variant="contained" component="label" color="primary">
                                {" "}
                                <AddIcon /> {t('centerPage.UploadFile')}
                                <input type="file" hidden onChange={handleNewImage}/>
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={8} sx={{textAlign: 'right'}}>
                            {isProcessing &&
                                <CircularProgress color="secondary" size={40}/>}
                            <Tooltip title={t('centerPage.Save')} sx={myStyles.icons} >
                                <SaveIcon onClick={handleEdit}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary" sx={{fontSize:'1em', pt:3}}>
                                {t('centerPage.LogoScale')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Slider
                                value={typeof scale === 'number' ? scale : 0}
                                defaultValue={1}
                                min={0.1}
                                max={2}
                                step={0.01}
                                valueLabelDisplay="on"
                                onChange={handleScale}
                                aria-labelledby="input-slider"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary" sx={{fontSize:'1em', pt:3}}>
                                {t('centerPage.Instruction1')}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{fontSize:'1em', pt:3}}>
                                {t('centerPage.Instruction2')}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{fontSize:'1em', pt:3}}>
                                {t('centerPage.Instruction3')}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{fontSize:'1em', pt:3}}>
                                {t('centerPage.Instruction4')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EditCenterLogo;