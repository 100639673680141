import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import {
	Button,
	CircularProgress,
	Grid,
	InputAdornment,
	Paper,
	Typography,
	Modal,
	Box, Stack
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {BaseTextField} from "../textfield/StyledTextField";
import apiClient from "../../services/apiClient";
import Swal from "sweetalert2";
import {myStyles, PasswordRule, Title} from 'components';
import PasswordValidator from 'password-validator';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {useAuthContext} from '../../contexts/auth';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const initialState = {firstName:'', lastName:'', email:'', password:'', confirmPassword:''};

function ModalEmployee({open, handleClose, handleCrudCompleted}) {
    const {t} = useTranslation()
	const [isProcessing, setIsProcessing] = useState(false);
	const { user, initialized} = useAuthContext()
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [form, setForm] = useState(initialState);
	const [pwdError, setPwdError] = useState(['min', 'uppercase', 'lowercase', 'digits', 'symbols'])

	const handleShowPassword = () => setShowPassword(!showPassword);

	const handleChange = (element) => {
		if ( errors.hasOwnProperty(element.target.name) ) {
			setErrors((e) => ({ ...e, [element.target.name]: null }))
		}

		if (element.target.name === "email") {
			if (element.target.value.indexOf("@") === -1) {
				setErrors((e) => ({ ...e, email: "app.ValidEmail" }))
			} else {
				setErrors((e) => ({ ...e, email: null }))
			}
		}

		if (element.target.name === 'password') {
			validatePasswordSecurity(element.target.value);
		}

		if (element.target.name === "confirmPassword") {
			if (element.target.value !== form.password) {
				setErrors((e) => ({ ...e, confirmPassword: "passwordRule.PwMatchingPassword" }))
			} else {
				setErrors((e) => ({ ...e, confirmPassword: null }))
			}
		}

		setForm({ ...form, [element.target.name]: element.target.value, centerId: user.centerId});
	}

	const validatePasswordSecurity = (password) => {
		const pwdRule = new PasswordValidator();
		pwdRule
		.is()
		.min(8) // Minimum length 8
			.has()
			.uppercase() // Must have uppercase letters
			.has()
			.lowercase() // Must have lowercase letters
			.has()
			.digits(1) // Must have at least 1 digits
			.has()
			.symbols(1) // Must have at least 1 special character
			.has()
			.not()
		.spaces(); // Should not have spaces

		setPwdError(pwdRule.validate(password, {list : true}))
	}

	async function submitHandler() {
		const requiredFields = [
			'email',
			'password',
			'confirmPassword', 'firstName','lastName']
		let hasError = false;
		setIsProcessing(true)
		setErrors((e) => ({...e, form: null}))

		requiredFields.forEach(field => {
			if (!form.hasOwnProperty(field)) {
				setErrors((e) => ({...e, [field]: "app.RequiredFields"}))
				hasError = true
			}
		})

		Object.entries(form).map(field => {
			if (field[1] === '') {
				setErrors(
					(e) => ({...e, [field[0]]: "app.RequiredFields"}))
				hasError = true
			}
		})

		if (form.confirmPassword !== form.password) {
			setErrors((e) => ({
				...e,
				confirmPassword: "passwordRule.PwMatchingPassword"
			}))
			hasError = true
		} else {
			setErrors((e) => ({...e, confirmPassword: null}))
		}

		if (!hasError) {
			setIsFetching(true)
			const {data, error} = await apiClient.addEmployee(form)
			if (data) {
				handleClose();
				handleCrudCompleted();
				Swal.fire({
					title: t('employee.EmployeeCompletedTitle'),
					html:t('employee.EmployeeCompleted'),
					icon: 'success',
					timer: 3000,
					timerProgressBar: true,
				}).then((result) => {
				})
			}
			if (error) {
				setErrors({form: error})
			}
			setIsFetching(false)
		}
	}

	const closeModal = () => {
		setErrors({})
		setForm(initialState)
		handleClose();
	}

	return (
		<>
			<Modal
				open={open}
				onClose={closeModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={myStyles.bigBoxModal}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Typography variant="h4">{t('employee.CreateEmployee')}</Typography>
						<HighlightOffIcon sx={myStyles.icons} onClick={closeModal}/>
					</Stack>
					<Grid container alignItems="center">
						<Grid item xs={12} md={12} sx={{pb: 3}}>
								{errors?.form && <span className="errorMsg">{t(errors.form)}</span>}
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<BaseTextField
											label={t('employee.FirstNane')}
											required
											fullWidth
											variant="outlined"
											name="firstName"
											onChange={handleChange}
											value={form.firstName}
											error={errors.firstName && true}
											helperText={errors.firstName && t(errors.firstName) }
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<BaseTextField
											label={t('employee.LastName')}
											required
											fullWidth
											variant="outlined"
											name="lastName"
											onChange={handleChange}
											value={form.lastName}
											error={errors.lastName && true}
											helperText={errors.lastName && t(errors.lastName) }
										/>
									</Grid>
									<Grid item xs={12}>
										<BaseTextField
											label={t('employee.Email')}
											required
											fullWidth
											variant="outlined"
											name="email"
											onChange={handleChange}
											value={form.email}
											error={errors.email && true}
											helperText={errors.email && t(errors.email) }
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<BaseTextField
											label={t('employee.Password')}
											fullWidth
											required
											type={showPassword? 'text' : 'password'}
											variant="outlined"
											name="password"
											onChange={handleChange}
											value={form.password}
											error={errors.password && true}
											helperText={errors.password && t(errors.password)}
											InputProps={{
												endAdornment: (
													<InputAdornment
														onClick={handleShowPassword}
														className="showPassword"
														position="end">
														{showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} /> }
													</InputAdornment>
												),
											}}
										/>

									</Grid>
									<Grid item xs={12} md={6}>
										<BaseTextField
											label={t('employee.ConfirmPassword')}
											fullWidth
											required
											type={showPassword? 'text' : 'password'}
											variant="outlined"
											name="confirmPassword"
											onChange={handleChange}
											value={form.confirmPassword}
											error={errors.confirmPassword && true}
											helperText={errors.confirmPassword && t(errors.confirmPassword)}
											InputProps={{
												endAdornment: (
													<InputAdornment
														onClick={handleShowPassword}
														className="showPassword"
														position="end">
														{showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} /> }
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<PasswordRule pwdRuleError={pwdError}/>
									</Grid>
								</Grid>
							<Grid item xs={12}>
									{errors?.agreement && <span className="errorMsg">{t(errors.agreement)}</span>}
							</Grid>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isFetching} onClick={closeModal}>
								{t('app.Cancel')}
							</Button>
						</Grid>
						<Grid item xs={12} lg={6}/>
						<Grid item xs={12} lg={3}>
							<Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isFetching} onClick={submitHandler}>
								{isFetching && <CircularProgress color="secondary"  size={20} />}
								{!isFetching && t('employee.Submit')}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>

		</>
	);
 }

export default ModalEmployee;