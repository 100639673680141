import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import {
	Box,
	Button,
	CircularProgress,
	FormControlLabel,
	FormLabel,
	Grid,
	Modal,
	Paper, Radio,
	RadioGroup, Stack, Typography
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {myStyles, Title} from "components";
import apiClient from "../../services/apiClient";
import SignaturePad from "react-signature-canvas";
import {useAuthContext} from "../../contexts/auth";
import moment from "moment";

function PhotoConsentFormModal({open, handleCloseModal, client}) {
    const {t} = useTranslation()
	const {user} = useAuthContext()
	const [isProcessing, setIsProcessing] = useState(false);
	const [errors, setErrors] = useState(false);
	const [signature, setSignature] = useState(null);
	const [authorisation, setAuthorisation] = useState('0')
	const [isSigned, setIsSigned] = useState(false)
	const [signatureDate, setSignatureDate] = useState(null)

	useEffect(() => {
		setErrors(null)
		setIsProcessing(false)
		setAuthorisation(client?.authorisation?.consent ? '1' : '0')
		setIsSigned(!!client?.authorisation?.signature)
		setSignature(client?.authorisation?.signature)
		setSignatureDate(client?.authorisation?.signatureDate)
	}, [])

	const submitHandler = () => {
		setIsProcessing(true);
		if (!signature.isEmpty()) {
			apiClient.signConsentForm(client.id, signature.getTrimmedCanvas().toDataURL(), authorisation === '1').then(({data, error}) => {
				if (error) {
					setErrors((e) => ({...e, form: true}))
				} else {
					setErrors(null)
					handleCloseModal(true)
				}
				setIsProcessing(false);
			})
		}else {
			setErrors((e) => ({...e, signature: true}))
			setIsProcessing(false);
		}
	}

	function handleAuthorisationChange(element) {
		setErrors((e) => ({...e, authorisation: null}))
		setAuthorisation(element.target.value)
	}

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				aria-labelledby="modal-modal-consent-form"
				aria-describedby="modal-modal-consent-description"
			>
				<Box sx={myStyles.smallBoxModal}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Title balise={'h4'} title={t('photoConsentForm.ModalTitle')} subtitle='' forgetLabel={false}/>
						<HighlightOffIcon sx={myStyles.icons} onClick={() => handleCloseModal(false)}/>
					</Stack>
					<Grid container alignItems="center" spacing={2}>
						{ errors?.form ? (
							<Grid item xs={12}>
								<span className="errorMsg">{t('photoConsentForm.Error')}</span>
							</Grid>
						) : null }
						{ errors?.signature ? (
							<Grid item xs={12}>
								<span className="errorMsg">{t('photoConsentForm.MissingSignature')}</span>
							</Grid>
						) : null }
						<Grid item xs={12}>
							<FormLabel component="legend">{t('photoConsentForm.PictureAuthorisation', {centerName : user.centerName})}</FormLabel><br/>
							<RadioGroup row aria-label="gender" name="row-radio-buttons-group" onChange={handleAuthorisationChange} value={authorisation}>
								<FormControlLabel value="1" control={<Radio disabled={isSigned} />} label={t('photoConsentForm.YesPicture')} /><br/>
								<FormControlLabel value="0" control={<Radio disabled={isSigned}/>} label={t('photoConsentForm.NoPicture')} />
							</RadioGroup>
						</Grid>
						<Grid item xs={12} >
							<Typography variant={'h6'}>{t('photoConsentForm.Signature')}</Typography>
							{isSigned ? (
								<Paper  sx={myStyles.paper} >
									<img className="" src={signature}  alt="signature"/>
								</Paper>
							):(
								<Paper  sx={myStyles.paper} >
									<SignaturePad canvasProps={{className: 'signatureFormulaire'}}  ref={(ref) => { setSignature(ref) }} />
								</Paper>
							)}
						</Grid>
						{
							isSigned && (
								<Grid item xs={12} sx={{textAlign: 'right', pt:1}}>
									{moment(signatureDate).format('ll')}
								</Grid>
							)
						}
						<Grid item xs={12} lg={4}>
							<Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isProcessing} onClick={() => handleCloseModal(false)}>
								{t('app.Close')}
							</Button>
						</Grid>
						{
							isSigned ? (
								<Grid item xs={12} lg={4}/>
							) : (
								<>
									<Grid item xs={12} lg={4}>
										<Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isProcessing}  onClick={() => {
											signature.clear();
										}}>{t('form.Clear')}</Button>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isSigned} onClick={submitHandler}>
											{isProcessing && <CircularProgress color="secondary"  size={20} />}
											{!isProcessing && t('app.Save')}
										</Button>
									</Grid>
								</>
							)
						}
					</Grid>
				</Box>
			</Modal>
		</>
	);
 }

export default PhotoConsentFormModal;