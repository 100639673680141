import React from 'react';
import {
	Grid, Tooltip
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useTranslation} from 'react-i18next';
import {DashboardAppointmentList, Title} from 'components';
import {useFollowUpContext} from "../../../contexts/FollowUpContext";
import theme from "../../../theme";

const myStyles = {
	icons:{
		fontSize:'60px',
		color: theme.palette.primary.main,
		cursor: 'pointer',
		'&:hover':{
			color:theme.palette.primary.dark
		}
	}
};

function Portail(props) {
	const {t} = useTranslation();
	const { handleAddFollowUpClick, showFollowUpList } = useFollowUpContext();

	return (
		<>
			<Grid container  spacing={2}>
				<Grid item xs={12} lg={8}>
					<Title title='appointment.Title' subtitle='appointment.Subtitle' forgetLabel={false}/>
					<DashboardAppointmentList />
				</Grid>
				<Grid item xs={12} lg={4}>
					<Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={8} sx={{alignItems: 'flex-start'}}>
							<Title title='followUp.ClientFollowUpTitle' subtitle='appointment.SuiviNote' forgetLabel={false}/>
						</Grid>
						<Grid item xs={4} sx={{textAlign:'right'}}>
							<Tooltip title={t('followUp.AjouterClientFollowUpTitle')} sx={myStyles.icons} >
								<AddCircleOutlineIcon onClick={() => handleAddFollowUpClick()}/>
							</Tooltip>
						</Grid>
						<Grid item xs={12}>
							{
								showFollowUpList()
							}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default Portail;