import React, {useEffect, useState} from 'react';
import {ElectroNoteForm, ListNoteElectro, ModalNoteGeneral,myStyles, Title, ToastMsg} from 'components';
import {Backdrop, Box, CircularProgress, Grid, Stack, Tooltip} from '@mui/material';
import AddBusinessRounded from "@mui/icons-material/AddComment";
import {useAuthContext} from '../../../contexts/auth';
import {useTranslation} from 'react-i18next';
import { useNavigate, useParams} from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import apiClient from "../../../services/apiClient";
import {TREATMENTS} from "../../../constants/EnumTreatment";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import {useFollowUpContext} from "../../../contexts/FollowUpContext";

function ElectrolyseNotePage({viewOnly}) {
	const {t} = useTranslation();
	const {id} = useParams()
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)
	const { user } = useAuthContext()
    const [open, setOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false)
	const [isUpdated, setIsUpdated] = useState(false)
	const [generalNote, setGeneralNote] = useState(false)
	const [notes, setNotes] = useState([])
	const [client, setClient] = useState({})
	const { handleAddFollowUpClick } = useFollowUpContext();
	const navigate = useNavigate();

	useEffect(() => {
		setIsUpdated(false)
		setGeneralNote(false)
		apiClient.fetchElectroAppointmentNote(id, TREATMENTS.Electrolyse).then(({data}) => {
			setNotes(data)
			setIsFetching(false)
			return apiClient.fetchClientName(id)
		}).then(({data}) => {
			setClient({clientId: id, firstName: data.firstName, lastName: data.lastName})
		}).catch(error => {
			setIsFetching(false)
			setErrors(error.response.data.errors)
		})
	}, [isUpdated])

	const handleCancel = () => {
		navigate('/center/client/' + id)
	}

	const handleOpen = () => {
		setOpen(true);
	}

	const handleUpdate = () =>{
		setIsUpdated(true)
		setGeneralNote(false)
	}

	const handleClose= (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpen(false);
			setIsProcessing(false)
			setGeneralNote(false)
		}
	}

	const handleCrudCompleted = () => {
		setIsProcessing(false)
		setOpen(false)
		setGeneralNote(true)
	}

	const handleOpenClientFollowUp = () => {
		handleAddFollowUpClick(client)
	}

	return (
		<>
			{generalNote &&
				<ToastMsg open={true} type="success" message={t('app.SaveSuccess')}/>
			}
			{errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
			<ModalNoteGeneral open={open} handleClose={handleClose} handleCrudCompleted={handleCrudCompleted} editNote={false}/>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isFetching}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
			<Grid container>
				<Grid item xs={12} >
					<Tooltip title={t('app.BackToClientPage')} sx={myStyles.icons} >
						<ArrowBackIosIcon onClick={() => handleCancel()}/>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<div>
							<Title title='electrolyseNotes.Title' subtitle='electrolyseNotes.Subtitle' forgetLabel={true}/>
						</div>
						{!viewOnly &&
							<Box sx={{display:'inline-flex'}}>
								<Tooltip title={t('electrolyseNotes.AddFollowUp')} sx={myStyles.iconsWithRightMargin} >
									<NotificationAddIcon onClick={() => handleOpenClientFollowUp()} />
								</Tooltip>
								<Tooltip title={t('electrolyseNotes.AddGeneralNote')} sx={myStyles.icons} >
									<AddBusinessRounded onClick={e => handleOpen()} />
								</Tooltip>
							</Box>
						}
					</Stack>
				</Grid>

			</Grid>
			<Grid container sx={{pt:3}}>
				{!viewOnly && <Grid item xs={12}>
					<ElectroNoteForm handleUpdate={handleUpdate} idTreatment={TREATMENTS.Electrolyse}/>
				</Grid>}
				<Grid item xs={12}>
					<ListNoteElectro notes={notes}/>
				</Grid>
			</Grid>
		</>
	);
}

export default ElectrolyseNotePage;