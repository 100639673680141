import theme from "../theme";

const myStyles = {
    anchorLink:{
        fontSize: '1.2rem',
        fontWeight: 'bold',
        margin: '0.5rem 1rem',
        color: theme.palette.primary.main,
        '&:hover':{
            color:theme.palette.primary.dark
        }
    },
    backToTop:{
        background: theme.palette.primary.dark,
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    boxModal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        overflowY:'auto',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            height:'100%'
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    },
    bigBoxModal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        overflowY:'auto',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            height:'100%'
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    },
    smallBoxModal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        overflowY:'auto',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            height:'100%'
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    },
    iconsDisable:{
        fontSize:'45px',
        color: '#ccc',
        cursor: 'not-allowed',
        [theme.breakpoints.down('lg')]: {
            fontSize:'35px',
        },
    },
    iconsCenter:{
        fontSize:'35px',
        margin: '10px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        },
    },
    icons:{
        fontSize:'65px',
        margin: '10px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        },
        [theme.breakpoints.down('lg')]: {
            fontSize:'45px',
        },
    },
    iconsWithLeftMargin:{
        fontSize:'65px',
        marginLeft:'30px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        },
        [theme.breakpoints.down('lg')]: {
            fontSize:'45px',
        },
    },
    iconsWithRightMargin:{
        fontSize:'65px',
        marginRight: '20px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        },
        [theme.breakpoints.down('lg')]: {
            fontSize:'45px',
        },
    },
    paper: {
        padding: theme.spacing(2),
    },
    smallIcons:{
        fontSize:'45px',
        margin:'0 5px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        },
        [theme.breakpoints.down('lg')]: {
            fontSize:'35px',
        },
    },
    label:{
        fontSize:'1.2rem',
        fontWeight:'bold',
    }
};

export default myStyles;