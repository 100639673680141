import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardActionArea, CardContent, Typography} from "@mui/material";
import theme from "../../theme";

const myStyles = {
    card:{
        textAlign: 'center',
        marginTop:'5px'
    },
    cardSelected:{
        textAlign: 'center',
        border:'3px solid',
        borderColor: theme.palette.primary.main
    }
};

function FollowUpCard({item, handleOpen}) {
    const {t} = useTranslation();

    const onClickCard = () => {
        handleOpen(item);
    }

    return (
        <>
            <Card onClick={() => onClickCard()} sx={myStyles.card} >
                <CardActionArea sx={{p: 1}}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {item.clientName}
                        </Typography>
                        <Typography variant="body2">
                            {
                                item.note.substring(0,75)+"..."
                            }
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    );
}

export default FollowUpCard;