import React, {useEffect, useState} from 'react';
import theme from "../../theme";
import {useTranslation} from "react-i18next";
import {Alert, Box, Button, CircularProgress, Grid, Modal, Paper, Stack, Tooltip, Typography} from "@mui/material";
import apiClient from "../../services/apiClient";
import {useAuthContext} from "../../contexts/auth";
import DeleteIcon from '@mui/icons-material/Delete';
import {ModalAddSubscriptionClientAddon, myStyles, Title, ToastMsg} from "../index";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

function ModalSubscriptionAddonClient({open, setOpen, clientCardUsing, clientCardAvailable, handleCrudClientAddon}) {
    const {t} = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState({});
    const [addons, setAddons] = useState([]);
    const [editedAddon, setEditedAddon] = useState(false);
    const [openExtra, setOpenExtra] = useState(false);
    const {user} = useAuthContext();

    useEffect(() => {
        setIsProcessing(false);
        setErrors({});
        setEditedAddon(false);
        apiClient.fetchCenterAddons(user.centerId).then(({data}) => {
            setAddons(data);
        }).catch(error => {
            setIsProcessing(false)
            setErrors({...errors, server: true})
        })
    }, [open, editedAddon]);

    const handleClose = () => {
        setErrors({});
        setOpen(false);
    };

    const handleDeactivateAddon = (i) => {
        if (clientCardUsing > (clientCardAvailable - addons[i].addExtraClientCard)) {
            setErrors({...errors, quota: true})
        } else {
            setIsProcessing(true)
            apiClient.setAddonDeactivate(addons[i].id).then(({data}) => {
                setEditedAddon(true);
                handleCrudClientAddon();
            }).catch(error => {
                setIsProcessing(false)
                setErrors({...errors, server: true})
            })
        }
    }

    const handleReactivateAddon = (i) => {
        if (moment(addons[i].addonEndDate) >= moment())
        {
            apiClient.setAddonReactivated(addons[i].id).then(({data}) => {
                setEditedAddon(true);
                handleCrudClientAddon();
            }).catch(error => {
                setIsProcessing(false)
                setErrors({...errors, server: true})
            })
        }
    }

    const submitAddCustomerAddon = () => {
        setOpenExtra(true);
    }

    const handleAddedClientRecord = () => {
        setOpenExtra(false);
        handleCrudClientAddon();
        setOpen(false);
    }

    return (
        <>
            <ModalAddSubscriptionClientAddon open={openExtra} setOpen={(o) => setOpenExtra(o)} addedClientRecord={handleAddedClientRecord}/>
            {editedAddon && <ToastMsg open={true} type='success' message={t('centerPage.AutoRenewSuccess')}/>}
            {errors?.server && <ToastMsg open={true} type='error' message={t('centerPage.ErrorHappenRetry')}/>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={myStyles.smallBoxModal} key={addons.id}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Title balise="h4" title='centerAddon.CrudClientCard' subtitle='' forgetLabel={false}/>
                        <HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
                    </Stack>
                    <Grid container alignItems="center">
                        <Grid container spacing={2} sx={{pb:3}}>
                            {
                                errors?.quota && (
                                    <Grid item xs={12}>
                                        <Alert sx={{justifyContent:"left"}} severity="error">
                                            {t('centerAddon.CantDeleteCardAddon')}
                                        </Alert>
                                    </Grid>
                                )
                            }
                            {
                                addons.length === 0 && (
                                    <Grid item xs={12}>
                                        <Alert sx={{justifyContent:"left"}} severity="info">
                                            {t('centerAddon.NoAddon')}
                                        </Alert>
                                    </Grid>
                                )
                            }
                            { addons.map((addon, index) => (
                                <Grid key={index} item xs={12} lg={6}>
                                    <Paper sx={{p: 1}} >
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography variant="h6" align="left" >
                                                {t('centerAddon.'+ addon.tierAddonName)}
                                            </Typography>
                                            {
                                                (moment(addon.addonEndDate) >= moment() && addon.addonAutoRenew) ? (
                                                    <Tooltip title={t('centerAddon.DeactivateAddon')} sx={myStyles.iconsCenter} >
                                                        <DeleteIcon onClick={() => handleDeactivateAddon(index)}/>
                                                    </Tooltip>
                                                ) : (
                                                    moment(addon.addonEndDate) >= moment() && !addon.addonAutoRenew ? (
                                                        <Tooltip title={t('centerAddon.DeactivateAddon')} sx={myStyles.iconsCenter} >
                                                            <PublishedWithChangesIcon onClick={() => handleReactivateAddon(index)}/>
                                                        </Tooltip>
                                                    ) : null
                                                )
                                            }

                                        </Stack>
                                        {
                                            moment(addon.addonEndDate) >= moment() && !addon.addonAutoRenew ? (
                                                <>
                                                    <Typography variant="body1" align="left" sx={{fontWeight:'bold'}} >
                                                        {t('centerAddon.DateExpiration')}
                                                    </Typography>
                                                    <Typography variant="body1" align="left" >
                                                        {moment(addon.addonEndDate).format('LL')}
                                                    </Typography>
                                                </>
                                            ) : null
                                        }
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isProcessing} onClick={handleClose}>
                                {t('app.Close')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={4}/>
                        <Grid item xs={12} lg={4}>
                            <Button fullWidth variant="contained" color="primary" size="large" type="submit" onClick={submitAddCustomerAddon}>
                                {isProcessing && <CircularProgress color="secondary"  size={20} />}
                                {!isProcessing && t('centerAddon.Add')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}

export default ModalSubscriptionAddonClient;