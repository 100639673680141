import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {myStyles, ToastMsg} from 'components';
import {DataGrid, enUS, frFR, GridColDef} from "@mui/x-data-grid";
import moment from "moment/moment";
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useFollowUpContext} from "../../contexts/FollowUpContext";
import theme from "../../theme";
import Swal from "sweetalert2";
import {useMediaQuery} from "@mui/material";

function ListClientFollowUp({followUp}) {
    const {t} = useTranslation()
	const [followUpList, setFollowUpList] = useState(followUp)
	const [errors, setErrors] = useState({})
	const { handleComplete,handleCrudOpen,handleDeleteFollowUp, handleViewOpen } = useFollowUpContext();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect( () => {
	    setFollowUpList(followUp)
    }, [followUp])

	const columns: GridColDef[] = [
		{ field: 'id', hide:true },
		{
			field: "view",
			headerName:t("followUp.FollowUpViewHeader"),
			width: 100,
			align: 'center',
			sortable: false,
			renderCell: (client) => (
				<>
					{
						<VisibilityIcon sx={myStyles.smallIcons} onClick={() => handleViewOpen(client.row)}/>
					}
				</>
			)
		},
		{
			field: "note",
			headerName: t("followUp.FollowUpListHeader"),
			flex: 1,
			hide : isMobile,
		},
		{
			field: "dateToFollowUP",
			headerName: t("followUp.FollowUpDateListHeader"),
			flex: 0.5,
			hide : isMobile,
			renderCell: (client) => (
				<>
					<span>
						{moment(client.row.dateToFollowUP).format('dddd LL')}
					</span>
				</>
			)
		},
		{
			field: "complete",
			headerName:t("followUp.FollowUpCompleteHeader"),
			width: 100,
			align: 'center',
			sortable: false,
			renderCell: (client) => (
				<>
					{
						!client.row.isCompleted && <CheckIcon sx={myStyles.smallIcons} onClick={() => handleComplete(client.row.id)}/>
					}
				</>
			)
		},
		{
			field: "edit",
			headerName: t("followUp.FollowUpEditHeader"),
			width: 100,
			align: 'center',
			sortable: false,
			hide : isMobile,
			renderCell: (client) => (
				<>
					{
						!client.row.isCompleted && <EditNotificationsIcon sx={myStyles.smallIcons} onClick={() => handleCrudOpen(client.row)}/>
					}

				</>
			)
		},
		{
			field: "delete",
			headerName: t("followUp.FollowUpDeleteHeader"),
			width: 100,
			align: 'center',
			sortable: false,
			renderCell: (client) => (
				<>
					<DeleteIcon sx={myStyles.smallIcons} onClick={() => handleDelete(client.row.id)}/>
				</>
			)
		}
	]



	const handleDelete = (id) => {
		Swal.fire({
			title: t("followUp.FollowUpAlertTitleDelete"),
			text: t("followUp.FollowUpAlertTextWarningDelete"),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: theme.palette.primary.main,
			cancelButtonColor: theme.palette.secondary.light,
			confirmButtonText: t("followUp.FollowUpAlertBtnDelete")
		}).then((result) => {
			if (result.isConfirmed) {
				handleDeleteFollowUp(id)
			}
		})
    }

	return (
		<>
			{errors?.msg &&
				<ToastMsg open={true} type='error' message={t('app.Error')}/>}
			<DataGrid
				autoHeight
				rows={followUpList}
				localeText={localStorage.getItem("i18nextLng") === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
				disableColumnMenu={true}
				disableColumnFilter={true}
				hideFooterSelectedRowCount={true}
				disableDensitySelector={true}
				columns={columns}
			/>
		</>
	);
 }

export default ListClientFollowUp;