import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
	DataGrid,
	enUS,
	frFR,
	GridColDef, GridToolbarDensitySelector,
	GridToolbarFilterButton,
} from '@mui/x-data-grid';
import {Button, IconButton, Link, Tooltip, useMediaQuery} from '@mui/material';
import NumberFormat from 'react-number-format';
import {BaseTextField} from '../textfield/StyledTextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from "@mui/icons-material/Visibility";
import {myStyles} from "../index";
import theme from "../../theme";

function ListClients({list}) {
	const {t} = useTranslation();
	const [clientList, setClientList] = useState(list)
	const [searchText, setSearchText] = React.useState('');
	const isMobileTablette = useMediaQuery(theme.breakpoints.down("md"));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleEditClient = (client) => {
		const url = 'client/' + client.id
		window.open(url)
	};

	const columns: GridColDef[] = [
		{ field: 'id', hide:true },
		{ field: 'lastName', headerName: t('client.LastName'), flex: 1 },
		{ field: 'firstName', headerName: t('client.FirstName'), flex: 1},
		{ field: 'address', headerName:t('client.Address'), flex: 1.75, hide : isMobileTablette},
		{ field: 'local', headerName: t('client.Local'),  flex: 0.5, hide : isMobileTablette},
		{
			field: 'mainPhone',
			headerName: t('client.MainPhone'),
			flex: 0.75,
			hide : isMobile,
			renderCell: (client) => (
				<>
					<Link href={`tel:${client.row.mainPhone}`}>
						<NumberFormat
							value={client.row.mainPhone}
							format="(###) ###-####"
							displayType={'text'}
						/>
					</Link>
				</>
			)
		},
		{ field: 'altPhone', headerName: t('client.AltPhone'), flex: 0.75, hide : isMobileTablette},
		{
			field: 'email',
			headerName: t('client.Email'),
			flex: 1,
			hide : isMobileTablette,
			renderCell: (client) => (
				<>
					<Link href={`mailto:${client.row.email}`}>
						{client.row.email}
					</Link>
				</>
			)
		},
		{
			field: "view",
			headerName:t("client.ViewHeader"),
			width: 70,
			align: 'center',
			sortable: false,
			renderCell: (client) => (
				<>
					<Tooltip title={t('client.ViewHeader')} sx={myStyles.smallIcons} >
						<VisibilityIcon onClick={() => handleEditClient(client)}/>
					</Tooltip>

				</>
			)
		}
	];

	function escapeRegExp(value) {
		return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
	}

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);
		const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
		const filteredRows = list.filter((row) => {
			return Object.keys(row).some((field) => {
				return searchRegex.test(row[field]?.toString());
			});
		});
		setClientList(filteredRows);
	};

	function QuickSearchToolbar(props) {
		return (
			<div>
				<div>
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</div>
				<BaseTextField
					variant="standard"
					value={props.value}
					onChange={props.onChange}
					placeholder={t('app.Search')}
					autoFocus
					InputProps={{
						startAdornment: <SearchIcon fontSize="small" />,
						endAdornment: (
							<IconButton
								title="Clear"
								aria-label="Clear"
								size="small"
								style={{ visibility: props.value ? 'visible' : 'hidden' }}
								onClick={props.clearSearch}
							>
								<ClearIcon fontSize="small" />
							</IconButton>
						),
					}}
				/>
			</div>
		);
	}

	return (
		<div style={{ display: 'flex', height: '100%' }}>
			<div style={{ flexGrow: 1 }}>
				<DataGrid
					autoHeight
					rows={clientList}
					localeText={localStorage.getItem("i18nextLng") === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
					disableColumnMenu={true}
					disableColumnFilter={true}
					hideFooterSelectedRowCount={true}
					disableDensitySelector={true}
					columns={columns}
					components={{ Toolbar: QuickSearchToolbar }}
					componentsProps={{
						toolbar: {
							value: searchText,
							onChange: (event) => requestSearch(event.target.value),
							clearSearch: () => {
								requestSearch('')
							},
						},
					}}
				/>
			</div>
		</div>

	);
}

export default ListClients;