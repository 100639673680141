import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuthContext} from "../../contexts/auth";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid, Link,
    Paper, Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {myStyles, Title, ToastMsg} from "components";
import IsoIcon from '@mui/icons-material/Iso';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import apiClient from "../../services/apiClient";
import moment from "moment";
import ModalSubscriptionAddonClient from "../modal/ModalSubscriptionAddonClient";
import Swal from "sweetalert2";
import theme from "../../theme";

function CenterPlanInformations(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const {user} = useAuthContext();
    const [isProcessing, setIsProcessing] = useState(false)
    const [subscription, setSubscription] = useState({})
    const [totalEmployees, setTotalEmployees] = useState(0)
    const [TotalClientCard, setTotalClientCard] = useState(0)
    const [isChecked, setIsChecked] = useState(false)
    const [subscriptionAutoRenewEdited, setSubscriptionAutoRenewEdited] = useState(false)
    const [openClientModal, setOpenClientModal] = useState(false)
    const [crudAddon, setCrudAddon] = useState(false);
    const [openEmployeeModal, setOpenEmployeeModal] = useState(false)
    const [subscriptionUpdated, setSubscriptionUpdated] = useState(false)

    useEffect(() => {
        setIsProcessing(true)
        setCrudAddon(false)
        apiClient.fetchCenterTierInformation(user.centerId).then(({data}) => {
            setSubscription(data)
            setTotalEmployees(data.employeeIncluded + data.extraEmployee)
            setTotalClientCard(data.clientCardIncluded + data.extraClientCard)
            setIsChecked(data.autoRenew)
            setIsProcessing(false)
        }).catch(error => {
            setIsProcessing(false)
        })

    }, [crudAddon]);

    const handleAddonClient = () => {
        setCrudAddon(false)
        setOpenClientModal(true)
    }

    const handleCrudClientAddon = () => {
        setCrudAddon(true)
    }

    const handleStripe = () => {
        window.open(process.env.REACT_APP_STRIPE_PORTAIL, '_blank');
    }

    return (
        <>
            <ModalSubscriptionAddonClient open={openClientModal} setOpen={(o) => setOpenClientModal(o)} clientCardUsing={subscription.usingClientCard} clientCardAvailable={subscription.clientCardIncluded + subscription.extraClientCard } handleCrudClientAddon={handleCrudClientAddon}/>
            {errors?.autoRenew && <ToastMsg open={true} type='error' message={t('centerPage.AutoRenewError')}/>}
            {crudAddon && <ToastMsg open={true} type='success' message={t('centerPage.CrudClientRecordSuccess')}/>}
            {subscriptionAutoRenewEdited && <ToastMsg open={true} type='success' message={t('centerPage.AutoRenewSuccess')}/>}
            {!isProcessing && (
                <Paper sx={{p: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} md={7}>
                            <Title
                                balise={'h3'}
                                title={t('centerPage.CenterPlanInformations')}
                                subtitle='' forgetLabel={false}/>
                        </Grid>
                        <Grid item xs={12}  sx={{pt:3}}>
                            <Typography variant="h6" align="left" >
                                {t('centerPage.YourForfait')} : {t(`centerPage.${subscription.tierName}`)} {!subscription.autoRenew && "(" + t('centerPage.Canceled') + ")"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{pt:3}}>
                            <Paper sx={{p: 2}}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Typography variant="h6" align="center" >
                                        {t('centerPage.ClientCard')}
                                    </Typography>
                                    <Tooltip title={t('centerPage.AddClientCard')} sx={myStyles.iconsCenter} >
                                        <IsoIcon onClick={handleAddonClient}/>
                                    </Tooltip>
                                </Stack>
                                <Typography variant="h6" align="left" >
                                    ({subscription.clientCardIncluded})
                                </Typography>
                                <Typography variant="h5" align="center" sx={{fontWeight:'bold', pt:3}} >
                                    {subscription.usingClientCard} / {TotalClientCard}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{pt:3}}>
                           <Paper sx={{p: 2}}>
                               <Typography variant="h6" align="center" >
                                   {t('centerPage.Employees')}
                               </Typography>
                               <Typography variant="h6" align="center" >
                                   ({subscription.employeeIncluded})
                               </Typography>
                               <Typography variant="h5" align="center" sx={{fontWeight:'bold', pt:3}} >
                                   {subscription.usingEmployee} / {totalEmployees}
                               </Typography>
                           </Paper>
                        </Grid>
                        <Grid item xs={12} sx={{pt:3, textAlign:'center'}}>
                            <Button variant="contained" component="label" color="primary" onClick={handleStripe}>
                                {t('centerPage.StripeGestion')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    );
}

export default CenterPlanInformations;