import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styled from '@emotion/styled';
import {alpha, Autocomplete, TextField} from '@mui/material';
import apiClient from '../../services/apiClient';
import {useAuthContext} from '../../contexts/auth';

const DivSearch = styled('div')(({ theme }) => ({
	position: 'relative',
	'fieldset': {
		border: 'none',
	},

	'& input:focus': {
		backgroundColor: 'white',
		borderRadius: '4px'
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: 0,
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

function Search(props) {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});
	const [clientList, setClientList] = useState([])
	const [isFetching, setIsFetching] = useState(false)
	const {user, initialized} = useAuthContext()

	useEffect(() => {
		const makeLabelFromList = (list) => { 
			const options = [];
			list.forEach(function(option) {
				options.push({ label: option.firstName + ' ' + option.lastName, id: option.id })
			});
			return options
		}
		const fetchCenterClientList = async() => {
			setIsFetching(true)
			const {data, error} = await apiClient.fetchCenterClientList(user.centerId)
			if (data){
				setClientList(makeLabelFromList(data))
			}
			if (error){
				setErrors({msg: error})
			}
			setIsFetching(false)
		}
		fetchCenterClientList()
	}, [])

	return (
		<DivSearch className="searchClient">
			<Autocomplete
				disablePortal
				id="combo-box-demo"
				options={clientList}
				sx={{ width: 290,m:0,  borderRadius: '4px'}}
				renderInput={(params) => <TextField {...params} label={t('search.Client')} />}
				onChange={(event, newValue) => {
					if (newValue.id) {
						navigate('../center/client/' + newValue.id);
						window.location.reload(false);
					}
				}}
			/>
		</DivSearch>
	);
}

export default Search;