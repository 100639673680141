import React, {useState} from 'react';
import {Card, Grid, IconButton, SvgIcon, Tooltip} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import 'moment/locale/fr';
import moment from 'moment/moment';
import {useTranslation} from "react-i18next";
import {myStyles} from "components";

function AppointmentCardClient({appointmentList, handleOpen, handleOpenDocument }) {
	const {t} = useTranslation();
	return (
		<>
			{
				appointmentList.map((appointment, index) => (
					<Grid item xs={12} key={index}>
						<Card sx={{p: 1}} >
							<Grid container alignItems="center">
								<Grid item xs={3} sm={2} md={2} sx={{textAlign: 'left'}}>
									<img width='50px'
									     src={`../../images/treatments/vert/${appointment.treatment}.png`}
									     alt={appointment.treatment}/>
								</Grid>
								<Grid item xs={9} sm={6} md={6} sx={{textAlign: 'center'}}>
									{moment(appointment.date).format('LLLL')}
								</Grid>
								<Grid item xs={12} sm={4} md={4} sx={{textAlign: 'right'}}>
									<Tooltip title={t('appointment.ViewBilan')} sx={myStyles.icons} >
										<LibraryBooksIcon sx={myStyles.icons} onClick={() => handleOpenDocument(appointment)}/>
									</Tooltip>
									<Tooltip title={t('appointment.EditAppointmentModalTitle')} sx={myStyles.icons} >
										<EditIcon sx={myStyles.icons} onClick={() => handleOpen(appointment)}/>
									</Tooltip>
								</Grid>
							</Grid>
						</Card>
					</Grid>
				))
			}
		</>
	);
}

export default AppointmentCardClient;