import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
	Alert, Box,
	CircularProgress,
	FormControlLabel,
	FormLabel,
	Grid,
	Link,
	Radio,
	RadioGroup, Stack,
	Tooltip, Typography
} from '@mui/material';
import {myStyles, Title, ToastMsg} from 'components';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import SaveIcon from '@mui/icons-material/Save';
import MapAutoComplete from '../textfield/MapAutoComplete';
import {BaseTextField} from '../textfield/StyledTextField';
import NumberFormat from 'react-number-format';
import apiClient from '../../services/apiClient';
import {useParams} from 'react-router-dom';
import PhotoConsentFormModal from "../modal/PhotoConsentFormModal";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			format="(###) ###-####" mask="_"
		/>
	);
});



function EditClientInfo({client, clientEdited}) {
	const {t} = useTranslation();
	const {id} = useParams()
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState(client);
	const [edited, setEdited] = useState(false)
	const [isProcessing, setIsProcessing] = useState(false)
	const [lang, setLanguage] = useState(client?.langPreferences || 'fr')
	const [openModal, setOpenModal] = useState(false)

	const handleEdit = async () => {
		setErrors((e) => ({...e, form: null}))

		const requiredFields = [
			'address',
			'mainPhone',
			'email'];
		let hasError = false;

		Object.entries(form).map(field => {
			if (requiredFields.indexOf(field[0]) !== -1 && field[1] === '') {
				setErrors(
					(e) => ({...e, [field[0]]: "app.RequiredFields"}))
				hasError = true
			}
		})
		if (!hasError) {
			setIsProcessing(true)
			setEdited(false)
			const {data, error} = await apiClient.editClient(form, form.id)
			if (data) {
				setEdited(true)
				clientEdited()
			}
			if (error) {
				setErrors({form: error})
			}
			setIsProcessing(false)
		}
	}

	const handleChange = (element) => {
		if (errors.hasOwnProperty(element.target.name)) {
			setErrors((e) => ({...e, [element.target.name]: null}))
		}
		if (element.target.name === "email") {
			if (element.target.value.indexOf("@") === -1) {
				setErrors((e) => ({...e, email: "app.ValidEmail"}))
			} else {
				setErrors((e) => ({...e, email: null}))
			}
		}
		setForm({...form, [element.target.name]: element.target.value});
	}

	const handleChangeAutoComplete = (value) => {
		setErrors((e) => ({...e, address: null}))
		setForm(
			{...form, address: (value?.description ? value.description : '')})
	}

	function handleLangChange(element) {
		setErrors((e) => ({...e, langPreferences: null}))
		setLanguage(element.target.value)
		setForm({...form, langPreferences: element.target.value})
	}

	const handleCloseModal = (isUpdate) => {
		setOpenModal(false)
		if (isUpdate) {
			clientEdited()
		}
	}

	return (
		<>
			{errors?.form &&
				<ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{edited && <ToastMsg open={true} type='success'
			                     message={t('client.EditedSuccess')}/>}
			<PhotoConsentFormModal open={openModal} handleCloseModal={handleCloseModal} client={client}/>
			<Grid container alignItems="center" >
				{
					client?.authorisation?.signature === null ? (
						<Grid item xs={12}>
							<Alert sx={{justifyContent:"center", fontSize:'22px'}} severity="error">{t('client.PictureAuthorisationNotFilled')}</Alert>
						</Grid>
					) : (
						!client?.authorisation?.consent && (
							<Grid item xs={12}>
								<Alert sx={{justifyContent:"center", fontSize:'22px'}} severity="warning">{t('client.NoPictureAuthorisation')}</Alert>
							</Grid>
						)
					)
				}
			</Grid>
			<Grid container alignItems="center" spacing={2}>
				<Grid item xs={12} sx={{pt:2}}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Typography variant="h3">{form?.firstName + ' ' + form?.lastName}</Typography>
						<Box sx={{display:'inline-flex'}}>
							<Tooltip title={t('client.PictureAuthorizationIcon')} sx={myStyles.icons} >
								<CameraEnhanceIcon onClick={() => setOpenModal(true)}/>
							</Tooltip>
							<Tooltip title={t('app.Save')} sx={myStyles.icons} >
								<SaveIcon onClick={handleEdit}/>
							</Tooltip>
						</Box>
					</Stack>
				</Grid>
				<Grid item xs={12} md={6}>
					<BaseTextField
						label={t('client.FirstName')}
						required
						fullWidth
						autoFocus
						variant="outlined"
						name="firstName"
						onChange={handleChange}
						value={form.firstName}
						error={errors.firstName && true}
						helperText={errors.firstName && t(errors.firstName) }
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<BaseTextField
						label={t('client.LastName')}
						required
						fullWidth
						variant="outlined"
						name="lastName"
						onChange={handleChange}
						value={form.lastName}
						error={errors.lastName && true}
						helperText={errors.lastName && t(errors.lastName) }
					/>
				</Grid>
				<Grid item xs={12} md={10}>
					<MapAutoComplete valueAutoComplete={form?.address}
									 errorAutoComplete={errors.address &&
									 true}
									 helperTextAutoComplete={errors.address &&
									 t(errors.address)}
									 handleChangeAutoComplete={handleChangeAutoComplete}/>
				</Grid>
				<Grid item xs={12} md={2}>
					<BaseTextField
						label={t('client.Local')}
						fullWidth
						variant="outlined"
						name="local"
						onChange={handleChange}
						value={form.local}
						error={errors.local && true}
						helperText={errors.local && t(errors.local)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<BaseTextField
						label={t('client.MainPhone')}
						required
						fullWidth
						onChange={handleChange}
						name="mainPhone"
						id="formatted-mainPhone-input"
						InputProps={{
							inputComponent: NumberFormatCustom,
						}}
						variant="outlined"
						value={form.mainPhone}
						error={errors.mainPhone && true}
						helperText={errors.mainPhone &&
						t(errors.mainPhone)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<BaseTextField
						label={t('client.AltPhone')}
						fullWidth
						onChange={handleChange}
						name="altPhone"
						id="formatted-altPhone-input"
						InputProps={{
							inputComponent: NumberFormatCustom,
						}}
						variant="outlined"
						value={form.altPhone}
						error={errors.altPhone && true}
						helperText={errors.altPhone &&
						t(errors.altPhone)}
					/>
				</Grid>
				<Grid item xs={12}>
					<BaseTextField
						label={t('client.Email')}
						required
						fullWidth
						variant="outlined"
						name="email"
						onChange={handleChange}
						value={form.email}
						error={errors.email && true}
						helperText={errors.email && t(errors.email)}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormLabel component="legend">{t('app.LangPreference')}</FormLabel>
					<RadioGroup row aria-label="gender" name="row-radio-buttons-group" onChange={handleLangChange} value={lang}>
						<FormControlLabel value="fr" control={<Radio />} label={t('app.French')} />
						<FormControlLabel value="en" control={<Radio />} label={t('app.English')} />
					</RadioGroup>
				</Grid>
			</Grid>
		</>
	);
}

export default EditClientInfo;