import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from 'react';
import {
    AppointmentPage,
    ClientListPage,
    ClientPage,
    ConfirmAccount,
    ExamenPeauNotePage,
    Login,
    Portail,
    ProtectedRoute,
    EditDocumentPageNoAuth,
    AppointmentNotePage,
    CenterPage,
    ElectrolyseNotePage,
    IPLNotePage,
    ThermocoagulationNotePage,
    BotoxNotePage,
    ResetPassword,
    UpdatePassword,
    ViewNotePage,
    CompletedDocumentPageNoAuth, DocumentPageLikePDF
} from 'components';
import './asset/css/App.css';
import {AuthContextProvider, useAuthContext} from "contexts/auth";
import { DocumentPage, EditDocumentPage,FillEmptyDocumentPage} from 'components'

export default function AppContainer() {
    return (
        <AuthContextProvider>
            <App/>
        </AuthContextProvider>
    )
};

function App() {
    return (
        <div className="App">
           <BrowserRouter>
                 <Routes>
                        <Route exact path="/" element={<Login/>} />
                        <Route exact path="/resetPassword" element={<ResetPassword/>} />
                        <Route exact path="/resetPassword/:id/:token" element={<UpdatePassword/>} />
                        <Route exact path="/confirmaccount/:token" element={<ConfirmAccount/>} />
                        <Route exact path="/portail" element={<ProtectedRoute element={<Portail/>} />} />
                        <Route exact path="/center" element={<ProtectedRoute element={<CenterPage/>} />} />
                        <Route exact path="/center/appointment" element={<ProtectedRoute element={<AppointmentPage/>} />} />
                        <Route exact path="/center/clientlist" element={<ProtectedRoute element={<ClientListPage/>} />} />
                        <Route exact path="/center/client/:id" element={<ProtectedRoute element={<ClientPage/>} />} />
                        <Route exact path="/center/client/:id/viewdocument/:docid" element={<ProtectedRoute element={<DocumentPageLikePDF/>} />} />
                        <Route exact path="/center/client/:id/viewdocument/:docid/:print" element={<ProtectedRoute element={<DocumentPageLikePDF/>} />} />
                        <Route exact path="/center/client/:id/view/:docid" element={<ProtectedRoute element={<DocumentPage/>} />} />
                        <Route exact path="/center/client/:id/edit/:docid" element={<ProtectedRoute element={<EditDocumentPage/>} />} />
                        <Route exact path="/center/client/:id/view/appointmentnotes/:idTreatment" element={<ProtectedRoute element={<ViewNotePage/>} />} />
                        <Route exact path="/center/client/:id/appointmentnotes/:idTreatment" element={<ProtectedRoute element={<AppointmentNotePage viewOnly={false}/>} />} />
                        <Route exact path="/center/client/:id/appointmentnotes/electrolyse" element={<ProtectedRoute element={<ElectrolyseNotePage viewOnly={false}/>} />} />
                        <Route exact path="/center/client/:id/appointmentnotes/ipl" element={<ProtectedRoute element={<IPLNotePage viewOnly={false}/>} />} />
                        <Route exact path="/center/client/:id/appointmentnotes/thermocoagulation" element={<ProtectedRoute element={<ThermocoagulationNotePage viewOnly={false}/>} />} />
                        <Route exact path="/center/client/:id/appointmentnotes/botox" element={<ProtectedRoute element={<BotoxNotePage viewOnly={false}/>} />} />
                        <Route exact path="/center/client/:id/appointmentnotes/examenpeau/:idTreatment" element={<ProtectedRoute element={<ExamenPeauNotePage viewOnly={false}/>} />} />
                        <Route exact path="/center/appointment" element={<ProtectedRoute element={<AppointmentPage/>} />} />
                        <Route exact path="/document/:docid/:id" element={<EditDocumentPageNoAuth/>} />
                        <Route exact path="/document/:docid/completed/:id" element={<CompletedDocumentPageNoAuth/>} />
                        <Route exact path="/client/document/:token" element={<FillEmptyDocumentPage/>} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}


