import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
	DataGrid,
	enUS,
	frFR,
	GridColDef
} from '@mui/x-data-grid';

import moment from "moment/moment";

function ListNoteBotox({notes}) {
	const {t} = useTranslation();

	const columns: GridColDef[] = [
		{
			field: "date",
			headerName: t("botoxNotes.Date"),
			type: 'date',
			flex: 1,
			renderCell: (n) => (
				<>
					<span>
						{moment(n.row.date).format('LL')}
					</span>
				</>
			)
		},
		{ field: 'area', headerName: t('botoxNotes.Region'), flex: 1, editable: false,},
		{ field: 'nbUnit', headerName: t('botoxNotes.NbUnit'), flex: 1, editable: false,},
		{ field: 'technique', headerName: t('botoxNotes.Technique'), flex: 2, editable: false,},
		{ field: 'products', headerName: t('botoxNotes.Products'), flex: 3, editable: false,},
		{ field: 'notes', headerName: t('botoxNotes.Notes'), flex: 3, editable: false,},
		{ field: 'employeeName', headerName: t('botoxNotes.Employee'), flex: 1, editable: false,},
	];
	return (
		<DataGrid
			autoHeight
			rows={notes}
			localeText={localStorage.getItem("i18nextLng") === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
			disableColumnMenu={true}
			disableColumnFilter={true}
			hideFooterSelectedRowCount={true}
			disableDensitySelector={true}
			columns={columns}
		/>
	);
}

export default ListNoteBotox;