import React, {useEffect, useState} from 'react';
import { Title, ToastMsg} from 'components';
import {CircularProgress, Grid, Tooltip} from '@mui/material';
import AddBusinessRounded from "@mui/icons-material/AddComment";
import apiClient from '../../../services/apiClient';
import {useTranslation} from 'react-i18next';
import ListEmployee from '../../grid/ListEmployee'
import theme from '../../../theme';
import {ModalEmployee, myStyles} from 'components'
import Swal from "sweetalert2";
import {useAuthContext} from '../../../contexts/auth';
import {useNavigate} from "react-router-dom";

function EmployeeSection(props) {
	const {t} = useTranslation();
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)
	const [employees, setEmployees] = useState([])
	const [listTable, setListTable] = useState([])
    const [open, setOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false)
	const [crudCompleted, setCrudCompleted] = useState(false)
	const { user, initialized} = useAuthContext()
	const navigate = useNavigate();

	const fetchEmployee = async() => {
		setIsFetching(true)
		const {data, error} = await apiClient.fetchEmployeeList(user.centerId)
		if (data){
			setEmployees(data.employees)
		}
		if (error){
			setErrors({msg: error})
		}
		setIsFetching(false)
	}

	useEffect(() => {
		if(user.isEmployee) {
			navigate("/portail")
		} else {
			fetchEmployee()
		}
	}, [])

	const handleOpen = () => {
		setOpen(true);
        setIsProcessing(true)
        setCrudCompleted(false)
    }

    const handleClose= (event, reason) => {
		setOpen(false);
		setIsProcessing(false)
    }

    const handleCrudCompleted = (form) => {
        setIsProcessing(false)
        setOpen(false)
        setCrudCompleted(true)
		fetchEmployee();
    }

	const updateList = (e) => {
		const index = Object.keys(e)[0]
		if (index) {
			var newlist = JSON.parse(JSON.stringify(listTable));
			if (e[index].message) {
				newlist.forEach((item) => {
					if (item.id === parseInt(index)) {
						item.message = e[index].message.value
					}
				})
			} else if (e[index].date) {
				newlist.forEach((item) => {
					if (item.id === parseInt(index)) {
						item.date = e[index].date.value
					}
				})
			}
			setListTable(newlist);
			setEmployees({note: employees.note, table: newlist})
		}
	}

	const deleteEmployee = async(user_PK) => {
		const {data, error} = await apiClient.deleteEmployee(user_PK)
		if (data) {
			Swal.fire({
				title: t('employee.DeletionCompletedTitle'),
				html:t('employee.DeletionCompleted'),
				icon: 'success',
				timer: 3000,
				timerProgressBar: true,
			}).then((result) => {
				fetchEmployee();
			})
		}
		if (error) { 
			setErrors({msg: error})
		}
	}
	
	const max = t('employee.NumberEmployee') + ' (' + employees.length + '/' + user.maxEmployee + ')';

	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{!isFetching &&(
				<>
					<Grid container>
						<Grid item xs={12} md={8}>
							<Title balise={'h3'} title='employee.Title' subtitle='' forgetLabel={false}/>
							<Title title='' subtitle={max} forgetLabel={false}/>
						</Grid>
						<Grid item xs={12} md={4} sx={{textAlign: 'right'}}>
							<Tooltip title={t('employee.AddNewEmployee')} sx={myStyles.icons}>
								<AddBusinessRounded onClick={() => handleOpen(null)} />
							</Tooltip>
						</Grid>
					</Grid>
					
                    <ModalEmployee open={open} handleClose={handleClose} handleCrudCompleted={handleCrudCompleted}/>

					<Grid item xs={12}>
						<ListEmployee list={employees} updateList={updateList} deleteEmployee={deleteEmployee}></ListEmployee>
					</Grid>
				</>
			)}		
		</>
	);
}

export default EmployeeSection;