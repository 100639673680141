import React, {useEffect, useState} from 'react';
import { TreatmentCardList,myStyles, Title, ToastMsg} from 'components';
import {CircularProgress, Grid, Tooltip} from '@mui/material';
import apiClient from '../../../services/apiClient';
import {useAuthContext} from '../../../contexts/auth';
import {useTranslation} from 'react-i18next';
import SaveIcon from "@mui/icons-material/Save";

function ServicesSection(props) {
	const {t} = useTranslation();
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)
	const [treatmentList, setTreatmentList] = useState([])
	const { user, initialized} = useAuthContext()
	const [selectedTreatments, setSelectedTreatments] = useState( user.centerTreatment)
	const [saveSelectedTreatments, setSaveSelectedTreatments] = useState(false)
	const [treatmentListChanged, setTreatmentListChanged] = useState(false)

	useEffect(() => {
		setSaveSelectedTreatments(false)
		const fetchTreatmentList = async() => {
			setIsFetching(true)
			 const {data, error} = await apiClient.fetchTreatmentList()
			if (data){
				setTreatmentList(data)
			}
			if (error){
				setErrors({msg: error})
			}
			setIsFetching(false)
		}
		fetchTreatmentList()
	}, [])

	function onListChanged(selected){
		setTreatmentListChanged(true)
		setSaveSelectedTreatments(false)
		setSelectedTreatments(selected)
	}

	function saveSelectedTreatment() {
		const updateSelectedTreatment = async() => {
			const {data, error} = await apiClient.updateSelectedTreatment(selectedTreatments, user.centerId)
			if (data) {
				setSaveSelectedTreatments(true)
				setTreatmentListChanged(false)
			}
			if (error) {
				setErrors({msg: error})
			}
		}
		if (treatmentListChanged){
			updateSelectedTreatment()
		}
	}

	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{saveSelectedTreatments && <ToastMsg open={true} type='success' message={t('app.SaveSuccess')}/>}
			{!isFetching &&(
				<>
					<Grid container>
						<Grid item xs={12} md={8}>
							<Title balise={'h3'} title='services.Title' subtitle='services.Subtitle' forgetLabel={false}/>
						</Grid>
						<Grid item xs={12} md={4} sx={{textAlign: 'right'}}>
							<Tooltip title={t('services.Save')} sx={myStyles.icons} >
								<SaveIcon onClick={saveSelectedTreatment} />
							</Tooltip>
						</Grid>
					</Grid>

					<Grid container alignItems="center" justifyContent="space-between" spacing={3} sx={{paddingTop:2}}>
						<TreatmentCardList contents={treatmentList} treatmentSelected={selectedTreatments} onChange={onListChanged}/>
					</Grid>
				</>
			)}
		</>
	);
}

export default ServicesSection;