import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Drawer, Grid, Tooltip, Typography} from "@mui/material";
import {useFollowUpContext} from "../../contexts/FollowUpContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {myStyles} from "components";



function FollowUpDrawer({open, handleDrawer}) {
    const {t} = useTranslation();
    const { handleAddFollowUpClick, showFollowUpList } = useFollowUpContext();

    return (
        <>
            <Drawer
                anchor='right'
                open={open}
                onClose={handleDrawer}
            >
                <Box
                    sx={{ width: 320, mt:'75px', p: 2 }}
                    role="presentation"
                    onClick={handleDrawer}
                    onKeyDown={handleDrawer}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h4" gutterBottom component="div">
                                {t('followUp.ClientFollowUpTitle')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{textAlign:'right'}}>
                            <Tooltip title={t('followUp.AjouterClientFollowUpTitle')} sx={myStyles.smallIcons} >
                                <AddCircleOutlineIcon onClick={() => handleAddFollowUpClick()}/>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                showFollowUpList()
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </>
    );
}

export default FollowUpDrawer;