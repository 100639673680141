import React, {useContext, useEffect, useState} from 'react'
import {FormContext} from '../../../contexts/FormContext'
import {FormContextNoAuth} from '../../../contexts/FormContextNoAuth'
import {InputLabel, Checkbox, Grid, FormControlLabel} from "@mui/material"
import {useTranslation} from "react-i18next";
import {FormExamenPeauContext} from "../../../contexts/FormExamenPeauContext";

const FormCheckbox = ({field_id, field_label, field_value, field_attributes, viewMode, isNoAuth, isExamenPeau, handleMinSelected = () => {
	return undefined
}}) => {
	const {t} = useTranslation()
	const [isChecked, setIsChecked] = useState(false)
	const [isDisabled, setIsDisabled] = useState(false)
	const { handleChange } = useContext(isNoAuth ? FormContextNoAuth : !isExamenPeau ? FormContext : FormExamenPeauContext);

	const xs = field_attributes?.find(element => element.xs)?.xs ? field_attributes?.find(element => element.xs).xs : 12
	const md = field_attributes?.find(element => element.md)?.md ? field_attributes?.find(element => element.md).md : 12
	const lg = field_attributes?.find(element => element.lg)?.lg ? field_attributes?.find(element => element.lg).lg : 12

	useEffect(() => {
		setIsChecked(field_value)
		setIsDisabled(viewMode)
	})

	const handleCheckBox = (event) => {
		setIsChecked(!isChecked)
		handleChange(field_id, event)
		handleMinSelected(field_id, !isChecked)
	};

	return (
		<Grid item xs={xs} md={md} lg={lg}>
			<FormControlLabel control={<Checkbox checked={isChecked} onChange={handleCheckBox} disabled={isDisabled} inputProps={{ 'aria-label': 'controlled' }}/>} label={t(field_label)} />
		</Grid>
	)
}

export default FormCheckbox
