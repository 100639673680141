import React, {useEffect, useState, useContext} from 'react'
import {Grid, FormGroup} from "@mui/material"
import {useTranslation} from "react-i18next";
import {Element, FormCheckbox, FormLabel} from "../../index";
import {FormContext} from '../../../contexts/FormContext'
import {FormContextNoAuth} from '../../../contexts/FormContextNoAuth'
import {FormExamenPeauContext} from "../../../contexts/FormExamenPeauContext";


const FormCheckboxGroup = ({field_id, field_group, field_attributes, viewMode, isNoAuth, isExamenPeau}) => {
	const {t} = useTranslation()
	const [error, setError] = useState(true)
	const {handleSectionError} = useContext(isNoAuth ? FormContextNoAuth : !isExamenPeau ? FormContext : FormExamenPeauContext)
	const msgError = field_attributes?.find(element => element.msgError)?.msgError ? t(field_attributes?.find(element => element.msgError).msgError) : t('form.CheckBoxSelection')

	const handleMinSelected = (id, checked) => {
		const index = field_group.findIndex(field => field.field_id === id);
		field_group[index].field_value = checked
		setError(field_group.findIndex(field => field.field_value === true) === -1)
		handleSectionError(field_group[index].field_id, checked)
	}

	useEffect(() => {
		field_group.some(function(e, i){
			if (e.field_value === true) {
				setError(false)
			}
		});
	}, [field_group])

	return (
		<Grid item xs={12}>
			{ !viewMode && error ? (<span className="errorMsg">{msgError}</span>) : null }
				<FormGroup id={field_id}>
					<Grid container>
						{field_group.map((field, index) => {
								return (
									<FormCheckbox key={index}
										field_id={field.field_id}
										field_label={field.field_label}
										field_value={field.field_value}
										field_attributes={field.field_attributes}
									    viewMode={viewMode}
										handleMinSelected={handleMinSelected}
										isNoAuth={isNoAuth}
										isExamenPeau={isExamenPeau}
									/>
								)
							}
						)}
					</Grid>
				</FormGroup>
		</Grid>
	)
}

export default FormCheckboxGroup