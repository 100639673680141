import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, CircularProgress, Grid, Stack, Tooltip} from "@mui/material";
import theme from "../../theme";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {AppointmentCardClient, ModalAppointment, myStyles, Title, ToastMsg} from "components";
import apiClient from '../../services/apiClient';
import {useNavigate, useParams} from 'react-router-dom';
import _ from "lodash";
import Swal from "sweetalert2";
import moment from "moment";

function ClientAppointmentSection({client}) {
	const {t} = useTranslation()
	const {id} = useParams()
	const navigate = useNavigate()
	const [errors, setErrors] = useState({});
	const [isProcessing, setIsProcessing] = useState(false)
	const [appointments, setAppointments ] = useState({})
	const [openAppointment, setOpenAppointment] = useState(false);
	const [completed, setCompleted] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [isSend, setIsSend] = useState(false)
	const [appointmentInfos, setAppointmentInfos] = useState({})

	useEffect( () => {
		setIsSend(false)
		if (client.id){
			setIsProcessing(true)
			apiClient.fetchClientAppointment(client.id).then(({data}) => {
                setAppointments(data)
            }).catch((error) => {
                setErrors({msg: error})
            })
			setIsProcessing(false)
			setIsFetching(false)
		}
	}, [client.id, completed])

	const handleOpen = (appointment = null) => {
		setAppointmentInfos({
			id: appointment?.id,
			treatmentId: appointment?.idTreatment,
			start: appointment?.date,
			end: appointment?.id ? moment(appointment?.date).add(appointment?.time, 'minutes') : null,
			clientId: id,
			note: appointment?.note
		})
		setIsProcessing(true)
		setOpenAppointment(true);
		setCompleted(false)
	}

	const handleCloseAppointment = (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpenAppointment(false)
			setIsProcessing(false)
		}
	}

	const handleCrudCompleted = (appointment) => {
		setCompleted(true)
		setOpenAppointment(false)
		setIsProcessing(false)
		if (appointment?.appointment?.isFirstAppointment){
			Swal.fire({
				title: t('bilan.AppointmentDocument'),
				text: t('bilan.AppointmentDocumentText'),
				showDenyButton: true,
				showCancelButton: true,
				confirmButtonText: t('bilan.SendDocumentToFillOut'),
				denyButtonText: t('bilan.EditDocumentToFillOut'),
				confirmButtonColor: theme.palette.primary.main,
				denyButtonColor: theme.palette.secondary.main,
				cancelButtonColor: theme.palette.secondary.light,
			}).then((result) => {
				if (result.isConfirmed) {
					apiClient.SendDocumentToFillOutEmail(appointment?.appointment?.idDocument, client)
						.then(() => {
							setIsSend(true)
						})
						.catch(error => {
							setErrors({message : t('bilan.DocumentSentError')})
						})
				} else if (result.isDenied) {
					navigate(`edit/${appointment?.appointment?.idDocument}`)
				}
			})
		}
	}

	const handleCancel = () => {
		setCompleted(true)
		setOpenAppointment(false)
		setIsProcessing(false)
	}

	const handleOpenDocument = (appointment) => {
		if (_.isEmpty(appointment.docData)) {
			Swal.fire({
				title: t('bilan.AppointmentDocument'),
				text: t('bilan.AppointmentDocumentText'),
				showDenyButton: true,
				showCancelButton: true,
				confirmButtonText: t('bilan.SendDocumentToFillOut'),
				denyButtonText: t('bilan.EditDocumentToFillOut'),
				confirmButtonColor: theme.palette.primary.main,
				denyButtonColor: theme.palette.secondary.main,
				cancelButtonColor: theme.palette.secondary.light,
			}).then((result) => {
				if (result.isConfirmed) {
					apiClient.SendDocumentToFillOutEmail(appointment.idDocument, client)
						.then(() => {
							setIsSend(true)
						})
						.catch(error => {
							setErrors({message : t('bilan.DocumentSentError')})
						})
				} else if (result.isDenied) {
					navigate(`edit/${appointment.idDocument}`)
				}
			})
		}else{
			if (appointment?.isSigned){
				navigate("/center/client/" + id + "/view/" + appointment.idDocument)
			}else{
				navigate(`edit/${appointment.idDocument}`)
			}
		}
	}

	return (
		<>
			{errors?.msg &&
				<ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{completed &&
				<ToastMsg open={true} type="success" message={t('app.SaveSuccess')}/>
			}
			{isSend &&
				<ToastMsg open={true} type='success' message={t('bilan.DocumentSentSuccess')}/>
			}
			<>
				<Grid container alignItems="center">
					<Grid item xs={12} sx={{pb:3}}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="flex-start"
							spacing={2}
						>
							<Title  balise={'h3'}  title={'client.Appointment'} subtitle='' forgetLabel={false}/>
							<Box sx={{display:'inline-flex'}}>
								{isProcessing && <CircularProgress color="secondary" size={40}/>}
								<Tooltip title={t('client.AddAppointment')} sx={myStyles.icons} >
									<AddCircleOutlineIcon sx={myStyles.icons} onClick={() => handleOpen(null)}/>
								</Tooltip>
							</Box>
						</Stack>

					</Grid>
					<Grid container spacing={1}>
						{!isFetching && (
							<>
								<ModalAppointment open={openAppointment} handleCloseModal={handleCloseAppointment} appointmentInfos={appointmentInfos} handleUpdate={handleCrudCompleted} addGlobal={false} handleCancel={handleCancel}/>
								{ appointments?.length ? (
									<AppointmentCardClient handleOpen={handleOpen} appointmentList={appointments} handleOpenDocument={handleOpenDocument}/>
									) : (
									<Grid item xs={12} key={0}>
										{t('client.NoAppointment')}
									</Grid>
								)}
							</>
						)}
					</Grid>
				</Grid>
			</>
		</>
	);
}

export default ClientAppointmentSection;