import React from 'react';
import {useTranslation} from 'react-i18next';

function ForgetSaveLabel(props) {
	const {t} = useTranslation();

	return (
		<u>
			{t('app.ForgetSave')}
		</u>
	);
}

export default ForgetSaveLabel;