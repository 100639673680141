import {createContext, useContext, useEffect, useState} from 'react';
import apiClient from "../services/apiClient";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import theme from "../theme";
import Swal from "sweetalert2";
import _ from "lodash";

export const FormContext = createContext(null);

export const FormContextProvider = ({children, viewNoMessage=false}) => {
    const {t} = useTranslation()
    const {id, docid} = useParams()
    const [errors, setErrors] = useState(null);
    const [errorPage, seterrorPage] = useState(null);
    const [elements, setElements] = useState(null);
    const [form, setForm] = useState({});
    const [section, setSection] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [treatmentName, setTreatmentName] = useState({});
    const [client, setClient] = useState({});
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [signature, setSignature] = useState(null);
    const [signatureDate, setSignatureDate] = useState(null);
    const [idTreatment, setIdTreatment] = useState(null);

    useEffect(() => {
        setIsFetching(true);
        seterrorPage(null);

        apiClient.fetchClientDocumentData(id, docid)
            .then(({data}) => {
                if (data?.appointmentData?.baseForm?.forceUpdate){
                    setElements(data?.appointmentData?.baseForm?.baseForm)
                    if (!data?.appointmentData?.isFirstAppointment){
                        Swal.fire({
                            icon: 'warning',
                            title: t('form.ChangedFormTitle'),
                            text: t('form.ChangedFormTitleText'),
                            confirmButtonColor: theme.palette.primary.main,
                        })
                    }
                    handleFormSection(data?.appointmentData?.baseForm?.baseForm, true)
                }else{
                    handleFormSection(data?.appointmentData.docData, false)
                    setElements(data?.appointmentData.docData)
                    if (!viewNoMessage){
                        Swal.fire({
                            icon: 'info',
                            title: t('form.DocumentFilledValidation'),
                            text: t('form.DocumentFilledValidationText'),
                            confirmButtonColor: theme.palette.primary.main,
                        })
                    }
                }
                setAppointmentDate(data?.appointmentData?.date);
                setClient(data?.client)
                setTreatmentName(data.appointmentData?.treatment)
                setIdTreatment(data.appointmentData?.idTreatment)
                setSignature(data.appointmentData?.signature)
                setSignatureDate(data.appointmentData?.signatureDate)
                setIsFetching(false);
            })
            .catch(errorPage => {
                setIsFetching(false);
            });
    }, []);

    const handleChange = (id, event) => {
        seterrorPage(null)
        switch (event.target.type) {
            case 'checkbox':
                setForm({...form, [id]: {id: id, value: event.target.checked}  });
                break;
            case 'datepicker':
                setForm({...form, [id]: {id: id, value: event.target.value}});
                break;
            default:
                setForm({...form, [id]: {id: id, value: event.target.value}});
                break;
        }

    }

    const formHasSectionError = () => {
        return section.find(element => element.error === true);
    }

    const handleFormSection = (elements, isNewForm) => {
        let checkboxSection=[];

        elements.map(function(element){
            let f;
            element.fields.forEach(function(field, index){
                if (field.field_type === 'checkboxGroup') {
                    checkboxSection.push({
                        id: field.field_id,
                        group: field.field_group,
                        error:isNewForm,
                    });
                }
            });
        });
        setSection(checkboxSection);
    }

    const handleSectionError = (id, value) => {
        const checkBoxSection = [...section];
        let pIndex;
        checkBoxSection.map(function(element, index){
            pIndex = index;
            element.group.some(function(e, i){
                if (e.field_id === id) {
                    checkBoxSection[pIndex].error = !value;
                }
            });
        });
        setSection(checkBoxSection);
    }

    const handleCompletedForm = () =>{
        setCompleted(!completed);
    }
    const handleSubmit = (signature) => {
        setIsFetching(true);
        const newForm = {...form};
        let newElements = [...elements];
        let sectionError = formHasSectionError();
        if (!signature.isEmpty()) {
            if (!sectionError){
                Object.entries(newForm).map(item => {
                    const {id, value} = item[1];
                    newElements.map(element => {
                        element.fields.map(field => {
                            if (field.field_id === id) {
                                field['field_value'] = value;
                                return true;
                            }
                        })
                    });
                })
                setElements(newElements)
                apiClient.updateClientDocument(docid, newElements, signature.getTrimmedCanvas().toDataURL()).then(({data}) => {
                    if (data !== null){
                        handleCompletedForm();
                        setIsFetching(false);
                    }else{
                        seterrorPage({form: t('app.errorPage')});
                        setIsFetching(false);
                    }
                }).catch(error => {
                    seterrorPage({form: t('app.Error')});
                    setIsFetching(false);
                })
            }else{
                seterrorPage({msg: t('form.HasErrors', {section: t(`formSection.${sectionError.id}`)})});
                setIsFetching(false);
            }
        }else{
            seterrorPage({msg: t('form.MissingAgreement')});
            setIsFetching(false);
        }
    }

    const formValue = {
        appointmentDate,
        client,
        completed,
        errors,
        setErrors,
        errorPage,
        elements,
        setElements,
        form,
        setForm,
        formHasSectionError,
        idTreatment,
        isFetching,
        setIsFetching,
        setSignature,
        setSignatureDate,
        signatureDate,
        signature,
        handleChange,
        handleCompletedForm,
        handleFormSection,
        handleSectionError,
        handleSubmit,
        treatmentName
    }

    return (
        <FormContext.Provider value={formValue}>
            {children}
        </FormContext.Provider>
    )
}

export const useFormContext = () => useContext(FormContext);