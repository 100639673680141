import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import {Box, CircularProgress, Grid, Stack, Tooltip, Typography} from '@mui/material';
import {ListClientGeneralNote,ModalNoteGeneral, myStyles, Title, ToastMsg} from 'components';
import apiClient from "../../services/apiClient";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function ClientGeneralNoteSection({client}) {
    const {t} = useTranslation()
	const navigate = useNavigate()
	const [errors, setErrors] = useState({})
	const [isFetching, setIsFetching] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [open, setOpen] = useState(false);
    const [crudCompleted, setCrudCompleted] = useState(false)
    const [notes, setNotes] = useState([])
    const [noteId, setNoteId] = useState(null)
    const [editNote, setEditNote] = useState(null)

	useEffect( () => {
		if(client.id) {
            setIsFetching(true)
            apiClient.fetchClientGeneralNotes(client.id)
                .then(({data}) => {
                    setIsFetching(false)
					setNotes(data)
                })
                .catch(error => {
                    setIsFetching(false)
                    setErrors(error.response.data.errors)
                })
        }
    }, [client.id, crudCompleted])

    const handleOpen = (noteId = null) => {
        if (noteId != null){
            const noteFilter =  notes.filter((a) => a.id === noteId)
            setEditNote(noteFilter[0])
            setOpen(true);
            setNoteId(noteId)
        }else{
            setEditNote({id:null, note:''})
            setOpen(true);
            setNoteId(null)
        }
        setIsProcessing(true)
        setCrudCompleted(false)
    }

    const handleClose= (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            setIsProcessing(false)
            setNoteId(null)
        }
    }

    const handleCrudCompleted = () => {
        setIsProcessing(false)
        setOpen(false)
        setCrudCompleted(true)
        setNoteId(null)
    }

	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.message &&
			    <ToastMsg open={true} type='error' message={t('app.Error')}/>}
            {crudCompleted &&
                <ToastMsg open={true} type="success" message={t('app.SaveSuccess')}/>
            }
			{!isFetching && (
				<>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Typography variant="h3">{t('notes.NoteGeneralTitle')}</Typography>
                                <Box sx={{display:'inline-flex'}}>
                                    {isProcessing && <CircularProgress color="secondary" size={40}/>}
                                    <Tooltip title={t('notes.AddNoteModalTitle')} sx={myStyles.icons} >
                                        <AddCircleOutlineIcon onClick={() => handleOpen(null)}/>
                                    </Tooltip>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <ListClientGeneralNote notes={notes} handleOpen={handleOpen}/>
                        </Grid>
                    </Grid>
                    <ModalNoteGeneral open={open} handleClose={handleClose} handleCrudCompleted={handleCrudCompleted} editNote={editNote}/>
				</>
			)}

		</>
	);
 }

export default ClientGeneralNoteSection;