import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
	Button,
	CircularProgress,
	FormControl, FormHelperText,
	Grid,
	InputLabel, MenuItem,
	Select,
} from '@mui/material';
import {BaseTextField} from '../textfield/StyledTextField';
import 'moment/locale/fr';
import moment from 'moment/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {useAuthContext} from '../../contexts/auth';
import {TREATMENTS} from '../../constants/EnumTreatment';
import apiClient from '../../services/apiClient';

function AppointmentForm({closeModal, appointmentId, appointmentList, handleCrudCompleted, client, handleCancelAppointmentCompleted}) {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});
	const [isProcessing, setIsProcessing] = useState(false);
	const [appointment, setAppointment] = useState({idTreatment: '', date: moment(), appointmentId: appointmentId});
	const {user, initialized} = useAuthContext()
	const [treatmentList, setTreatmentList] = useState([])

	useEffect(() => {
		setTreatmentList(user.centerTreatment.treatmentList)
		if (appointmentId != null){
			const appointmentFilter =  appointmentList.filter((a) => a.id === appointmentId)
			setAppointment(appointmentFilter[0])
		}
	}, []);

	const submitHandler = async () => {
		const requiredFields = [
			'idTreatment',
			'date',
		];
		let hasError = false;
		setIsProcessing(true)
		setErrors((e) => ({...e, appointment: null}))

		Object.entries(appointment).map(field => {
			if (requiredFields.indexOf(field[0]) !== -1 && field[1] === '') {
				setErrors(
					(e) => ({...e, [field[0]]: "app.RequiredFields"}))
				hasError = true
			}
		})

		let result = null;
		if (!hasError) {
			if (appointmentId !== null) {
				result = await apiClient.editClientAppointment(appointment)
			} else{
				result = await apiClient.addClientAppointment(appointment, client.id )
			}
			const {data, error} = result
			if (data) {
				setIsProcessing(false)
				handleCrudCompleted(data.isFirstTimeAppointment, data.appointment)
			}
			if (error) {
				setIsProcessing(false)
				setErrors({form: error})
			}
		}
	}

	const handleCancelAppointment = async () => {
		await apiClient.cancelClientAppointment(appointmentId).then((data, error) => {
			if (data) {
				setIsProcessing(false)
				handleCancelAppointmentCompleted()
			}
			if (error) {
				setIsProcessing(false)
				setErrors({form: error})
			}
		})
	}
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterMoment}>
			<Grid container spacing={1} sx={{pt: 2}}>
				<Grid item xs={12} >
					<FormControl fullWidth autoFocus required>
						<InputLabel htmlFor="demo-simple-select">Service</InputLabel>
						<Select
							id="demo-simple-select"
							value={appointment?.idTreatment ? appointment?.idTreatment : ''}
							label="Service *"
							error={errors.idTreatment && true}
							onChange={(element) => {
								setAppointment({...appointment, idTreatment: element.target.value});
							}}
						>
							{treatmentList.map( (item, keyIndex) =>
								<MenuItem  key={keyIndex} value={item}>{t(`treatment.${Object.keys(TREATMENTS).find(k=>TREATMENTS[k]===item)}`)}</MenuItem>
							)}
						</Select>
						{errors.idTreatment && <FormHelperText className="errorMsg"> {t(
							errors.idTreatment)}</FormHelperText>
						}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<DateTimePicker
						label="Date"
						value={appointment?.date}
						onChange={(newValue) => {
							setAppointment({...appointment, date: newValue.format()});
						}}
						renderInput={(params) => <BaseTextField fullWidth required {...params}  />}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isProcessing} onClick={closeModal}>
						{t('app.Close')}
					</Button>
				</Grid>
				<Grid item xs={12} lg={4}>
					{ appointmentId != null && (
							<Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleCancelAppointment}>
								{t('appointment.CancelAppointment')}
							</Button>
						)
					}
				</Grid>
				<Grid item xs={12} lg={4}>
					<Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isProcessing} onClick={submitHandler}>
						{isProcessing && <CircularProgress color="secondary"  size={20} />}
						{!isProcessing && t('app.Save')}
					</Button>
				</Grid>
			</Grid>
			</LocalizationProvider>
		</>
	);
}

export default AppointmentForm;