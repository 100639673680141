import React, {useEffect, useState} from 'react';
import {
	CircularProgress,
	Grid, Tooltip, Typography
} from '@mui/material';
import apiClient from '../../../services/apiClient';
import {useAuthContext} from '../../../contexts/auth';
import {useTranslation} from 'react-i18next';
import {AddNewClient,ListClients,myStyles, Title, ToastMsg} from 'components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


function ClientListPage(props) {
	const {t} = useTranslation();
	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)
	const {user, initialized} = useAuthContext()
	const [open, setOpen] = useState(false);
	const [added, setAdded] = useState()
	const [clientList, setClientList] = useState([])


	useEffect(() => {
		setAdded(false)
		setOpen(false)
		const fetchCenterClientList = async() => {
			setIsFetching(true)
			const {data, error} = await apiClient.fetchCenterClientList(user.centerId)
			if (data){
				setClientList(data)
			}
			if (error){
				setErrors({msg: error})
			}
			setIsFetching(false)
		}
		fetchCenterClientList()
	}, [added])

	const handleOpen = () => setOpen(true);
	const handleClose = (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpen(false)
		}
	}
	const handleAddClient = () => {
		setAdded(true)
	}

	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{added && <ToastMsg open={true} type='success' message={t('client.AddedSuccess')}/>}
			{!isFetching && (
				<>
					<Grid container alignItems="center"  >
						<Grid item xs={12} md={8} >
							<Typography variant="h2">
								{t('client.Title')}
								<Tooltip title={t('client.Subtitle')} sx={myStyles.icons} >
									<AddCircleOutlineIcon onClick={handleOpen}/>
								</Tooltip>
							</Typography>

						</Grid>
					</Grid>

					<Grid container alignItems="center" justifyContent="space-between" spacing={3} sx={{paddingTop:2}}>
						<Grid item xs={12}>
							<AddNewClient open={open} handleClose={handleClose} handleAddClient={handleAddClient}/>
							<ListClients list={clientList}/>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
}

export default ClientListPage;