import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {Tooltip, useMediaQuery} from "@mui/material";
import {
    DataGrid,
    enUS,
    frFR,
    GridColDef
} from '@mui/x-data-grid';
import {myStyles} from "components";
import theme from "../../theme";

function ListExamenPeauNotes({oldNotes, handleCrudOldNotes}) {
    const {t} = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const columns: GridColDef[] = [
        {
            field: "view",
            headerName:t("client.ViewHeader"),
            width: 70,
            align: 'center',
            sortable: false,
            hide: !isMobile,
            renderCell: (note) => (
                <>
                    <Tooltip title={t('client.ViewHeader')} sx={myStyles.smallIcons} >
                        <VisibilityIcon onClick={() => handleCrudOldNotes(note.row.id, true)}/>
                    </Tooltip>

                </>
            )
        },
        {
            field: "treatmentName",
            headerName: t("examenPeauNotes.Treatment"),
            flex: 1,
            renderCell: (n) => (
                <>
					<span>
						{t(`treatment.${n.row.treatmentName}`)}
					</span>
                </>
            )
        },
        {
            field: "date",
            headerName: t("examenPeauNotes.Date"),
            type: 'date',
            flex: 1,
            renderCell: (n) => (
                <>
					<span>
						{moment(n.row.date).format('LL')}
					</span>
                </>
            )
        },
        { field: 'employeeName', headerName: t('examenPeauNotes.Employee'), flex: 1, editable: false,hide: isMobile},
        {
            field: "copy",
            headerName:t("examenPeauNotes.CopyNoteHeader"),
            width: 150,
            align: 'center',
            sortable: false,
            hide: isMobile,
            renderCell: (n) => (
                <>
                    <Tooltip title={t('examenPeauNotes.Copy')} sx={myStyles.smallIcons} >
                        <CopyAllIcon onClick={() => handleCrudOldNotes(n.row.id, false)}/>
                    </Tooltip>

                </>
            )
        }
    ];

    return (
        <>
            <DataGrid
                autoHeight
                rows={oldNotes}
                localeText={localStorage.getItem("i18nextLng") === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
                disableColumnMenu={true}
                disableColumnFilter={true}
                hideFooterSelectedRowCount={true}
                disableDensitySelector={true}
                columns={columns}
            />
        </>
    );
}

export default ListExamenPeauNotes;