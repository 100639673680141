import React, {useEffect} from 'react';
import {Grid} from '@mui/material';
import {TREATMENTS} from "../../../constants/EnumTreatment";
import {ExamenPeauNotePage, ElectrolyseNotePage, IPLNotePage, ThermocoagulationNotePage, BotoxNotePage, AppointmentNotePage} from '../../../components';
import {useParams} from "react-router-dom";

function ViewNotePage(props) {
    const {idTreatment} = useParams();

	const showNotes = () => {
        switch (parseInt(idTreatment)){
            case TREATMENTS.Electrolyse:
                return <ElectrolyseNotePage viewOnly={true}/>
            case TREATMENTS.IPL:
                return <IPLNotePage viewOnly={true}/>
            case TREATMENTS.Thermocoagulation:
                return <ThermocoagulationNotePage viewOnly={true}/>
            case TREATMENTS.SoinsVisage:
                return <ExamenPeauNotePage viewOnly={true}/>
            case TREATMENTS.Peeling:
                return <ExamenPeauNotePage viewOnly={true}/>
            case TREATMENTS.Botox:
                return <BotoxNotePage viewOnly={true}/>
            default:
                return <AppointmentNotePage viewOnly={true}/>
        }
    }
	return (
		<>
            <Grid container alignItems="center">
                <Grid item xs={12} >
                    {showNotes()}
                </Grid>
            </Grid>
		</>
	);
}

export default ViewNotePage;