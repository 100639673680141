import React, {useState} from 'react';
import { useAuthContext } from "contexts/auth";
import {BackToTop, Header, Login, ToastMsg} from "components"
import {useTranslation} from 'react-i18next';
import apiClient from '../../services/apiClient';
import {Alert, Box, CircularProgress, Link} from '@mui/material';
import styled from '@emotion/styled';
import {FollowUpContextProvider} from "../../contexts/FollowUpContext";
import {ApplicationAuthContextProvider} from "../../contexts/ApplicationAuthContext";
import {useNavigate} from "react-router-dom";

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

export default function ProtectedRouteContainer({element}) {
	return (
		<>
			<ApplicationAuthContextProvider>
				<FollowUpContextProvider>
					<Protected element={element} />
				</FollowUpContextProvider>
			</ApplicationAuthContextProvider>
		</>
	)
}

function Protected({element}) {
	const { t } = useTranslation()
	const { user, initialized} = useAuthContext()
	const [isProcessing, setIsProcessing] = useState(false)
	const [errors, setErrors] = useState({})

	if (!initialized) return null

	if (initialized && !user?.email) {
		return <Login message="app.MustBeAuthenticated"/>
	}else if(!user?.centerStatus){
		return <Login message="app.AccountDeactivated"/>
	}

	async function handleResendClick() {
		setIsProcessing(true)
		setErrors((e) => ({...e, resend: null}))

		const {error} = await apiClient.ResendConfirmEmail(user.email)

		if (error) {
			setErrors((e) => ({...e, resend: true}))
		}
		setIsProcessing(false)
	}

	return <>
		<Box sx={{ display: 'flex' }}>
			<Header/>
			<div className='main-container' id="top-anchor" >
				<DrawerHeader />
				{!user?.accountConfirmed && (
					<Alert sx={{justifyContent:"center"}} severity="warning">{t('auth.ConfirmEmailError')}
						<Link
							component="button"
							variant="body2"
							sx={{paddingLeft:"15px"}}
							onClick={handleResendClick}
						>{ isProcessing ?  <CircularProgress color="secondary" size={18} />: t('auth.ResendConfirmEmail') }</Link>
					</Alert>
				)}
				{errors?.resend && <ToastMsg open={true} type='error' message={t('auth.ResendConfirmEmailError')}/>}
				<div className="element-container" >
					{element}
				</div>
			</div>
			<BackToTop />
		</Box>
	</>
}