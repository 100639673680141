import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Card, Grid} from "@mui/material";
import moment from "moment/moment";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from "@mui/icons-material/Visibility";
import NotesIcon from "@mui/icons-material/NotesTwoTone";
import {myStyles} from "components";

function ListDocumentsBilanClient({documents, handleModal, handlePdf, handleNotes}) {
    const {t} = useTranslation()

    useEffect(() => {
    }, [])
    
    if (documents.length === 0) {
        return (
            <div className="text-center" key={99}>
                <h4>{t('Aucun document')}</h4>
            </div>
        )
    }else {
        return documents.map((document, index ) => {
            const {treatmentName, documentDate} = document
            return (
                <Grid item xs={12} md={2} sx={{textAlign: 'center'}} key={index}>
                    <Card sx={{p:2}}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sx={{textAlign: 'center'}}>
                                <Grid item xs={12}>
                                    <img width='75px'
                                         src={`../../images/treatments/vert/${treatmentName}.png`}
                                         alt={treatmentName}/>
                                </Grid>
                                <Grid item xs={12}>
                                    {moment(documentDate).format('LL')}
                                </Grid>
                            </Grid>
                            <Grid item xs={4} sx={{textAlign: 'center', pt:2}}>
                                <VisibilityIcon sx={myStyles.smallIcons} onClick={() => handleModal(document)}/>
                            </Grid>
                            <Grid item xs={4} sx={{textAlign: 'center', pt:2}}>
                                <NotesIcon sx={myStyles.smallIcons} onClick={() => handleNotes(document)}/>
                            </Grid>
                            <Grid item xs={4} sx={{textAlign: 'center', pt:2}}>
                                <PictureAsPdfIcon sx={myStyles.smallIcons} onClick={() => handlePdf(document)}/>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            )
        })
    }
}

export default ListDocumentsBilanClient;