import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Grid, Modal, Stack, TextField} from '@mui/material';
import {ClientForm, FastAddClientForm, myStyles, Title} from 'components';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function AddNewClient({open, handleClose, handleAddClient, fastAdd = false}) {
	const {t} = useTranslation();

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={myStyles.boxModal}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Title title='client.AddNewClientModalTitle' subtitle='' forgetLabel={false}/>
						<HighlightOffIcon sx={myStyles.iconsWithLeftMargin} onClick={handleClose}/>
					</Stack>
					{
						!fastAdd ? (
							<ClientForm closeModal={handleClose} handleAddClient={handleAddClient}/>
						): (
							<FastAddClientForm closeModal={handleClose} handleAddClient={handleAddClient}/>
						)
					}
				</Box>
			</Modal>
		</>
	);
}

export default AddNewClient;