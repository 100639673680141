import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';
import {ForgetSaveLabel} from '../index';
import moment from "moment";

function DocumentTitle({title, subtitle, appointmentDate}) {
	const {t} = useTranslation();

	return (
		<>
			<Typography variant='h2'>{t(title)}</Typography>
			{subtitle && <Typography variant="h6" gutterBottom>
					{t(subtitle)} - {moment(appointmentDate).format('LL')}
				</Typography>
			}
		</>
	);
}

export default DocumentTitle;