import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import {Box, Button, CircularProgress, Grid, Modal, Stack} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {myStyles, Title} from "components";
import {BaseTextField} from "../textfield/StyledTextField";
import apiClient from "../../services/apiClient";
import {useParams} from "react-router-dom";

function ModalNoteGeneral({open, handleClose, handleCrudCompleted, editNote}) {
    const {t} = useTranslation()
	const [isProcessing, setIsProcessing] = useState(false);
	const [note, setNote] = useState()
	const {id} = useParams()
	const [errors, setErrors] = useState({});

	useEffect( () => {
	    setNote(editNote)
    }, [editNote])

	const submitHandler = async () => {
		let hasError = false;
		setIsProcessing(true);
		setErrors((e) => ({...e, note: null}))

		if (note?.note === '') {
			setErrors({note: 'app.RequiredFields'})
			setIsProcessing(false);
			hasError = true;
		}
		if (!hasError) {
			apiClient.crudClientNoteGeneral(note, id)
			    .then(() => {
			        setIsProcessing(false);
			        handleCrudCompleted();
			    })
			    .catch(error => {
			        setIsProcessing(false);
			        setErrors({note: error.response.data.message})
			    })
		}
	}

	const handleChange = (element) => {
		if (errors.hasOwnProperty(element.target.name)) {
			setErrors((e) => ({...e, [element.target.name]: null}))
		}
		setNote({...note, note: element.target.value});
	}

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={myStyles.smallBoxModal}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Title balise={'h4'} title={editNote === null || !editNote ? t('notes.AddNoteModalTitle') : t('notes.EditNoteModalTitle')} subtitle='' forgetLabel={false}/>
						<HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
					</Stack>
					<Grid container alignItems="center">
						<Grid item xs={12} >
							<BaseTextField
								label={t('notes.Note')}
								fullWidth
								variant="outlined"
								name="note"
								multiline={true}
								minRows="5"
								onChange={handleChange}
								value={note?.note}
								error={errors?.note && true}
								helperText={errors.note && t(errors.note)}
							/>
						</Grid>
						<Grid item xs={12} lg={4}>
							<Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isProcessing} onClick={handleClose}>
								{t('app.Close')}
							</Button>
						</Grid>
						<Grid item xs={12} lg={4}/>
						<Grid item xs={12} lg={4} sx={{pt:2}}>
							<Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isProcessing} onClick={submitHandler}>
								{isProcessing && <CircularProgress color="secondary"  size={20} />}
								{!isProcessing && t('app.Save')}
							</Button>
						</Grid>
					</Grid>

				</Box>
			</Modal>

		</>
	);
 }

export default ModalNoteGeneral;