import React from 'react'
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";


const FormImage = ({field_id, field_label, field_placeholder, field_value, field_attributes, field_mandatory}) => {
	const {t} = useTranslation();
	const xs = field_attributes?.find(element => element.xs)?.xs ? field_attributes?.find(element => element.xs).xs : 12
	const md = field_attributes?.find(element => element.md)?.md ? field_attributes?.find(element => element.md).md : 12
	const lg = field_attributes?.find(element => element.lg)?.lg ? field_attributes?.find(element => element.lg).lg : 12
	const imageSrc = field_attributes?.find(element => element.img)?.img ? field_attributes?.find(element => element.img).img : '../../../../images/logowithicon.png'
	return (
		<Grid item xs={xs} md={md} lg={lg}>
			<img src={imageSrc} alt={t(field_label)} />
		</Grid>
	)
}

export default FormImage
