import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import { TranslateLink, PasswordRule } from 'components'
import apiClient from 'services/apiClient';
//images
import loginImg from 'asset/images/loginImg.png'
import logo from 'asset/images/logo.png'
import {Box, Button, Container, Grid, InputAdornment, Link, Typography} from "@mui/material";
import {BaseTextField} from '../../textfield/StyledTextField';
import theme from '../../../theme';
import PasswordValidator from 'password-validator';
import Swal from "sweetalert2";

const myStyles = {
	container: {
		margin:"auto",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		minHeight: "100vh",
		padding: "100px 0",
	},
	leftSide:{
		backgroundColor: theme.palette.primary.main,
		borderRadius:"20px",
		padding:"15px"
	},
	rightSide:{
		padding: "70px 0px 70px 24px",
		[theme.breakpoints.down('sm')]: {
			padding: "20px",
		},
	},
};

function UpdatePassword({}) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isProcessing, setIsProcessing] = useState(false)
	const [isDone, setDone] = useState(false)
	const [form, setForm] = useState({ email: null });
	const [errors, setErrors] = useState({})
    const {token, id} = useParams()
    const [isModifiedPassword, setModified] = useState(false);
	const [isFetching, setIsFetching] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [pwdError, setPwdError] = useState(['min', 'uppercase', 'lowercase', 'digits', 'symbols'])

    const fetchUserFromToken = async() => {
		setIsFetching(true)
		const {data, error} = await apiClient.fetchUserFromTokenPassword(token)
		if (data){
            setModified(data.isModified)		
        }
		if (error){
			setErrors({msg: error})
		}
		setIsFetching(false)
	}

	useEffect(() => {
		fetchUserFromToken()
	}, [])

	const validatePasswordSecurity = (password) => {
		const pwdRule = new PasswordValidator();
		pwdRule
		.is()
		.min(8) // Minimum length 8
			.has()
			.uppercase() // Must have uppercase letters
			.has()
			.lowercase() // Must have lowercase letters
			.has()
			.digits(1) // Must have at least 1 digits
			.has()
			.symbols(1) // Must have at least 1 special character
			.has()
			.not()
		.spaces(); // Should not have spaces

		setPwdError(pwdRule.validate(password, {list : true}))
	}
	const handleChange = (element) => {
		if ( errors.hasOwnProperty(element.target.name) ) {
			setErrors((e) => ({ ...e, [element.target.name]: null }))
		}

		if (element.target.name === 'password') {
			validatePasswordSecurity(element.target.value);
		}

		if (element.target.name === "confirmPassword") {
			if (element.target.value !== form.password) {
				setErrors((e) => ({ ...e, confirmPassword: "passwordRule.PwMatchingPassword" }))
			} else {
				setErrors((e) => ({ ...e, confirmPassword: null }))
			}
		}

		setForm({ ...form, [element.target.name]: element.target.value });
	}

	const handleShowPassword = () => setShowPassword(!showPassword);

	const submitHandler = async () => {
		setIsProcessing(true)
		let hasError = false;
		setErrors((e) => ({ ...e, form: null }))

		if (form.confirmPassword !== form.password) {
			setErrors((e) => ({
				...e,
				confirmPassword: "passwordRule.PwMatchingPassword"
			}))
			hasError = true
		} else {
			setErrors((e) => ({...e, confirmPassword: null}))
		}

		if (!hasError) {
			const { data, error } = await apiClient.updatePassword({ form: form, token: token })
	
			if (data) {
				setDone(true)
			}
			if (error) {
				setErrors((e) => ({ ...e, form: error }))
			}
	
			if (data) {
				Swal.fire({
					title: t('register.RegisterCompletedTitle'),
					html:t('register.RegisterCompleted'),
					icon: 'success',
					timer: 5000,
					timerProgressBar: true,
				}).then((result) => {
					navigate('/')
				})
			}
			if (error) {
				setErrors({form: error})
			}
		}
		setIsProcessing(false)
	}

	return (
		<>
			<Container maxWidth="lg" sx={myStyles.container}>
				<Grid container alignItems="center" justifyContent="space-between">
				<Grid item xs={12} md={6} sx={myStyles.leftSide}>
					<div>
						<img src={logo} alt="" className='login__logo' />
						<div className='login__translate'>
							<TranslateLink />
						</div>
					</div>
					<div className="login__image">
						<img src={loginImg} alt="NessaApp" width="250px" />
						<p>{t('app.Slogan')}</p>
					</div>
				</Grid>
				<Grid item xs={12} md={6}  sx={myStyles.rightSide}>
					{isModifiedPassword && <><Box display="flex" justifyContent="flex-start"	alignItems="flex-start" flexDirection="column">
						<Typography variant="h2">{t('auth.ResetPasswordUsed')}</Typography>
						<Typography gutterBottom>{t('auth.ResetPasswordUsedInfo')}</Typography>
						<Link href="/resetPassword">{t('auth.ResetPassword')}</Link>
					</Box></>}
					{!isModifiedPassword && <>
					<Box display="flex" justifyContent="flex-start"	alignItems="flex-start" flexDirection="column">
						<Typography variant="h2">{t('auth.UpdatePassword')}</Typography>
						<Typography gutterBottom>{t('auth.UpdatePasswordInfo')}</Typography>
					</Box>
					<Grid item xs={12} md={6}>
								<BaseTextField
									label={t('register.Password')}
									fullWidth
									required
									type={showPassword? 'text' : 'password'}
									variant="outlined"
									name="password"
									onChange={handleChange}
									value={form.password}
									error={errors.password && true}
									helperText={errors.password && t(errors.password)}
									InputProps={{
										endAdornment: (
											<InputAdornment
												onClick={handleShowPassword}
												className="showPassword"
												position="end">
												{showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} /> }
											</InputAdornment>
										),
									}}
								/>

							</Grid>
							<Grid item xs={12} md={6}>
								<BaseTextField
									label={t('register.ConfirmPassword')}
									fullWidth
									required
									type={showPassword? 'text' : 'password'}
									variant="outlined"
									name="confirmPassword"
									onChange={handleChange}
									value={form.confirmPassword}
									error={errors.confirmPassword && true}
									helperText={errors.confirmPassword && t(errors.confirmPassword)}
									InputProps={{
										endAdornment: (
											<InputAdornment
												onClick={handleShowPassword}
												className="showPassword"
												position="end">
												{showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} /> }
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<PasswordRule pwdRuleError={pwdError}/>
							</Grid>
					<Button fullWidth variant="contained"  size="large" type="submit" disabled={isProcessing || errors.email} onClick={submitHandler}>
						{isProcessing ? t('app.Loading') : t('auth.Reset') }
					</Button></>}
				</Grid>
			</Grid>
			</Container>
		</>
	);
}

export default UpdatePassword;