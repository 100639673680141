import React, {useEffect, useState} from 'react';
import theme from "../../theme";
import {useTranslation} from "react-i18next";
import {Box, Button, Divider, Grid, Modal, Stack, Typography} from "@mui/material";
import {myStyles, Title} from "components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment/moment";
import {BaseTextField} from "../textfield/StyledTextField";
import {Autocomplete} from "@mui/lab";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from "@mui/x-date-pickers";
import {useAuthContext} from "../../contexts/auth";
import {useFollowUpContext} from "../../contexts/FollowUpContext";

function ModalCrudFollowUp({followUp, handleClose, isEditFollowUp, open, handleSaveCrud}) {
    const {t} = useTranslation()
    const [errors, setErrors] = useState({})
    const [ followUpToSave, setFollowUpToSave ] = useState({});
    const { user } = useAuthContext()
    const { clientList } = useFollowUpContext();

    useEffect(() => {
        setErrors({})
        isEditFollowUp ? setFollowUpToSave(followUp) : setFollowUpToSave((e) => ({ ...followUp, dateToFollowUP: moment()}))
    },[followUp]);

    const handleChange = (element) => {
        setErrors(e => ({...e, global:false}))
        if (errors.hasOwnProperty(element.target.name)) {
            setErrors((e) => ({...e, [element.target.name]: null}))
        }
        setFollowUpToSave({...followUpToSave, note: element.target.value});
    }

    const handleSubmit = () => {
        let hasError = false;
        if (followUpToSave.note === '' || followUpToSave.note === null || followUpToSave.dateToFollowUP === '' || followUpToSave.dateToFollowUP === null) {
            setErrors((e) => ({...e, global: true}))
            hasError = true;
        }
        if (!isEditFollowUp && (followUpToSave.clientId === '' || followUpToSave.clientId === null || followUpToSave.clientId === undefined)) {
            setErrors((e) => ({...e, global: true}))
            hasError = true;
        }
        !hasError && handleSaveCrud(followUpToSave);
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-edit-follow-up-appointment"
                aria-describedby="modal-edit-follow-up-description"
            >
                <Box sx={myStyles.smallBoxModal}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Title balise={'h4'} title={isEditFollowUp ? t('followUp.ModifierClientFollowUpTitle') : t('followUp.AjouterClientFollowUpTitle')}/>
                            <HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
                        </Stack>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        {
                            errors?.global && <Grid item xs={12} sx={{pt:1}}> <Typography variant="body2" color="error">{t('followUp.ErreurGlobal')}</Typography></Grid>
                        }
                        <Grid item xs={12} sx={{pt: '10px', pb: '10px'}}>
                            {
                                isEditFollowUp || followUp?.clientName ? (
                                    <Typography variant="h5" sx={{fontWeight:'bold'}}>{followUp.clientName}</Typography>
                                ): (
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        id="combo-box-demo"
                                        options={clientList ? clientList : []}
                                        sx={{m :0,  borderRadius: '4px'}}
                                        renderInput={(params) => <BaseTextField {...params} label={t('followUp.Client')} />}
                                        onChange={(event, newValue) => {
                                            if (newValue.id) {
                                                setFollowUpToSave({
                                                    ...followUpToSave,
                                                    clientId: newValue.id,
                                                    centerId: user.centerId,
                                                    employeeId: user.id,
                                                })
                                            }
                                        }}
                                    />
                                )
                            }

                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label={t('followUp.Date')}
                                value={followUpToSave?.dateToFollowUP}
                                onChange={(newValue) => {
                                    setFollowUpToSave({...followUpToSave, dateToFollowUP: newValue})
                                }}
                                renderInput={(params) => <BaseTextField {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{pt: '10px', pb: '10px'}}>
                            <BaseTextField
                                label={t('followUp.Note')}
                                fullWidth
                                variant="outlined"
                                name="note"
                                multiline={true}
                                minRows="5"
                                onChange={handleChange}
                                value={followUpToSave?.note}
                                error={errors?.note && true}
                                helperText={errors?.note && t(errors.note)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid container sx={{pt:1}}>
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleClose}>
                                    {t('app.Close')}
                                </Button>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" color="primary" size="large" type="submit" onClick={handleSubmit}>
                                    {t('followUp.SaveCrudFollowUp')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    </LocalizationProvider>
                </Box>
            </Modal>
        </>
    );
}

export default ModalCrudFollowUp;