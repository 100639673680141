import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FormContextProvider, useFormContext} from '../../../contexts/FormContext';
import Element from "../../form/Element";
import {Alert, Backdrop, Button, CircularProgress, Grid, Link, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {DocumentTitle, ModalResignDocument} from "../../index";
import {useNavigate, useParams} from "react-router-dom";
import theme from "../../../theme";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EventNote from '@mui/icons-material/NoteAltTwoTone';
import moment from "moment/moment";
import Swal from "sweetalert2";
import {TREATMENTS} from "../../../constants/EnumTreatment";
import {noteRedirectTo} from "../../../utils/utils";
import {useAuthContext} from "../../../contexts/auth";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const myStyles = {
    paper: {
        padding: theme.spacing(2),
    },
    icons:{
        fontSize:'60px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        }
    }
};

export default function ViewFormContainer(){
    return (
        <FormContextProvider viewNoMessage={true}>
            <DocumentPageLikePDF />
        </FormContextProvider>
    )
}

function DocumentPageLikePDF(props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [error, setError] = useState(null);
    const {id, docid, print} = useParams()
    const { user } = useAuthContext()
    const {appointmentDate, client, elements, isFetching, signatureDate, signature, treatmentName, idTreatment} = useFormContext();

    useEffect(() => {
        if (!isFetching && appointmentDate) {
            if (print === "print") {
                setTimeout(function() { //Start the timer
                    printDocument(); //After 1 second, print data to remove gray loading
                }.bind(this), 100)
            }
        }
    }, [isFetching, appointmentDate]);

    const handleCancel = () => {
        navigate("/center/client/" + id)
    }

    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        input.style.width = "1024px"
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF("p", "pt", "letter");
                const imgProperties = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 10;
                const pdfHeight =
                ((imgProperties.height * pdfWidth) / imgProperties.width )- 10;
                let heightLeft = pdfHeight;
                let position = 10;
                let pageHeight = 782;

                pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position -= (pageHeight + 5); // top padding for other pages
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
                    heightLeft -= pageHeight;
                }
                pdf.save("download.pdf");
                handleCancel();
            });
    }

    return (
        <div id="divToPrint">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container alignItems="center" id={print ? "header-print" : "header-document"}>
                <Grid item xs={12} >
                    <Tooltip title={t('form.BackToClientPage')} sx={myStyles.icons} >
                        <ArrowBackIosIcon onClick={() => handleCancel()}/>
                    </Tooltip>
                </Grid>
            </Grid>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
            >
                <div>
                    <DocumentTitle title={`${client?.firstName} ${client?.lastName}`} subtitle={`${t(`treatment.${treatmentName}`)}`} appointmentDate={appointmentDate}/>
                </div>
            </Stack>
            <Grid container alignItems="center" sx={{pt:3}} spacing={1} id={print ? "printingView" : "viewContainer"}>
                    {
                        elements ? elements.map((elm, i) => {
                            const {fields, page_label} = elm ?? {};
                            return (
                                <Grid item xs={12} key={page_label}>
                                    <Paper  sx={myStyles.paper} >
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">{t(page_label)}</Typography>
                                            </Grid>
                                            {fields ? fields.map((field, i) => <Element field={field} key={i} viewMode={true}/>) : null}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        }) : null }

                    <Grid container alignItems="center" sx={{pt:5}} spacing={1}>
                        <Grid item xs={12}>
                            <Paper  sx={myStyles.paper} >
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">{t('form.ConsentTitle')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            idTreatment === TREATMENTS.Botox ?
                                                (
                                                    <>
                                                        <Typography variant="h6">{t('form.ConsentBotox', {centerName : user.centerName})}</Typography>
                                                        <Typography variant="h6" sx={{pt:2, pb:2}}>{t('form.Consent2Botox')}</Typography>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Typography variant="h6">{t('form.Consent', {centerName : user.centerName})}</Typography>
                                                        <Typography variant="h6" sx={{pt:2, pb:2}}>{t('form.Consent2')}</Typography>
                                                    </>
                                                )
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Paper  sx={myStyles.paper} >
                                                    <img className="" src={signature}  alt="signature"/>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} sx={{textAlign: 'center'}}>
                                        {moment(signatureDate).format('ll')}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        {error?.msg &&
                            <Grid item xs={12}>
                                <span className="errorMsg">{error.msg}</span>
                            </Grid>
                        }
                    </Grid>
                    <Grid container alignItems="center" spacing={1} sx={{pt: 5}} id={print ? "printerFooter" : "footerContainer"}>
                        <Grid item xs={12} lg={3}>
                            <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleCancel}>
                                {t('app.Cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
        </div>
    );
}