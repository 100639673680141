import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FormContextProvider, useFormContext} from '../../../contexts/FormContext';
import Element from "../../form/Element";
import {Alert, Backdrop, Button, CircularProgress, Grid, Link, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {DocumentTitle, ModalResignDocument} from "../../index";
import {useNavigate, useParams} from "react-router-dom";
import theme from "../../../theme";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EventNote from '@mui/icons-material/NoteAltTwoTone';
import moment from "moment/moment";
import Swal from "sweetalert2";
import {TREATMENTS} from "../../../constants/EnumTreatment";
import {noteRedirectTo} from "../../../utils/utils";
import {useAuthContext} from "../../../contexts/auth";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const myStyles = {
    paper: {
        padding: theme.spacing(2),
    },
    icons:{
        fontSize:'60px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        }
    }
};

export default function ViewFormContainer(){
    return (
        <FormContextProvider>
            <DocumentPage />
        </FormContextProvider>
    )
}

function DocumentPage(props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [error, setError] = useState(null);
    const {id, docid} = useParams()
    const { user } = useAuthContext()
    const [openModal, setOpenModal] = useState(false);
    const {appointmentDate, client, elements, handleCompletedForm, isFetching, setSignature, setSignatureDate, signatureDate, signature, treatmentName, idTreatment} = useFormContext();

    useEffect(() => {
        moment(appointmentDate) >= moment() && (
            Swal.fire({
                title: t("form.ModificationNeededTitle"),
                text: "",
                icon: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonColor: theme.palette.primary.main,
                cancelButtonColor: theme.palette.secondary.light,
                denyButtonColor: theme.palette.primary.main,
                confirmButtonText: t("form.EditDocumentBtn"),
                cancelButtonText: t("form.EditCancelBtn"),
                denyButtonText: t('form.ViewDocumentBtn'),
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/center/client/" + id + "/edit/" + docid)
                }else if (result.isDismissed && result.dismiss === 'cancel') {
                    noteRedirectTo(idTreatment, id, navigate)
                }
            })
        )
    }, [isFetching])

    const handleCancel = () => {
        navigate("/center/client/" + id)
    }

    const navigateNote = () => {
        noteRedirectTo(idTreatment, id, navigate)
    }

    const handleModificationClick = () => {
        handleCompletedForm()
        navigate("/center/client/" + id + "/edit/" + docid)
    }

    const handleCloseModal = (completed , signature) => {
        setOpenModal(false)
        if(completed){
            setSignature(signature)
            setSignatureDate(moment())
        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ModalResignDocument open={openModal} handleCloseModal={handleCloseModal} docId={docid} />
            <Grid container alignItems="center">
                <Grid item xs={12} >
                    <Tooltip title={t('form.BackToClientPage')} sx={myStyles.icons} >
                        <ArrowBackIosIcon onClick={() => handleCancel()}/>
                    </Tooltip>
                </Grid>
            </Grid>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
            >
                <div>
                    <DocumentTitle title={`${client?.firstName} ${client?.lastName}`} subtitle={`${t(`treatment.${treatmentName}`)}`} appointmentDate={appointmentDate}/>
                </div>
                <Tooltip title={t('form.SetServiceAppointmentNote')} sx={myStyles.icons} >
                    <EventNote onClick={navigateNote} />
                </Tooltip>
            </Stack>
            {
                moment(appointmentDate) >= moment() && (
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Alert sx={{justifyContent:"left"}} severity="warning">
                                {t('form.ModificationNeeded')}
                                <Link
                                    component="button"
                                    variant="body2"
                                    sx={{paddingLeft:"15px"}}
                                    onClick={handleModificationClick}
                                >{t('form.Edit')}</Link>
                            </Alert>
                        </Grid>
                    </Grid>
                )
            }
            <Grid container alignItems="center" sx={{pt:3}} spacing={1}>
                    {
                        elements ? elements.map((elm, i) => {
                            const {fields, page_label} = elm ?? {};
                            return (
                                <Grid item xs={12} key={page_label}>
                                    <Paper  sx={myStyles.paper} >
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">{t(page_label)}</Typography>
                                            </Grid>
                                            {fields ? fields.map((field, i) => <Element field={field} key={i} viewMode={true}/>) : null}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        }) : null }

                    <Grid container alignItems="center" sx={{pt:5}} spacing={1}>
                        <Grid item xs={12}>
                            <Paper  sx={myStyles.paper} >
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">{t('form.ConsentTitle')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            idTreatment === TREATMENTS.Botox ?
                                                (
                                                    <>
                                                        <Typography variant="h6">{t('form.ConsentBotox', {centerName : user.centerName})}</Typography>
                                                        <Typography variant="h6" sx={{pt:2, pb:2}}>{t('form.Consent2Botox')}</Typography>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Typography variant="h6">{t('form.Consent', {centerName : user.centerName})}</Typography>
                                                        <Typography variant="h6" sx={{pt:2, pb:2}}>{t('form.Consent2')}</Typography>
                                                    </>
                                                )
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Paper  sx={myStyles.paper} >
                                                    <img className="" src={signature}  alt="signature"/>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={2} sx={{textAlign: 'center'}}>
                                        {moment(signatureDate).format('ll')}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        {error?.msg &&
                            <Grid item xs={12}>
                                <span className="errorMsg">{error.msg}</span>
                            </Grid>
                        }
                    </Grid>
                    <Grid container alignItems="center" spacing={1} sx={{pt: 5}}>
                        <Grid item xs={12} lg={3}>
                            <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleCancel}>
                                {t('app.Cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
        </>
    );
}