export const TREATMENTS = {
	Electrolyse : 1,
	EpilationCire : 2,
	EpilationSucre: 3,
	ExtensionCils: 4,
	Massage:5,
	Peeling:6,
	PoseOngles : 7,
	RehaussementCils: 8,
	SoinsVisage:9,
	Pedicure:11,
	Thermocoagulation:12,
	Microneedling : 13,
	MaquillagePermanant: 14,
	Maquillage:15,
	IPL:16,
	Microdermabraison: 17,
	Coifure:18,
	Bronzage:19,
	Manucure:20,
	Botox:21,
}