import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Backdrop, Button, CircularProgress, Container, Grid, Paper, Tooltip, Typography} from '@mui/material';
import {DocumentTitle, Element, Title, ToastMsg, TranslateLink} from "../../index";
import {FormContextNoAuth, FormContextNoAuthProvider, useFormContextNoAuth} from "../../../contexts/FormContextNoAuth";
import theme from "../../../theme";
import {useNavigate, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import 'moment/locale/fr';
import SignaturePad from 'react-signature-canvas'
import moment from "moment/moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const myStyles = {
    paper: {
        padding: theme.spacing(2),
    },
    icons:{
        fontSize:'60px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.primary.dark
        }
    }
};

export default function CrudFormContainer(){
    return (
        <FormContextNoAuthProvider>
            <EditDocumentPageNoAuth />
        </FormContextNoAuthProvider>
    )
}

function EditDocumentPageNoAuth(props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id, docid} = useParams()
    const {appointmentDate, client,completed, elements,errorPage, handleSubmit, isFetching, treatmentName, signature, setSignature} = useFormContextNoAuth();

    useEffect ( () => {
      if (completed) {
          navigate("/document/" + docid + "/completed/" + id)
      }
    }, [completed]);

    const handleCancel = () => {
        Swal.fire({
            title: t("document.CancelEditDocumentTitle"),
            text: t("document.DocumentWontBeSaved"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.main,
            cancelButtonColor: theme.palette.secondary.light,
            confirmButtonText: t("document.CancelEditBtn")
        }).then((result) => {
            if (result.isConfirmed) {
                window.close();
            }
        })
    }

    return (
        <>
            {completed &&
                <ToastMsg open={true} type="success" message={t('app.Success')}/>
            }
            {errorPage?.form &&
                <ToastMsg open={true} type="error" message={errorPage.form}/>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container maxWidth="lg" sx={{pt:5}}>
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <DocumentTitle title={`${client?.firstName} ${client?.lastName}`} subtitle={`${t(`treatment.${treatmentName}`)}`} appointmentDate={appointmentDate} />
                    </Grid>
                    <Grid item xs={2} className="link__translate">
                        <TranslateLink/>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" sx={{pt:3, pb:5}} spacing={1}>
                        {
                            elements ? elements.map((elm, i) => {
                                const {fields, page_label} = elm ?? {};
                                return (
                                    <Grid item xs={12} key={page_label}>
                                        <Paper  sx={myStyles.paper} >
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4">{t(page_label)}</Typography>
                                                </Grid>
                                                {fields ? fields.map((field, i) => <Element field={field} key={i} isNoAuth={true}/>) : null}
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                        }) : null }

                    <Grid container alignItems="center" sx={{pt:5}} spacing={1}>
                        <Grid item xs={12}>
                            <Paper  sx={myStyles.paper} >
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4">{t('form.ConsentTitle')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{pt:2, pb:2}}>{t('form.Consent2')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Paper  sx={myStyles.paper} >
                                                    <SignaturePad canvasProps={{className: 'signatureFormulaire'}}  ref={(ref) => { setSignature(ref) }} />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="secondary" onClick={() => {
                                                    signature.clear();
                                                }}>{t('form.Clear')}</Button>
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                    <Grid item xs={12} md={2} sx={{textAlign: 'center'}}>
                                        {moment().format('ll')}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        {errorPage?.msg &&
                            <Grid item xs={12}>
                                <span className="errorMsg">{errorPage.msg}</span>
                            </Grid>
                        }
                    </Grid>
                    <Grid container alignItems="center" spacing={1} sx={{pt: 5}}>
                        <Grid item xs={12} lg={3}>
                            <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleCancel}>
                                {t('app.Cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={6}/>
                        <Grid item xs={12} lg={3}>
                            <Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isFetching} onClick={() => handleSubmit(signature)}>
                                {!isFetching && t('app.Save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}