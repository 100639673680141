import {createContext, useContext, useEffect, useState} from 'react';
import apiClient from "../services/apiClient";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "./auth";
import Swal from "sweetalert2";
import theme from "../theme";
export const FormExamenPeauContext = createContext(null);

let initialState = null;

export const FormExamenPeauContextProvider = ({children}) => {
    const {t} = useTranslation()
    const {id, idTreatment} = useParams()
    const [errors, setErrors] = useState(null);
    const [errorPage, seterrorPage] = useState(null);
    const [elements, setElements] = useState(null);
    const [form, setForm] = useState({});
    const [section, setSection] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [viewMode, setViewMode] = useState(false)
    const [oldNotes, setOldNotes] = useState([])
    const { user } = useAuthContext()

    useEffect(() => {
        setIsFetching(true);
        seterrorPage(null);
        apiClient.fetchFormExamenPeauNotes().then(({data}) => {
            initialState = JSON.parse(data)
            setElements(JSON.parse(data))
            return apiClient.fetchClientTreatmentExamenPeauNotes(id)
        }).then(({data}) => {
            setOldNotes(data)
            setIsFetching(false)
        }).catch(error => {
            setIsFetching(false)
            setErrors(error.response.data.errors)
        })
    }, [completed]);

    const handleChange = (id, event) => {
        switch (event.target.type) {
            case 'checkbox':
                setForm({...form, [id]: {id: id, value: event.target.checked}  });
                break;
            case 'datepicker':
                setForm({...form, [id]: {id: id, value: event.target.value}});
                break;
            default:
                setForm({...form, [id]: {id: id, value: event.target.value}});
                break;
        }
    }

    const formHasSectionError = () => {
        const found = section.find(element => element.error === true);
        return !!found;
    }

    const handleFormSection = (elements) => {
        let checkboxSection=[];
        elements.map(function(element){
            let f;
            element.fields.forEach(function(field, index){
                if (field.field_type === 'checkbox') {
                    checkboxSection.push({
                        id: field.field_id,
                        group: field.field_group,
                        error:true,
                    });
                }
            });
        });
        setSection(checkboxSection);
    }

    const handleSectionError = (id, value) => {
        const checkBoxSection = [...section];
        let pIndex;
        checkBoxSection.map(function(element, index){
            pIndex = index;
            element.group.some(function(e, i){
                if (e.field_id === id) {
                    checkBoxSection[pIndex].error = !value;
                }
            });
        });
        setSection(section);
    }

    const handleCompletedForm = () =>{
        setCompleted(true);
        setForm({});
        setElements(initialState);
    }

    const handleSubmit = () => {
        setIsFetching(true);
        const newForm = {...form};
        let newElements = [...elements];
        if (!formHasSectionError()){
            Object.entries(newForm).map(item => {
                const {id, value} = item[1];
                newElements.map(element => {
                    element.fields.map(field => {
                        if (field.field_id === id) {
                            field['field_value'] = value;
                            return true;
                        }
                    })
                });
            })
            setElements(newElements)
            apiClient.addClientExamenPeauNote(id, newElements, idTreatment, user.id).then(({data}) => {
                if (data !== null){
                    handleCompletedForm();
                    setIsFetching(false);
                }else{
                    setErrors({msg: t('app.errorPage')});
                    setIsFetching(false);
                }
            }).catch(error => {
                setErrors({msg: t('app.Error')});
                setIsFetching(false);
            })
        }else{
            setErrors({msg: t('form.HasErrors')});
            setIsFetching(false);
        }
    }

    const handleViewMode = (value) => {
        setViewMode(value);
    }

    const handleCrudOldNotes = (idNote, isReadOnly) => {
        setIsFetching(true)
        apiClient.fetchOldExamenPeauNote(idNote).then(({data}) => {
            setViewMode(isReadOnly);
            setElements([data])
        }).catch(error => {
            setErrors({msg: t('app.Error')});
        })
        setIsFetching(false)
    }

    const handleResetNotes = () => {
        Swal.fire({
            title: t("examenPeauNotes.ResetNotesTitle"),
            text: t("examenPeauNotes.ResetNotesText"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.main,
            cancelButtonColor: theme.palette.secondary.light,
            confirmButtonText: t("examenPeauNotes.ResetNotesConfirmButton"),
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload(true);
            }
        })
    }

    const formValue = {
        completed,
        errors,
        setErrors,
        errorPage,
        elements,
        setElements,
        formHasSectionError,
        isFetching,
        setIsFetching,
        oldNotes,
        viewMode,
        handleChange,
        handleCompletedForm,
        handleCrudOldNotes,
        handleFormSection,
        handleResetNotes,
        handleSectionError,
        handleSubmit,
        handleViewMode
    }

    return (
        <FormExamenPeauContext.Provider value={formValue}>
            {children}
        </FormExamenPeauContext.Provider>
    )
}

export const useFormExamenPeauContext = () => useContext(FormExamenPeauContext);