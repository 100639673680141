import React, {useState} from 'react';
import {useAuthContext} from "../../contexts/auth";
import {
	AppBar, Badge,
	Box,
	IconButton,
	InputBase,
	Toolbar,
	Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import styled from '@emotion/styled';
import {FollowUpDrawer, Search, SideMenu, TranslateLink} from 'components';
import {useFollowUpContext} from "../../contexts/FollowUpContext";
import followUpIcon from "../../asset/images/followup.png";

const drawerWidth = 240;

const SiteAppBar = styled(AppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));


function Header(props) {
	const { user, setUser } = useAuthContext()
	const { followUpListAmount } = useFollowUpContext()
	const [ open, setOpen ] = useState(false);
	const [ followUpDrawerOpen, setFollowUpDrawerOpen ] = useState(false);

	function handleDrawer() {
		setOpen(!open);
	}

	const handleDrawerFollowUp = () => {
		setFollowUpDrawerOpen(!followUpDrawerOpen)
	}

	return (
		<>
			<SiteAppBar position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawer}
						edge="start">
						<MenuIcon />
					</IconButton>
					<Box>
						<Typography variant="h4" sx={{ display: { xs: 'none', sm: 'block' } }}>{user.centerName}</Typography>
					</Box>
					<Box sx={{flexGrow: 3}}>
						<Search />
					</Box>
					<Box sx={{ display: { xs: 'none', sm: 'flex' }, flexGrow: 1, justifyContent: 'flex-end' }}>
						<IconButton size="large" aria-label="show" color="inherit" onClick={handleDrawerFollowUp}>
							<Badge badgeContent={followUpListAmount}>
								<img src={followUpIcon} alt="Suivi" className=''/>
							</Badge>
						</IconButton>
					</Box>
					<TranslateLink />
				</Toolbar>
			</SiteAppBar>
			<SideMenu open={open} handleDrawer={handleDrawer}/>
			<FollowUpDrawer open={followUpDrawerOpen} handleDrawer={handleDrawerFollowUp} />
		</>

	);
}

export default Header;