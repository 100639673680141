import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import {Box, Button, CircularProgress, Grid, Modal, Stack, Typography} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {myStyles, Title} from "components";
import {BaseTextField} from "../textfield/StyledTextField";
import apiClient from "../../services/apiClient";
import {useParams} from "react-router-dom";
import moment from "moment";

function ModalNoteElectrolyse({open, handleClose, noteExamenPeau}) {
    const {t} = useTranslation()
	const [note, setNote] = useState()

	useEffect( () => {
	    setNote(noteExamenPeau)
    }, [noteExamenPeau])

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-note-examen-peau"
				aria-describedby="modal-modal-description-note-examen-peau"
			>
				<Box sx={myStyles.smallBoxModal}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={2}
					>
						<Title balise={'h4'} title={t('electrolyseNotes.NoteModalTitle')} subtitle='' forgetLabel={false}/>
						<HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
					</Stack>
					<Grid container alignItems="center" sx={{pt:5}}>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("electrolyseNotes.Region")} : </Typography>
							<Typography display="inline" variant="body1">{moment(note?.date).format('LL')}</Typography>
						</Grid>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("electrolyseNotes.Time")} : </Typography>
							<Typography display="inline" variant="body1">{note?.time}</Typography>
						</Grid>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("electrolyseNotes.Area")} : </Typography>
							<Typography display="inline" variant="body1">{note?.area}</Typography>
						</Grid>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("electrolyseNotes.Technique")} : </Typography>
							<Typography display="inline" variant="body1">{note?.technique}</Typography>
						</Grid>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("electrolyseNotes.Parameter")} : </Typography>
							<Typography display="inline" variant="body1">{note?.parameters}</Typography>
						</Grid>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("electrolyseNotes.Comments")} : </Typography>
							<Typography display="inline" variant="body1">{note?.comments}</Typography>
						</Grid>
						<Grid item xs={12} >
							<Typography display="inline" variant="body1" sx={myStyles.label}>{t("electrolyseNotes.Employee")} : </Typography>
							<Typography display="inline" variant="body1">{note?.employeeName}</Typography>
						</Grid>
						<Grid item xs={12} lg={4} sx={{pt:3}}>
							<Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={handleClose}>
								{t('app.Close')}
							</Button>
						</Grid>
					</Grid>

				</Box>
			</Modal>

		</>
	);
 }

export default ModalNoteElectrolyse;