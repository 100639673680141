import React, {useContext, useEffect, useState} from 'react'
import {FormContext} from '../../../contexts/FormContext'
import {FormContextNoAuth} from '../../../contexts/FormContextNoAuth'

import {BaseTextField} from '../../textfield/StyledTextField';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FormExamenPeauContext} from "../../../contexts/FormExamenPeauContext";


const FormInput = ({field_id, field_label, field_placeholder, field_value, field_attributes, field_mandatory, viewMode, isNoAuth, isExamenPeau}) => {
	const {t} = useTranslation();
	const { handleChange } = useContext(isNoAuth ? FormContextNoAuth : !isExamenPeau ? FormContext : FormExamenPeauContext);
	const [value, setValue] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const xs = field_attributes?.find(element => element.xs)?.xs ? field_attributes?.find(element => element.xs).xs : 12
	const md = field_attributes?.find(element => element.md)?.md ? field_attributes?.find(element => element.md).md : 12

	useEffect(() => {
		setValue(field_value ? field_value : '')
		setIsDisabled(viewMode);
	}, [viewMode, field_value])

	const handleChangeInput = (event) => {
		setValue(event.target.value);
		handleChange(field_id, event);
	};

	return (
		<Grid item xs={xs} md={md}>
			<BaseTextField
				label={t(field_label)}
				id={field_id}
				required = {field_mandatory ? field_mandatory : false}
				fullWidth
				disabled = {isDisabled}
				autoFocus = {!!field_attributes?.find(element => element.autofocus === true)}
				variant="outlined"
				placeholder={field_placeholder ? t(field_placeholder) : ''}
				onChange={handleChangeInput}
				value={value}
			/>
		</Grid>
	)
}

export default FormInput
