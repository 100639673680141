import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {CircularProgress, Grid, Stack, Tooltip, Typography} from '@mui/material';
import {ListClientFollowUp,Title, ToastMsg, myStyles} from 'components';
import apiClient from "../../services/apiClient";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useFollowUpContext} from "../../contexts/FollowUpContext";

function ClientFollowUpSection({client}) {
    const {t} = useTranslation()
	const [errors, setErrors] = useState({})
	const [isFetching, setIsFetching] = useState(false)
	const [followUp, setFollowUp] = useState([])
	const { followUpCompleted, handleAddFollowUpClick } = useFollowUpContext();

	useEffect( () => {
		if (client.id){
			setIsFetching(true)
			apiClient.fetchClientAppointmentFollowUp(client.id)
				.then(({data}) => {
					setIsFetching(false)
					setFollowUp(data)
				})
				.catch(error => {
					setIsFetching(false)
					setErrors({msg: error.response.data.errors})
				}
			)
		}
	}, [client.id, followUpCompleted])

	return (
		<>
			{isFetching && <CircularProgress color="secondary" size={40}/>}
			{errors?.msg &&
			    <ToastMsg open={true} type='error' message={t('app.Error')}/>}
			{!isFetching && (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="flex-start"
								spacing={2}
							>
								<Typography variant="h3">{t('followUp.ClientFollowUpTitle')}</Typography>
								<Tooltip title={t('followUp.AjouterClientFollowUpTitle')} sx={myStyles.icons} >
									<AddCircleOutlineIcon onClick={() => handleAddFollowUpClick(client)}/>
								</Tooltip>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<ListClientFollowUp followUp={followUp} />
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
 }

export default ClientFollowUpSection;