import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import apiClient from "../../services/apiClient";
import {useAuthContext} from "../../contexts/auth";
import {Backdrop, CircularProgress, Grid, Typography} from "@mui/material";
import listPlugin from '@fullcalendar/list';
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CircleIcon from "@mui/icons-material/Circle";
import {ModalAppointment, ToastMsg} from "../index";
import Swal from "sweetalert2";
import theme from "../../theme";

function DashboardAppointmentList(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const {user, initialized} = useAuthContext()
    const [isFetching, setIsFetching] = useState(false)
    const [centerAppointmentList, setCenterAppointmentList] = useState([]);
    const [appointmentInfos, setAppointmentInfos] = useState({});
    const [modalOpenAppointment, setModalOpenAppointment] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [ isSend, setIsSend ] = useState(false)
    const [ clientList, setClientList ] = useState([])

    useEffect(() => {
        setIsFetching(true)
        apiClient.fetchCenterAppointmentList(user.centerId).then(({data, error}) => {
            if (error){
                setErrors({msg: error})
            }else{
                setCenterAppointmentList(data)
                return apiClient.fetchCenterClientList(user.centerId)
            }
        }).then(({data}) => {
            setClientList(makeLabelFromList(data))
        }).catch(error => {
            setErrors({msg: error})
        })
        setIsFetching(false)
    }, [isUpdated])

    const makeLabelFromList = (list) => {
        const options = [];
        list && list.forEach(function(option) {
            options.push({ label: option.firstName + ' ' + option.lastName, id: option.id })
        });
        return options
    }

    const handleAppointmentClick = (appointment) => {
        setAppointmentInfos({
            id: appointment.event.id,
            start: appointment.event.start,
            end: appointment.event.end,
            idClient: appointment.event.extendedProps.idClient,
            idDocument: appointment.event.extendedProps.idDocument,
            clientName: appointment.event.extendedProps.clientName,
            treatmentId: appointment.event.extendedProps.treatmentId,
            note: appointment.event.extendedProps.note,
        });
        setModalOpenAppointment(true);
    }

    const handleCloseModal = () => {
        setAppointmentInfos({});
        setModalOpenAppointment(false);
    }

    const handleUpdate = (isFirstTimeAppointment = false, appointment = null) => {
        setIsUpdated(true);
        handleCloseModal();
        if (isFirstTimeAppointment){
            Swal.fire({
                title: t('bilan.AppointmentFirstTimeTitle'),
                text: t('bilan.AppointmentFirstTimeText'),
                showCancelButton: true,
                confirmButtonText: t('bilan.SendDocumentToFillOut'),
                confirmButtonColor: theme.palette.primary.main,
                cancelButtonColor: theme.palette.secondary.light,
            }).then((result) => {
                if (result.isConfirmed) {
                    apiClient.SendDocumentToFillOutEmailFromCalendar(appointment)
                        .then(() => {
                            setIsSend(true)
                        })
                }}
            )
        }
    }

    const renderEventContent = (eventInfo: EventContentArg) => {
        return (
            <Grid container spacing={0} sx={{cursor:'pointer'}}>
                <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontSize:'1.2rem', wordWrap: "break-word", whiteSpace: "pre-line" }}>
                        {eventInfo.event.title} - {t(`treatment.${eventInfo.event.extendedProps.treatmentName}`)}
                    </Typography>
                </Grid>
            </Grid>
        )
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            {isUpdated && <ToastMsg open={isUpdated} type='success' message={t('appointmentPage.Success')}/>}
            {isSend && <ToastMsg open={isSend} type='success' message={t('appointmentPage.SuccessSend')}/>}
            <ModalAppointment open={modalOpenAppointment} handleCloseModal={handleCloseModal} appointmentInfos={appointmentInfos} handleUpdate={handleUpdate} clientList={clientList}/>
            <FullCalendar
                plugins={[listPlugin  ]}
                initialView="listDay"
                locale= 'fr'
                events={centerAppointmentList}
                noEventsContent={t('appointment.NoAppointment')}
                listDayFormat={false}
                headerToolbar={{
                    left: "",
                    center: "",
                    right: ""
                }}
                contentHeight={"auto"}
                backgroundColor={(e) => e.backgroundColor}
                eventClick={(e) => handleAppointmentClick(e)}
                eventContent={renderEventContent}
            />
        </>
    );
}

export default DashboardAppointmentList;