import React, {useContext, useState} from 'react'
import {FormContext} from '../../../contexts/FormContext'
import {FormContextNoAuth} from '../../../contexts/FormContextNoAuth'

import {BaseTextField} from '../../textfield/StyledTextField';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";


const FormTextArea = ({field_id, field_label, field_placeholder, field_value, field_attributes, field_mandatory, viewMode, isNoAuth}) => {
    const {t} = useTranslation();
    const { handleChange } = useContext(isNoAuth ? FormContextNoAuth : FormContext);
    const [value, setValue] = useState(field_value ? field_value : '');
    const [isDisabled, setIsDisabled] = useState(viewMode);
    const xs = field_attributes?.find(element => element.xs)?.xs ? field_attributes?.find(element => element.xs).xs : 12
    const md = field_attributes?.find(element => element.md)?.md ? field_attributes?.find(element => element.md).md : 6

    const handleChangeInput = (event) => {
        setValue(event.target.value);
        handleChange(field_id, event);
    };

    return (
        <Grid item xs={xs} md={md}>
            <BaseTextField
                label={t(field_label)}
                id={field_id}
                required = {field_mandatory ? field_mandatory : false}
                disabled = {isDisabled}
                fullWidth
                multiline={true}
                minRows="5"
                autoFocus = {!!field_attributes?.find(element => element.autofocus === true)}
                variant="outlined"
                placeholder={field_placeholder ? t(field_placeholder) : ''}
                onChange={handleChangeInput}
                value={value}
            />
        </Grid>
    )
}

export default FormTextArea
