import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseTextField} from "../textfield/StyledTextField";
import {Button, CircularProgress, Grid} from "@mui/material";
import apiClient from "../../services/apiClient";
import {ToastMsg} from "../index";
import {useParams} from "react-router-dom";
import {useAuthContext} from "../../contexts/auth";

const initialState = {
    area:'',
    parameters:'',
    time:0,
    technique:'',
    comments:''
}

function ElectroNoteForm({handleUpdate, idTreatment}) {
    const {t} = useTranslation();
    const {id} = useParams()
    const [errors, setErrors] = useState({});
    const [isProcessing, setIsProcessing] = useState(false)
    const [note, setNote] = useState(initialState);
    const [addedNote, setAddedNote] = useState(false);
    const { user } = useAuthContext()

    const handleChange = (element) => {
        setErrors({...errors, msgServer: null});
        if ( errors.hasOwnProperty(element.target.name) ) {
            setErrors((e) => ({ ...e, [element.target.name]: null }))
        }
        setNote({ ...note, [element.target.name]: element.target.value });
    }

    const clearField = () => {
        setNote(initialState);
    }

    const submitHandler = () => {
        const requiredFields = [
            'region',
            'parameters',
            'time',
            'technique'];
        let hasError = false;
        setIsProcessing(true)
        setErrors((e) => ({...e, note: null}))

        Object.entries(note).map(field => {
            if (requiredFields.indexOf(field[0]) !== -1 && field[1] === '') {
                setErrors(
                    (e) => ({...e, [field[0]]: "app.RequiredFields"}))
                hasError = true
            }
        })

        if (!hasError) {
            apiClient.addElectroAppointmentNote(id, user.id, note, idTreatment).then(({data}) => {
                setIsProcessing(false)
                setAddedNote(true)
                setNote(initialState)
                handleUpdate()
            }).catch(error => {
                setIsProcessing(false)
                setErrors({msg : error.response.data.errors})
            })
        }
    }

    return (
        <>
            <Grid container spacing={1} sx={{pt: 2, pb:2}}>
                <Grid item xs={12}>
                    {errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
                    {addedNote && <ToastMsg open={true} type='success' message={t('app.SaveSuccess')}/>}
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <BaseTextField
                        label={t('electrolyseNotes.Region')}
                        required
                        fullWidth
                        autoFocus
                        variant="outlined"
                        name="area"
                        onChange={handleChange}
                        value={note.area}
                        error={errors.area && true}
                        helperText={errors.area && t(errors.area) }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <BaseTextField
                        label={t('electrolyseNotes.Time')}
                        required
                        fullWidth
                        autoFocus
                        variant="outlined"
                        name="time"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 180 } }}
                        onChange={handleChange}
                        value={note.time}
                        error={errors.time && true}
                        helperText={errors.time && t(errors.time) }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <BaseTextField
                        label={t('electrolyseNotes.Technique')}
                        required
                        fullWidth
                        autoFocus
                        variant="outlined"
                        name="technique"
                        onChange={handleChange}
                        value={note.technique}
                        error={errors.technique && true}
                        helperText={errors.technique && t(errors.technique) }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <BaseTextField
                        label={t('electrolyseNotes.Parameter')}
                        required
                        fullWidth
                        autoFocus
                        variant="outlined"
                        name="parameters"
                        onChange={handleChange}
                        value={note.parameters}
                        error={errors.parameters && true}
                        helperText={errors.parameters && t(errors.parameters) }
                    />
                </Grid>
                <Grid item xs={12}>
                    <BaseTextField
                        label={t('electrolyseNotes.Comments')}
                        required
                        fullWidth
                        autoFocus
                        multiline={true}
                        minRows="5"
                        variant="outlined"
                        name="comments"
                        onChange={handleChange}
                        value={note.comments}
                        error={errors.comments && true}
                        helperText={errors.comments && t(errors.comments) }
                    />
                </Grid>
                <Grid item xs={12} lg={6}/>
                <Grid item xs={12} lg={3}>
                    <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={clearField}>
                        {t('app.Clear')}
                    </Button>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isProcessing} onClick={submitHandler}>
                        {isProcessing && <CircularProgress color="secondary"  size={20} />}
                        {!isProcessing && t('app.Save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default ElectroNoteForm;