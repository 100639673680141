import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseTextField} from "../textfield/StyledTextField";
import {Button, CircularProgress, Grid, Tooltip} from "@mui/material";
import apiClient from "../../services/apiClient";
import {myStyles, ToastMsg} from "components";
import {useParams} from "react-router-dom";
import {useAuthContext} from "../../contexts/auth";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const initialState = [{
    id: 0,
    area:'',
    nbUnit:'',
    technique:'',
    products:'',
    notes:'',
}]

function BotoxNoteForm({handleUpdate}) {
    const {t} = useTranslation();
    const {id} = useParams()
    const [errors, setErrors] = useState({});
    const [isProcessing, setIsProcessing] = useState(false)
    const [notes, setNotes] = useState(initialState);
    const [addedNote, setAddedNote] = useState(false);
    const { user } = useAuthContext()

    const handleChangeInput = (id, event) => {
        setErrors({});
        const newInputFields = notes.map(i => {
            if(id === i.id) {
                i[event.target.name] = event.target.value
            }
            return i;
        })
        setNotes(newInputFields);
    }

    const handleAddFields = () => {
        setNotes([...notes, {
            id: notes.length + 1,
            area:'',
            nbUnit:'',
            technique:'',
            products:'',
            notes:''
        }])
    }

    const handleRemoveFields = id => {
        const values  = [...notes];
        values.splice(values.findIndex(value => value.id === id), 1);
        setNotes(values);
    }

    const clearField = () => {
        setNotes(initialState);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const requiredFields = [
            'area',
            'nbUnit',
            'technique',
            'products',
            'notes'];
        let hasError = false;
        setIsProcessing(true)
        notes.map(note => {
            Object.entries(note).map(field => {
                if (requiredFields.indexOf(field[0]) !== -1 && field[1] === '') {
                    setErrors(
                        (e) => ({...e, [field[0]]: "app.RequiredFields"}))
                    hasError = true
                }
            })
        })

        if (!hasError) {
            apiClient.addBotoxAppointmentNote(id, user.id, notes).then(() => {
                setIsProcessing(false)
                setAddedNote(true)
                setNotes([{
                    id: 0,
                    area:'',
                    nbUnit:'',
                    technique:'',
                    products:'',
                    notes:'',
                }])
                handleUpdate()
            }).catch(error => {
                setIsProcessing(false)
                setErrors({msg : true})
            })
        }
    }

    return (
        <>
            <Grid container spacing={1} sx={{pt: 2, pb:2}}>
                <Grid item xs={12}>
                    {errors?.msg && <ToastMsg open={true} type='error' message={t('app.Error')}/>}
                    {addedNote && <ToastMsg open={true} type='success' message={t('app.SaveSuccess')}/>}
                </Grid>
                { notes.map(inputField => (
                    <>
                        <Grid container spacing={1} key={inputField.id} >
                            <Grid item xs={12} md={6} lg={3}>
                                <BaseTextField
                                    label={t('electrolyseNotes.Region')}
                                    required
                                    fullWidth
                                    autoFocus
                                    variant="outlined"
                                    name="area"
                                    onChange={(e) => handleChangeInput(inputField.id, e)}
                                    value={inputField.area}
                                    error={errors?.area && true}
                                    helperText={errors?.area && t('app.RequiredFields') }
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <BaseTextField
                                    label={t('electrolyseNotes.NbUnit')}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    name="nbUnit"
                                    onChange={(e) => handleChangeInput(inputField.id, e)}
                                    value={inputField.nbUnit}
                                    error={errors?.nbUnit && true}
                                    helperText={errors?.nbUnit && t('app.RequiredFields') }
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <BaseTextField
                                    label={t('electrolyseNotes.Technique')}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    name="technique"
                                    onChange={(e) => handleChangeInput(inputField.id, e)}
                                    value={inputField.technique}
                                    error={errors?.technique && true}
                                    helperText={errors?.technique && t('app.RequiredFields') }
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <BaseTextField
                                    label={t('electrolyseNotes.Products')}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    name="products"
                                    onChange={(e) => handleChangeInput(inputField.id, e)}
                                    value={inputField.products}
                                    error={errors?.products && true}
                                    helperText={errors?.products && t('app.RequiredFields') }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BaseTextField
                                    label={t('electrolyseNotes.Notes')}
                                    required
                                    fullWidth
                                    multiline={true}
                                    minRows="3"
                                    variant="outlined"
                                    name="notes"
                                    onChange={(e) => handleChangeInput(inputField.id, e)}
                                    value={inputField.notes}
                                    error={errors?.notes && true}
                                    helperText={errors?.notes && t('app.RequiredFields') }
                                />
                            </Grid>
                            <Grid item xs={12} lg={9}/>
                            <Grid item xs={12} lg={3} sx={{textAlign:'right'}}>
                                <Tooltip title={t('electrolyseNotes.Add')} sx={myStyles.smallIcons}  >
                                    <RemoveIcon   onClick={() => { notes.length > 1 && handleRemoveFields(inputField.id)}}/>
                                </Tooltip>
                                <Tooltip title={t('electrolyseNotes.Remove')} sx={myStyles.smallIcons} >
                                    <AddIcon onClick={() => handleAddFields()}/>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
                ))}
                <Grid item xs={12} lg={6}/>
                <Grid item xs={12} lg={3}>
                    <Button fullWidth variant="contained" color="secondary" size="large" type="submit" onClick={clearField}>
                        {t('app.Clear')}
                    </Button>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Button fullWidth variant="contained" color="primary" size="large" type="submit" disabled={isProcessing} onClick={submitHandler}>
                        {isProcessing && <CircularProgress color="secondary"  size={20} />}
                        {!isProcessing && t('app.Save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default BotoxNoteForm;