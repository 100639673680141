import React from 'react';
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";

const FormLabel = ({field_label, field_attributes}) => {
    const {t} = useTranslation()

    const xs = field_attributes?.find(element => element.xs)?.xs ? field_attributes?.find(element => element.xs).xs : 12
    const md = field_attributes?.find(element => element.md)?.md ? field_attributes?.find(element => element.md).md : 12
    const lg = field_attributes?.find(element => element.lg)?.lg ? field_attributes?.find(element => element.lg).lg : 12

    const htmlTitle = field_attributes?.find(element => element.title)?.title ? field_attributes?.find(element => element.title).title : 'body2'
    const style = field_attributes?.find(element => element.fontStyle)?.fontStyle ? field_attributes?.find(element => element.fontStyle).fontStyle : 'normal'
    const weight = field_attributes?.find(element => element.fontWeight)?.fontWeight ? field_attributes?.find(element => element.fontWeight).fontWeight : '400'

    return (
        <Grid item xs={xs} md={md} lg={lg}>
            <Typography variant={htmlTitle} sx={{ fontStyle : style, fontWeight: weight }}>{t(field_label)}</Typography>
        </Grid>
    )
}


export default FormLabel;