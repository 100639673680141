import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Box,
    Button, CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select, Stack
} from "@mui/material";
import {myStyles, Title, ToastMsg} from "components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import apiClient from "../../services/apiClient";
import {placeDollars} from "../../utils/utils";
import {useAuthContext} from "../../contexts/auth";

function ModalAddSubscriptionClientAddon({open, setOpen, addedClientRecord}) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [addonsOptions, setAddonsOptions] = useState([]);
    const [selectedAddon, setSelectedAddon] = useState(null);
    const {user} = useAuthContext();

    useEffect(() => {
        setIsProcessing(true);
        setErrors({});
        apiClient.fetchCenterClientAddonsAvailable().then(({data}) => {
            setAddonsOptions(data);
        }).catch(error => {
            setErrors({...errors, server: true})
        })
        setIsProcessing(false);
    }, []);

    const handleClose = () => {
        setSelectedAddon(null);
        setErrors({});
        setOpen(false);
    };

    const handleAddAddon = () => {
        setIsProcessing(true);
        setErrors({});
        apiClient.submitAddonPayment(user.centerId, selectedAddon.id).then(({data}) => {
            if (data) {
                window.location.href = data.url
            }else{
                setErrors((e) => ({...e, msg: "app.ErrorStripe"}))
            }
        }).catch(error => {
            setErrors((e) => ({...e, msg: "app.ErrorStripe"}))
        });
        // .then(({data}) => {
        //     setIsProcessing(false);
        //     addedClientRecord();
        // }).catch(error => {
        //     setIsProcessing(false);
        //     setErrors({...errors, server: true})
        // })
    };

    return (
        <>
            {errors?.server && <ToastMsg open={true} type='error' message={t('centerPage.ErrorHappenRetry')}/>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={myStyles.smallBoxModal}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Title balise="h4" title='centerAddon.AddClientCard' subtitle='' forgetLabel={false}/>
                        <HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
                    </Stack>
                    <Grid container spacing={2} sx={{pt:3}}>
                        <Grid item xs={12}>
                            <FormControl fullWidth autoFocus required>
                                    <InputLabel htmlFor="demo-simple-select">{t('centerAddon.AddClientCardLabel')}</InputLabel>
                                    <Select
                                        id="simple-select"
                                        value={selectedAddon?.id ? selectedAddon?.id : ''}
                                        label="Service *"
                                        error={errors.id && true}
                                        onChange={(element) => {
                                            setSelectedAddon({...selectedAddon, id: element.target.value});
                                        }}
                                    >
                                        {addonsOptions.map( (item, keyIndex) =>
                                            <MenuItem  key={keyIndex} value={item.id}>{t(`centerAddon.${item.tierAddonName}`) + " " + placeDollars(item.addOnPrice)}</MenuItem>
                                        )}
                                    </Select>
                                    {errors.id && <FormHelperText className="errorMsg"> {t(errors.id)}</FormHelperText>}
                                </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Button fullWidth variant="contained" color="secondary" size="large" type="submit" disabled={isProcessing} onClick={handleClose}>
                                {t('app.Close')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={4}/>
                        <Grid item xs={12} lg={4}>
                            <Button fullWidth variant="contained" color="primary" size="large" type="submit" onClick={handleAddAddon}>
                                {isProcessing && <CircularProgress color="secondary"  size={20} />}
                                {!isProcessing && t('app.Save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}

export default ModalAddSubscriptionClientAddon;