const NumberFormat = require("react-number-format");
const {TREATMENTS} = require("../constants/EnumTreatment");
const {useNavigate} = require("react-router-dom");

const validEmail = (email) => {
	if (email.indexOf("@") === -1) {
		return {valid: false, error: "app.ValidEmailError" }
	} else {
		return {valid: true, error: null }
	}
}

const placeDollars = (element) => {
	const currentLanguage = localStorage.getItem("i18nextLng") || "fr"
	const price = Number(element).toFixed(2)
	return currentLanguage === 'fr' ? "$" + price : price + "$"
}

const noteRedirectTo = (idTreatment, id, navigate) => {
	switch (idTreatment){
		case TREATMENTS.Electrolyse:
			navigate("/center/client/" + id + "/appointmentnotes/electrolyse/")
			break;
		case TREATMENTS.IPL:
			navigate("/center/client/" + id + "/appointmentnotes/ipl/")
			break;
		case TREATMENTS.Thermocoagulation:
			navigate("/center/client/" + id + "/appointmentnotes/thermocoagulation/")
			break;
		case TREATMENTS.SoinsVisage:
			navigate("/center/client/" + id + "/appointmentnotes/examenpeau/"+ idTreatment)
			break;
		case TREATMENTS.Peeling:
			navigate("/center/client/" + id + "/appointmentnotes/examenpeau/"+ idTreatment)
			break;
		case TREATMENTS.Botox:
			navigate("/center/client/" + id + "/appointmentnotes/botox/")
			break;
		default:
			navigate("/center/client/" + id + "/appointmentnotes/" + idTreatment)
			break;
	}
}

module.exports = {
	placeDollars,
	noteRedirectTo,
	validEmail,
}