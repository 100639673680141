import React, {useState} from 'react';
import theme from "../../theme";
import {useTranslation} from "react-i18next";
import {Box, Button, Divider, Grid, Modal, Stack, Typography} from "@mui/material";
import {myStyles, Title} from "components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment/moment";

function ModalViewFollowUp({followUp,  handleClose, handleComplete, open,  switchToEditMode}) {
    const {t} = useTranslation()

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-follow-up-appointment"
                aria-describedby="modal-modal-follow-up-description"
            >
                <Box sx={myStyles.smallBoxModal}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Title balise={'h4'} title={t('followUp.ClientFollowUpTitle')}/>
                        <HighlightOffIcon sx={myStyles.icons} onClick={handleClose}/>
                    </Stack>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12} sx={{pt: '10px', pb: '10px'}}>
                            <Typography variant="h5" sx={{fontWeight:'bold'}}>{followUp.clientName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography display="inline" variant="body1" sx={{fontWeight:'bold'}}>{t('followUp.Date')} : </Typography><Typography display="inline" variant="body1">{moment(followUp.dateToFollowUP).format('LL')}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{pt: '10px', pb: '10px'}}>
                            <Typography variant="body1" sx={{fontWeight:'bold'}}>{t('followUp.Description')}</Typography>
                            <Typography variant="body1">{followUp.note}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        {
                            !followUp.isCompleted && (
                                <Grid container sx={{pt:1}}>
                                    <Grid item xs={4}>
                                        <Button fullWidth variant="contained" color="primary" size="large" type="submit"
                                                onClick={switchToEditMode}>
                                            {t('followUp.EditFollowUp')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <Button fullWidth variant="contained" color="primary" size="large" type="submit" onClick={handleComplete}>
                                            {t('followUp.CompleteFollowUp')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}

export default ModalViewFollowUp;