import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Divider, List, ListItem, ListItemText, Paper, Typography} from "@mui/material";

const styles = {
    list: {
        flexDirection: 'column',
        alignContent: 'flex-start',
        alignItems: 'flex-start'
    }
}

function Recommandations({idTreatment}) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});

    const showRecommandations = () => {
        switch (idTreatment) {
            case 1:
                return (
                    <>
                        <Typography variant='h6'>{t('recommandations.BeforeTreatment')}</Typography>
                        <List dense={false}>
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.electrolyse.BeforeTreatment1')} />
                                <ListItemText primary={t('recommandations.electrolyse.BeforeTreatment2')} />
                                <ListItemText primary={t('recommandations.electrolyse.BeforeTreatment3')} />
                                <ListItemText primary={t('recommandations.electrolyse.BeforeTreatment4')} />
                            </ListItem>
                        </List>
                        <Divider sx={{pb:3}}/>
                        <Typography variant='h6'>{t('recommandations.AfterTreatment')}</Typography>
                        <List dense={false} >
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment1')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment2')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment3')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment4')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment5')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment6')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment7')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment8')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment9')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment10')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatmen11')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment12')} />
                                <ListItemText primary={t('recommandations.electrolyse.AfterTreatment13')} />
                            </ListItem>
                        </List>
                    </>
                )
            case 2:
            case 3:
                return (
                    <>
                        <Typography variant='h6'>{t('recommandations.BeforeTreatment')}</Typography>
                        <List dense={false}>
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.epilation.BeforeTreatment1')} />
                                <ListItemText primary={t('recommandations.epilation.BeforeTreatment2')} />
                            </ListItem>
                        </List>
                        <Divider sx={{pb:3}}/>
                        <Typography variant='h6'>{t('recommandations.AfterTreatment')}</Typography>
                        <List dense={false} >
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.epilation.AfterTreatment1')} />
                                <ListItemText primary={t('recommandations.epilation.AfterTreatment2')} />
                                <ListItemText primary={t('recommandations.epilation.AfterTreatment3')} />
                                <ListItemText primary={t('recommandations.epilation.AfterTreatment4')} />
                                <ListItemText primary={t('recommandations.epilation.AfterTreatment5')} />
                                <ListItemText primary={t('recommandations.epilation.AfterTreatment6')} />
                                <ListItemText primary={t('recommandations.epilation.AfterTreatment7')} />
                            </ListItem>
                        </List>
                    </>
                )
            case 4:
                return (
                    <>
                        <Typography variant='h6'>{t('recommandations.BeforeTreatment')}</Typography>
                        <List dense={false}>
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.extensionCils.BeforeTreatment1')} />
                            </ListItem>
                        </List>
                        <Divider sx={{pb:3}}/>
                        <Typography variant='h6'>{t('recommandations.AfterTreatment')}</Typography>
                        <List dense={false} >
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment1')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment2')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment3')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment4')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment5')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment6')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment7')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment8')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment9')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment10')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment11')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment12')} />
                                <ListItemText primary={t('recommandations.extensionCils.AfterTreatment13')} />
                            </ListItem>
                        </List>
                    </>
                )
            case 6:
                return (
                    <>
                        <Typography variant='h6'>{t('recommandations.BeforeTreatment')}</Typography>
                        <List dense={false}>
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.peeling.BeforeTreatment1')} />
                                <ListItemText primary={t('recommandations.peeling.BeforeTreatment2')} />
                            </ListItem>
                        </List>
                        <Divider sx={{pb:3}}/>
                        <Typography variant='h6'>{t('recommandations.AfterTreatment')}</Typography>
                        <List dense={false} >
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.peeling.AfterTreatment1')} />
                                <ListItemText primary={t('recommandations.peeling.AfterTreatment2')} />
                                <ListItemText primary={t('recommandations.peeling.AfterTreatment3')} />
                            </ListItem>
                        </List>
                    </>
                )
            case 12:
                return (
                    <>
                        <Typography variant='h6'>{t('recommandations.BeforeTreatment')}</Typography>
                        <List dense={false}>
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.thermocoagulation.BeforeTreatment1')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.BeforeTreatment2')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.BeforeTreatment3')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.BeforeTreatment4')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.BeforeTreatment5')} />
                            </ListItem>
                        </List>
                        <Divider sx={{pb:3}}/>
                        <Typography variant='h6'>{t('recommandations.AfterTreatment')}</Typography>
                        <List dense={false} >
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.thermocoagulation.AfterTreatment1')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.AfterTreatment2')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.AfterTreatment3')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.AfterTreatment4')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.AfterTreatment5')} />
                                <ListItemText primary={t('recommandations.thermocoagulation.AfterTreatment6')} />
                            </ListItem>
                        </List>
                    </>
                )
            case 15:
                return (
                    <>
                        <Typography variant='h6'>{t('recommandations.maquillage.Title')}</Typography>
                        <List dense={false} >
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.maquillage.Treatment1')} />
                                <ListItemText primary={t('recommandations.maquillage.Treatment2')} />
                            </ListItem>
                        </List>
                    </>
                )
            case 16:
                return (
                    <>
                        <Typography variant='h6'>{t('recommandations.BeforeTreatment')}</Typography>
                        <List dense={false}>
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.ipl.BeforeTreatment1')} />
                                <ListItemText primary={t('recommandations.ipl.BeforeTreatment2')} />
                                <ListItemText primary={t('recommandations.ipl.BeforeTreatment3')} />
                                <ListItemText primary={t('recommandations.ipl.BeforeTreatment4')} />
                            </ListItem>
                        </List>
                        <Divider sx={{pb:3}}/>
                        <Typography variant='h6'>{t('recommandations.AfterTreatment')}</Typography>
                        <List dense={false} >
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment1')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment2')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment3')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment4')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment5')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment6')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment7')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment8')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment9')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment10')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment11')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment12')} />
                                <ListItemText primary={t('recommandations.ipl.AfterTreatment13')} />
                            </ListItem>
                        </List>
                    </>
                )
            case 20:
                return (
                    <>
                        <Typography variant='h6'>{t('recommandations.manucure.Title')}</Typography>
                        <List dense={false} >
                            <ListItem sx={styles.list}>
                                <ListItemText primary={t('recommandations.manucure.Treatment1')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment2')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment3')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment4')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment5')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment6')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment7')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment8')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment9')} />
                                <ListItemText primary={t('recommandations.manucure.Treatment10')} />
                            </ListItem>
                        </List>
                    </>
                )
            default:
                return (
                    <Typography variant='body1'>{t('recommandations.NoRecommandation')}</Typography>
                )
        }
    }

    return (
        <Paper elevation={4} sx={{p: 2, mt: 5}}>
            {showRecommandations()}
        </Paper>

    );
}

export default Recommandations;