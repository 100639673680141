import axios from 'axios'

class ApiClient {
	constructor(remoteHostUrl) {
		this.remoteHostUrl = remoteHostUrl
		this.tokenName = "portail_Nessa"
		this.token = null
	}

	async setToken(token){
		this.token = token
		localStorage.setItem(this.tokenName, token)
		return true
	}

	async request({ endpoint, method = 'GET', data = {}}) {
		const url = `${this.remoteHostUrl}/${endpoint}`
		const headers = {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: this.token ? `Bearer ${this.token}` : "",
		}

		try {
			const res = await axios({ url, method, data, headers })
			return { data: res.data, error: null }
		} catch (error) {
			// //TODO - Retirer quand on va être live
			// console.error("APIclient.makeRequest.error:")
			// console.error({ errorResponse: error.response })
			const message = error?.response?.data?.error?.message
			return { data: null, error: message || String(error) }
		}
	}

	async addClientAppointment(appointment) {
		return await this.request({
			endpoint: `client/addclientappointment`,
			method: `POST`,
			data: {appointment: appointment}
		})
	}

	async addClientGeneralNote(note, clientId) {
		return await this.request({
			endpoint: `client/addclientgeneralnote`,
			method: `POST`,
			data: {note: note, clientId: clientId}
		})
	}

	async addNewClient(newClient, centerId){
		return await this.request({endpoint: `center/addClient`, method:`POST`, data: { newClient: newClient, centerId: centerId }})
	}

	async addBotoxAppointmentNote(clientId, employeeId, notes){
		return await this.request({endpoint: `client/addbotoxappointmentnotes`, method:`POST`, data: { clientId, employeeId, notes }})
	}

	async cancelClientAppointment(appointmentId) {
		return await this.request({endpoint: `client/cancelclientappointment`, method: `POST`, data: { appointmentId: appointmentId }} )
	}

	async setFollowUpCompleted(followUpId) {
		return await this.request({endpoint: `client/setcompletedfollowup`, method: `POST`, data: { followUpId: followUpId }} )
	}

	async crudClientNoteGeneral(note, clientId) {
		return await this.request({endpoint: `client/crudclientnotegeneral`, method: `POST`, data: { note: note, clientId: clientId }} )
	}

	async crudClientFollowUp(followUp) {
		return await this.request({endpoint: `client/crudclientfollowup`, method: `POST`, data: { followUp }} )
	}

	async deleteClientFollowUp(followUpId) {
		return await this.request({endpoint: `client/deleteclientfollow`, method: `DELETE`, data: { followUpId }} )
	}

	async editCenterInformation(form, centerId) {
		return await this.request({endpoint: `center/editcenterinformations`, method:`POST`, data: { center: form, centerId: centerId }})
	}

	async editClient(form, clientId){
		return await this.request({endpoint: `client/editclient`, method:`POST`, data: { form: form, clientId: clientId }})
	}

	async editClientAppointment(appointment) {
		return await this.request({endpoint: `client/editclientappointment`, method: `POST`, data: { appointment: appointment }} )
	}

	async fetchAccountConfirmed(token){
		return await this.request({endpoint: `auth/confirmaccount/${token}`, method:`GET`})
	}

	async fetchClientName(clientId) {
		return await this.request({ endpoint: `client/fetchclientname/${clientId}`, method: `GET` })
	}

	async fetchAllCenterAppointments(centerId) {
		return await this.request({ endpoint: `center/fetchcenterappointments/${centerId}`, method: `GET` })
	}

	async fetchAllClientData(centerId) {
		return await this.request({ endpoint: `client/fetchclient/${centerId}`, method: `GET` })
	}

	async fetchCenterClientList(centerId) {
		return await this.request({ endpoint: `center/clientlist/${centerId}`, method: `GET` })
	}

	async fetchClientAppointment(clientId) {
		return await this.request({ endpoint: `client/fetchclientappointment/${clientId}`, method: `GET` })
	}

	async fetchClientAppointmentFollowUp(clientId) {
		return await this.request({ endpoint: `client/fetchclientappointmentfollowup/${clientId}`, method: `GET` })
	}

	async fetchCenterFollowUpList(centerId) {
		return await this.request({ endpoint: `center/fetchcenterfollowuplist/${centerId}`, method: `GET` })
	}

	async fetchClientGeneralNotes(clientId) {
		return await this.request({ endpoint: `client/fetchclientnotes/${clientId}`, method: `GET` })
	}

	async fetchTreatmentList(){
		return await this.request({endpoint:`apps/treatmentlist/`, method:`GET`})
	}

	async fetchTreatmentName(idTreatment){
		return await this.request({ endpoint: `apps/treatmentname/${idTreatment}`, method: `GET` })
	}

	async fetchUserFromToken() {
		return await this.request({ endpoint: `auth/me`, method: `GET` })
	}

	async loginUser(credentials){
		return await this.request({endpoint: `auth/login`, method: `POST`, data: credentials} )
	}

	async logoutUser() {
		this.setToken(null)
		localStorage.setItem(this.tokenName, "")
	}

	async ResendConfirmEmail(email){
		return await this.request({endpoint: `auth/resendconfirmemail`, method:`POST`, data: {email}})
	}

	async resetPassword(user){
		return await this.request({endpoint: `auth/resetPassword`, method:`POST`, data: {user}})
	}

	async updateSelectedTreatment(selectedTreatment, centerId){
		return await this.request({endpoint: `center/treatmentselected`, method: `POST`, data: { selectedTreatment: selectedTreatment, centerId: centerId }} )
	}
	
	async fetchClientDocumentList(clientId) {
		return await this.request({ endpoint: `client/fetchClientDocumentList/${clientId}`, method: `GET` })
    }

	async fetchClientDocumentData(clientId, documentId) {
		return await this.request({ endpoint: `client/fetchClientDocumentData/${documentId}&${clientId}`, method: `GET` })
	}

	async fetchClientDocumentDataNoAuth(clientId, documentId) {
		return await this.request({ endpoint: `client/fetchClientDocumentDataNoAuth/${documentId}&${clientId}`, method: `GET` })
	}

	async fetchClientTreatmentExamenPeauNotes(clientId) {
		return await this.request({ endpoint: `client/fetchclienttreatmentexamenpeaunotes/${clientId}`, method: `GET` })
	}

	async SendDocumentToFillOutEmail(documentId, client) {
		return await this.request({endpoint: `client/senddocumenttofillout`, method:`POST`, data: {documentId: documentId, client: client}})
	}
	async SendDocumentToFillOutEmailFromCalendar(data) {
		const infos = {
			id:  data.clientId
		}
		return await this.request({endpoint: `client/senddocumenttofillout`, method:`POST`, data: {documentId: data.appointment.idDocument, client: infos}})
	}

	async updateClientDocument(documentId, documentData, clientSignature) {
		return await this.request({endpoint: `client/updateclientdocument`, method:`POST`, data: { documentId: documentId, document: documentData, clientSignature: clientSignature}})
    }

	async addClientExamenPeauNote(clientId, examenPeauNote, idTreatment, employeeId) {
		return await this.request({endpoint: `client/addclientexamenpeaunotes`, method:`POST`, data: { clientId: clientId, examenPeauNote: examenPeauNote, idTreatment:idTreatment, employeeId:employeeId}})
	}

	async updateClientDocumentNoAuth(documentId, documentData, clientSignature) {
		return await this.request({endpoint: `client/updateClientDocumentNoAuth`, method:`POST`, data: { documentId: documentId, document: documentData, clientSignature: clientSignature}})
    }

	async fetchCenterAppointmentList(centerId) {
		return await this.request({endpoint: `center/fetchAppointmentList/${centerId}`, method: `GET`})
	}

	async fetchCenterTierInformation(centerId) {
		return await this.request({endpoint: `center/fetchcentertierinfo/${centerId}`, method: `GET`})
	}

	async fetchCenterAddons(centerId) {
		return await this.request({endpoint: `center/fetchcenteraddons/${centerId}`, method: `GET`})
	}

	async fetchCenterClientAddonsAvailable() {
		return await this.request({endpoint: `center/fetchcenterclientaddonsavailable/`, method: `GET`})
	}

	async fetchGeneralTreatmentNote(clientId, idTreatment) {
		return await this.request({endpoint: `client/fetchgeneraltreatmentappointmentnote/${clientId}/${idTreatment}`, method: `GET`})
	}

	async fetchFormExamenPeauNotes() {
		const configKey = 'noteExamenPeau'
		return await this.request({endpoint: `apps/fetchConfig/${configKey}`, method: `GET`})
	}

	async fetchOldExamenPeauNote(noteId) {
		return await this.request({endpoint: `client/fetcholdexamenpeaunote/${noteId}`, method: `GET`})
	}

	async addGeneralTreatmentAppointmentNote(note, clientId, idTreatment, employeeId) {
		return await this.request({endpoint: `client/addgeneraltreatmentappointmentnote/`, method: `POST`, data: { note, clientId, idTreatment,employeeId }})
	}

	async addElectroAppointmentNote(idClient, employeeId, note, idTreatment) {
		return await this.request({endpoint: `client/addelectroappointmentnote/`, method: `POST`, data: { idClient: idClient, employeeId: employeeId, note: note, idTreatment:idTreatment }})
	}

	async fetchElectroAppointmentNote(clientId, idTreatment) {
		return await this.request({endpoint: `client/fetchelectroappointmentnoteList/${clientId}/${idTreatment}`, method: `GET`})
	}

	async fetchBotoxAppointmentNote(clientId) {
		return await this.request({endpoint: `client/fetchbotoxappointmentnotelist/${clientId}`, method: `GET`})
	}

	async fetchEmployeeList(centerId) {
		return await this.request({endpoint: `center/fetchEmployeeList/${centerId}`, method: `GET`})
	}

	async deleteEmployee(userId) {
		return await this.request({endpoint: `center/deleteEmployee`, method: `POST`, data: { userId: userId }})
	}

	async addEmployee(form) {
		return await this.request({endpoint: `center/addEmployee`, method: `POST`, data: { form: form }})
	}

	async setAddonDeactivate(addonId) {
		return await this.request({endpoint: `center/deactivateaddonclient`, method: `POST`, data: { addonId }})
	}

	async setAddonReactivated(addonId) {
		return await this.request({endpoint: `center/reactivateaddonclient`, method: `POST`, data: { addonId }})
	}

	async updateDocumentSignature(documentId, clientSignature) {
		return await this.request({endpoint: `client/updateclientdocumentsignature/`, method: `POST`, data: { documentId, clientSignature }})
	}
	async updateCenterLogo(logo, centerId) {
		return await this.request({endpoint: `center/updatecenterlogo`, method: `POST`, data: { logo, centerId }})
	}

	async updateSubscriptionAutoRenew(centerId, autoRenew ) {
		return await this.request({endpoint: `center/updatecentersubscriptionautorenew`, method: `POST`, data: { autoRenew, centerId }})
	}

	async updateCenterClientAddon(tierAddonId, centerId) {
		return await this.request({endpoint: `center/updatecenterclientaddon`, method: `POST`, data: { tierAddonId, centerId }})
	}

	async fetchDocFromToken(token){
		return await this.request({endpoint: `center/fetchDocFromToken/${token}`, method: `GET`})
	}

	async fetchDocListFromId(id){
		return await this.request({endpoint: `client/fetchDocListFromId/${id}`, method: `GET`})
	}

	async fetchUserFromTokenPassword(token){
		return await this.request({endpoint: `auth/fetchUserFromToken/${token}`, method: `GET`})
	}

	async signConsentForm(clientId, signature, authorisation){
		return await this.request({endpoint: `client/signconsentform`, method: `POST`, data: { clientId, signature, authorisation }})
	}

	async updateClientDragAppointment(idAppointment, newDate){
		return await this.request({endpoint: `client/updateclientdragappointment`, method: `POST`, data: { idAppointment, newDate }})
	}
	//Nouveau temps en minutes de appointment.
	async updateClientTimeAppointment(idAppointment, newTime){
		return await this.request({endpoint: `client/updateclienttimeappointment`, method: `POST`, data: { idAppointment, newTime }})
	}

	async updatePassword(form, token){
		return await this.request({endpoint: `auth/updatepassword`, method: `POST`, data: { form, token }})
	}

	async submitAddonPayment(centerId, typeId){
		return await this.request({endpoint: `stripe/submitaddonnpayment`, method: `POST`, data: {centerId, typeId}} )
	}
}

export default new ApiClient(process.env.REACT_APP_REMOTE_HOST_URL)