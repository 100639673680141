import React, {useContext, useEffect, useState} from 'react'
import {FormContext} from '../../../contexts/FormContext'
import {FormContextNoAuth} from '../../../contexts/FormContextNoAuth'
import {Checkbox, Grid, FormControlLabel, FormControl, FormGroup, FormLabel} from "@mui/material"
import {useTranslation} from "react-i18next";
import {FormExamenPeauContext} from "../../../contexts/FormExamenPeauContext";

const FormMultiCheckbox = ({field_id, field_label, field_options,viewMode, isNoAuth, isExamenPeau}) => {
	const {t} = useTranslation()
	const [state, setState] = useState(null)
	const [isDisabled, setIsDisabled] = useState(viewMode);
	const { handleChange } = useContext(isNoAuth ? FormContextNoAuth : !isExamenPeau ? FormContext : FormExamenPeauContext);

	useEffect(() => {
		field_options.forEach(option => {
			setState(prevState => ({
				...prevState,
				[option.field_id]: option.field_value
			}))
		})

	}, [])


	const handleMultiCheckBox = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked
		})
		handleChange(field_id, event)
	};

	return (
		<Grid item xs={12}>
			<FormControl component="fieldset" variant="standard" >
				<FormLabel component="legend">{t(field_label)}</FormLabel>
				<FormGroup row={true}>
					{
						state !== null && field_options.map(option => {
							return (
								<FormControlLabel
									key={option.field_id}
									control={
										<Checkbox
											checked={state[option.field_id]}
											disabled={isDisabled}
											onChange={handleMultiCheckBox}
											name={option.field_id}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									}
									label={t(option.field_label)}
								/>
							)
						})
					}
				</FormGroup>
			</FormControl>
		</Grid>
	)
}

export default FormMultiCheckbox
